(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('GroupDialogController', GroupDialogController);

    GroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'Group', 'AllCustomGroupTypeAttribute', 'AllGroupsTypes', 'UserIDs','AssetIDs','AssetByID', 'AssetsForMap','Principal'];

    function GroupDialogController ($timeout, $scope, $stateParams, $state, entity, Group, AllCustomGroupTypeAttribute, AllGroupsTypes, UserIDs,Principal) {
        var vm = this;
        vm.group = entity;
        vm.clear = clear;
		var entity_old = angular.copy(vm.group);
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
		vm.customAttributesMap = {};
		vm.selectattributes = selectedGroupTypeAttributes;
		vm.save = save;
		vm.navigateGroupTypefromGroup = navigateGroupTypefromGroup;
        loadPage();
		var selctedUsers = [];
        
        function loadPage() {
        	vm.multiuser = [];
          	vm.multigroups = [];
          	AllGroupsTypes.getAll(function(response) {
				vm.grouptypes = response;
			});
			
			AllCustomGroupTypeAttribute.getAll(function(response) {
				response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customGroupTypeAttributeValues;
				});
			
			});
        	
        	UserIDs.getAdminAll(function(response) {
        		response.forEach(function(user) {
        			if(selctedUsers.indexOf(user.id) !== -1){
        					vm.multiuser.push({ login: user.login, id:user.id ,ticked: true });
        				}else{
        					vm.multiuser.push({ login: user.login, id:user.id ,ticked: false });
        			}
        		});
			});
		}
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }
        
		function selectedGroupTypeAttributes() {
			if (vm.groupType) {
				vm.isSite = false;
				
				vm.group.groupType = vm.groupType;
				if (entity_old.groupType
						&& vm.groupType.id === entity_old.groupType.id) {
					vm.group.groupTypeAttributeValues = [];
					vm.group.groupTypeAttributeValues = entity_old.groupTypeAttributeValues;
				} else {
					vm.group.groupTypeAttributeValues = [];
					$.each(
						vm.groupType.groupTypeAttributes,
						function(key, value) {
							vm.group.groupTypeAttributeValues
								.push({
									groupTypeAttribute : vm.groupType.groupTypeAttributes[key]
								});
						});
				}

			}

		}
     
        function save () {
            vm.isSaving = true;
            if (vm.group.id !== null) {
                Group.update(vm.group, onSaveSuccess, onSaveError);
            } else {
                Group.save(vm.group, onSaveSuccess, onSaveError);
            }
        }

		function onSaveSuccess(result) {
			$scope.$emit('trakeyeApp:groupUpdate', result);
			// $uibModalInstance.close(result);
			vm.isSaving = false;
			var pageValue = sessionStorage.getItem("prevPage");
			var previousId = sessionStorage.getItem("prevId");
			var prevEditDetail = sessionStorage.getItem("prevEditId");
			if (pageValue == 'work-order.new') {
				$state.go('work-order.new');
			} else if (pageValue == 'tr-case.workflow') {
				$state.go('tr-case.workflow');
			} else if (pageValue == 'user-management.new') {
				$state.go('user-management.new');
			} else if (previousId) {
				sessionStorage.setItem("groupToEditAsset", "groupToasset");
				$state.go('asset.edit', {
					'id': previousId
				});
			} else if (prevEditDetail) {
				sessionStorage.setItem("groupToEditAssetDetail", "groupToassetDetail");
				$state.go('asset-detail.edit', {
					'id': prevEditDetail
				});
			}
			else {
				$state.go('group');
			}
		}

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.modifiedDate = false;
        

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
		}
		
		function navigateGroupTypefromGroup() {
			sessionStorage.setItem("prevPage", "group.new");
			$state.go('group-type.new');
		}
    }
    
})();

