(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('WorkFlowDetailController', WorkFlowDetailController);

    WorkFlowDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'WorkFlow', 'User', 'WorkFlowType', 'WorkFlowTypeTypeAttributeValue', 'Asset', 'WorkOrder', 'Group', 'Tenant'];

    function WorkFlowDetailController($scope, $rootScope, $stateParams, previousState, entity, WorkFlow, User, WorkFlowType, WorkFlowTypeTypeAttributeValue, Asset, WorkOrder, Group, Tenant) {
        var vm = this;

        vm.workFlow = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:workFlowUpdate', function(event, result) {
            vm.workFlow = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
