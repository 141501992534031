(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('TrCaseDetailController', TrCaseDetailController);

    TrCaseDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TrCase', 'User', 'CaseType', 'TrCaseImages', 'TrCaseRecordings','$window'];

    function TrCaseDetailController($scope, $rootScope, $stateParams, previousState, entity, TrCase, User, CaseType,TrCaseImages,TrCaseRecordings,$window) {
		var vm = this;
		
		if (entity.status) {
			entity.status = entity.status.replace("NEW", "PLANNING PHASE");
			entity.status = entity.status.replace("INPROGRESS", "ROLL-OUT PHASE");
			entity.status = entity.status.replace("RESOLVED", "OPERATION PHASE");
		}
        vm.trCase = entity;
        vm.previousState = previousState.name;
        vm.decodeImage = decodeImage;
        var unsubscribe = $rootScope.$on('trakeyeApp:trCaseUpdate', function(event, result) {
            vm.trCase = result;
        });
        $scope.$on('$destroy', unsubscribe);
        
          $scope.go_back = function() { 
        	  $window.history.back();
        	};
        
        $scope.allimages = false;
//		$scope
		$scope.toggle = function() {
			$scope.allimages = !$scope.allimages;
			if ($scope.allimages) {

				AssetImages.query({
					assetId : vm.asset.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {

					vm.assetImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}

			}
		
			/*
			
			$scope.allimages = !$scope.allimages;
			
			var audioTag = document.createElement("AUDIO");
			audioTag.setAttribute("src","http://us-east-1.linodeobjects.com/trakeye-media-files/case_"+vm.trCase.id+".mp3");
			audioTag.setAttribute("controls", "controls");
			audioTag.setAttribute("controlsList", "nodownload");

			var mydiv = document.getElementById("audioLayout");
			
			if ($scope.allimages) {
				TrCaseImages.query({
					caseId : vm.trCase.id

				}, onSuccess, onError);

				function onSuccess(data, headers) {
					// console.log('Recived data-----------');
					vm.caseImages = data;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
				var mydivChild = document.createElement('div');
				mydivChild.setAttribute("id", "audioChild");
				mydiv.appendChild(mydivChild);
				mydivChild.appendChild(audioTag);
			}else{
				var mydivChild = document.getElementById("audioChild");
				mydivChild.remove();
			}
		*/};

//		$scope.playAudio = function() {
////			var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/case_"+vm.trCase.id+".mp3";
//            var urlStr = "http://us-east-1.linodeobjects.com/trakeye-media-files/case_15570572.mp3";
//	        var audio = new Audio(urlStr);
//	        audio.play();
//	    };
		
		
        function decodeImage(img){
     	   return window.atob(img);
        }
       
    }
})();
