(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomWorkFlowTypeAttributeDialogController', CustomWorkFlowTypeAttributeDialogController);

    CustomWorkFlowTypeAttributeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CustomWorkFlowTypeAttribute', 'User', 'Tenant', 'CustomWorkFlowTypeAttributeValue'];

    function CustomWorkFlowTypeAttributeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CustomWorkFlowTypeAttribute, User, Tenant, CustomWorkFlowTypeAttributeValue) {
        var vm = this;

        vm.customWorkFlowTypeAttribute = entity;
        vm.clear = clear;
        vm.save = save;
        vm.users = User.query();
        vm.tenants = Tenant.query();
        vm.customworkflowtypeattributevalues = CustomWorkFlowTypeAttributeValue.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.customWorkFlowTypeAttribute.id !== null) {
                CustomWorkFlowTypeAttribute.update(vm.customWorkFlowTypeAttribute, onSaveSuccess, onSaveError);
            } else {
                CustomWorkFlowTypeAttribute.save(vm.customWorkFlowTypeAttribute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:customWorkFlowTypeAttributeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
