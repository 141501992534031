(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('WorkFlowDialogController', WorkFlowDialogController);

    WorkFlowDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'WorkFlow', 'User', 'WorkFlowType', 'WorkFlowTypeTypeAttributeValue', 'Asset', 'WorkOrder', 'Group', 'Tenant'];

    function WorkFlowDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, WorkFlow, User, WorkFlowType, WorkFlowTypeTypeAttributeValue, Asset, WorkOrder, Group, Tenant) {
        var vm = this;

        vm.workFlow = entity;
        vm.clear = clear;
        vm.save = save;
        vm.users = User.query();
        vm.workflowtypes = WorkFlowType.query();
        vm.workflowtypetypeattributevalues = WorkFlowTypeTypeAttributeValue.query();
        vm.assets = Asset.query();
        vm.workorders = WorkOrder.query();
        vm.groups = Group.query();
        vm.tenants = Tenant.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.workFlow.id !== null) {
                WorkFlow.update(vm.workFlow, onSaveSuccess, onSaveError);
            } else {
                WorkFlow.save(vm.workFlow, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:workFlowUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
