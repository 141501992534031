(function () {
	'use strict';

	angular.module('trakeyeApp').controller('TrCaseController', TrCaseController);

	TrCaseController.$inject = ['$filter', '$scope', '$state', '$stateParams', '$compile', '$timeout', 'pagingParams', 'paginationConstants', 'TrCase', 'TrCaseGroup', 'TrCaseUpdateGroup', 'TrCaseBulkDelete', 'ParseLinks', 'AlertService', 'TrCaseUser', 'TrCaseSearch', 'UserGroupIDs', 'TrCaseGroupSearch', 'TrCasePriority', 'TrCaseGroupPriority',
		'TrCasePrioritySearch', 'TrCaseGroupPrioritySearch', 'TrCaseEdit', 'TrCaseForMap', 'TrGroupCaseForMap', 'TrCaseSearchForMap', 'TrGroupCaseSearchForMap', 'TrCaseLastLocation', 'WorkflowAssets', 'TrCaseById'];

	function TrCaseController($filter, $scope, $state, $stateParams, $compile, $timeout, pagingParams, paginationConstants, TrCase, TrCaseGroup, TrCaseUpdateGroup, TrCaseBulkDelete, ParseLinks, AlertService, TrCaseUser, TrCaseSearch, UserGroupIDs, TrCaseGroupSearch, TrCasePriority, TrCaseGroupPriority,
		TrCasePrioritySearch, TrCaseGroupPrioritySearch, TrCaseEdit, TrCaseForMap, TrGroupCaseForMap, TrCaseSearchForMap, TrGroupCaseSearchForMap, TrCaseLastLocation, WorkflowAssets, TrCaseById) {
		var vm = this;
		$scope.search = pagingParams.search;
		vm.loadPage = loadPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.filterTrcaseSearch = filterTrcaseSearch;
		vm.filterTrCaseSearchMap = filterTrCaseSearchMap;
		vm.showworkFlowBreakUps = showworkFlowBreakUps;
		vm.showPowerBudgetBreakUps = showPowerBudgetBreakUps;
		vm.selectTrcaseEntity = selectTrcaseEntity;
		vm.selectAllTrCases = selectAllTrCases;
		vm.selectedWorkFlow = selectedWorkFlow;
		vm.hideWorkFlowModal = hideWorkFlowModal;
		vm.hideWorkFlowPBModal = hideWorkFlowPBModal;
		vm.updateWorkFlowGroup = updateWorkFlowGroup;
		vm.searchTrCases = searchTrCases;
		vm.recalculateCase = recalculateCase;
		vm.isExpanded = false;
		vm.expandedCaseId = 0;
		//		 vm.trCases = [];
		var latlng;
		var userPaths = {};
		var locations = {};		
		var nearbyAgentsMarkers = [];

		vm.selectedWorkCases = [];
        vm.multgroup = [];
        var selctedGroup = [];
        vm.groupCases = false;
		vm.showTrCaseActions = false;
		vm.totalTrcaseSelected = 0;
		vm.assetsTable = assetsTable;
		vm.navigateCaseEdit = navigateCaseEdit;
		vm.goToEditPage = goToEditPage;
        vm.items = [{
            'name': 'Update Group Mapping'
        }, {
            'name': 'Delete Multiple Workflows'
        }];


		$scope.tab = 4;
		$scope.$on('$destroy', function () {
			//            $interval.cancel(updateUserAgentPath);
		});
		$scope.isSet = function (tabNum) {
			return $scope.tab === tabNum;
		};

		if ($scope.tab == 4) {
            UserGroupIDs.getAll(function (response) {
                response.forEach(function (group) {
                    if (selctedGroup.indexOf(group.id) !== -1) {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: true });
                    } else {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: false });
                    }
                });
            });
            filterGroupSearch();
        }

		$scope.setTab = function (newTab) {
			$scope.tab = newTab;
			if ($scope.tab == 2 || $scope.tab == 3) {
				filterTrCaseSearchMap();
			} else if ($scope.tab == 5) {		
				filterSearchCasesByGroupMap();
			} else if ($scope.tab == 4) {
				vm.itemsPerPage = paginationConstants.itemsPerPage;
				filterGroupSearch();
			} else {
				vm.itemsPerPage = paginationConstants.itemsPerPage;
				filterTrcaseSearch();
			}
		};
		
		function navigateCaseEdit(selectedId) {
			vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            $state.go('tr-case.edit', {
                'id': vm.editId
            });
		}
		function goToEditPage(selectedId) {
			$state.go('asset.edit', {
                'id': selectedId.id
            });
		}
		function assetsTable(trCase) {
			vm.trCases.forEach(function (item) {
				item.isExpanded = false;
			})
			if (vm.toggle == trCase.id) {
				vm.toggle = undefined
			} else {
				trCase.isExpanded = true;
				vm.toggle = trCase.id;
			}
			WorkflowAssets.query({
				parentId: trCase.id,
			}, onSuccess);
			
			function onSuccess(data, headers) {
				var trAssetsRes = [];
				var baseCost = "";
				var Cost = "";
				var pb = "";
				var totalDistance = 0.0;
				data.forEach(function (values) {
					// var FiberStatus = "";

					// if (values.status) {
					// 	values.status = values.status.replace("NEW", "PLANNING PHASE");
					// 	values.status = values.status.replace("INPROGRESS", "ROLL-OUT PHASE");
					// 	values.status = values.status.replace("RESOLVED", "OPERATION PHASE");
					// }
					var assetAttrVal = values.assetTypeAttributeValues;

					assetAttrVal.forEach(function (item) {
						if (item.assetTypeAttribute.name == "Cost") {
							Cost = item.attributeValue;
						} else if ((item.assetTypeAttribute.name == "Power Budget")) {
							pb = item.attributeValue;
						} else if ((item.assetTypeAttribute.name == "Fiber Distance")) {
							totalDis = item.attributeValue;
						} else if(item.name == "Base Cost") {
							baseCost = item.attributeValue;
						}
					});
					 if ((values.status = 'true') || (values.status = 'false')) {
						values.status = '';
					}
					
					var res = {
						id: values.id,
						description: values.name,
						status: values.status,
						reportedByUser: values.reportedByUser,
						assignedToUser: values.assignedToUser,
						priority: values.priority,
						// fiberStatus: FiberStatus,
						groups: values.groups,
						baseCost : baseCost,
						Cost: "$" + Cost,
						pb: "$" + pb,
						totalDistance: totalDistance
					};

					trAssetsRes.push(res);
				});
				vm.assetsData = trAssetsRes;
			}
		}

		function recalculateCase(caseVal) {
			TrCaseById.get(caseVal.id,function(caseVal){
				var caseAssetsList = caseVal.caseAssets;
				var totalCost = 0;
	            var totalPowerBudget = 0;
				var totalFiberDistance = 0.0;
				
	            for (var assetIndex = 0; assetIndex < caseAssetsList.length; assetIndex++) {
	                var isSpread = false;
	                var fiberDistance = 0.0;
	
					if (caseAssetsList[assetIndex].assetType.layout == "SPREAD") {
						isSpread = true;
	                    var spreadAV = caseAssetsList[assetIndex].assetTypeAttributeValues;
	                    for (var spreadAssetIndex = 0; spreadAssetIndex < spreadAV.length; spreadAssetIndex++) {
	                        if (spreadAV[spreadAssetIndex].assetTypeAttribute.name === "Fiber Distance (km)") {
	                           fiberDistance = parseFloat("0"+spreadAV[spreadAssetIndex].attributeValue);
	                        }
	                    }
	                }
	
					for(var attrIndex = 0;attrIndex < caseAssetsList[assetIndex].assetTypeAttributeValues.length;attrIndex++) {
						var item = caseAssetsList[assetIndex].assetTypeAttributeValues[attrIndex];
	                    var value = parseFloat(item.assetTypeAttribute.defaultValue);
	
	                    if (item.assetTypeAttribute.name == "Cost") {
	                        var tempCost = parseFloat(value);
	                        if (isSpread) {
	                            tempCost = parseFloat(value) * fiberDistance;
	                        }
	                        if (tempCost > 0) {
	                            totalCost += tempCost
	                        }
							item.attributeValue = value;
	                    }
	                    if (item.assetTypeAttribute.name == "Power Budget") {
	                        var tempPowerBudget = parseFloat(value);
	                        if (isSpread) {
	                            tempPowerBudget = parseFloat(value) * fiberDistance;
	                        }
	                        if (tempPowerBudget > 0) {
	                            totalPowerBudget += tempPowerBudget;
	                        }
							item.attributeValue = value;
	                    }
						caseAssetsList[assetIndex].assetTypeAttributeValues[attrIndex] = item;
					}
					totalFiberDistance += fiberDistance;
	            }
	
	            var caseAV = caseVal.caseTypeAttributeValues;
	            for (var caseIndex = 0; caseIndex < caseAV.length; caseIndex++) {
	                if (caseAV[caseIndex].caseTypeAttribute.name === "Cost") {
	                    caseAV[caseIndex].attributeValue = totalCost.toFixed(2);;
	                }
	                if (caseAV[caseIndex].caseTypeAttribute.name === "Power Budget") {
	                    caseAV[caseIndex].attributeValue = totalPowerBudget.toFixed(2);
	                }
					if (caseAV[caseIndex].caseTypeAttribute.name === "Fiber Distance") {
                        caseAV[caseIndex].attributeValue = totalFiberDistance.toFixed(2);
                    }
	            }
	            caseVal.caseTypeAttributeValues = caseAV;
				caseVal.deletedAssets = [];
				TrCase.update(caseVal, onSaveSuccess);
				function onSaveSuccess(result) {
//		            $scope.$emit('trakeyeApp:trCaseUpdate', result);
//		            $state.go('tr-case');
					vm.itemsPerPage = paginationConstants.itemsPerPage;
					filterGroupSearch();
		        }
			});
		}

		function distanceCal(lat1, lon1, lat2, lon2) {
			var theta = lon1 - lon2;
			var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
				Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
			dist = Math.acos(dist);
			dist = radians_to_degrees(dist);
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;
			if (isNaN(dist)) {
				return 0.0;
			} else {
				return (dist);
			}
		}

		function degrees_to_radians(degrees) {
			var pi = Math.PI;
			return degrees * (pi / 180);
		}

		function radians_to_degrees(radians) {
			var pi = Math.PI;
			return radians * (180 / pi);
		}

		
		function searchTrCases() {
			if ($scope.search != null && $scope.search != "" &&
				!angular.isUndefined($scope.search) && $scope.search.length != 1 && $scope.search.length != 2 && $scope.search.length != 3 && $scope.search.length != 4) {
				if ($stateParams.priority == undefined) {
					TrCaseSearch.query({
						search: $scope.search,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				} else {
					TrCasePrioritySearch.query({
						priority: $stateParams.priority,
						searchtext: $scope.search,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				}
				function sort() {
					var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
					if (vm.predicate !== 'id') {
						// result.push('id');
					}
					return result;
				}
				function onSuccess(data, headers) {
					vm.links = ParseLinks.parse(headers('link'));
					vm.totalItems = headers('X-Total-Count');
					vm.queryCount = vm.totalItems;
					var trCasesSearchRes = [];
					data.forEach(function (values) {
						var FiberStatus = "";
						var Cost = "";
						var pb = "";
						var dis = "";
						var baseCost = "";
						var caseTypeAttrVal = values.caseTypeAttributeValues;

						caseTypeAttrVal.forEach(function (item) {
							if (item.caseDataTypeName == "Fiber Status") {
								FiberStatus = item.attributeValue;
							}
							if (item.name == "Cost") {
								Cost = item.attributeValue;
							} else if ((item.name == "Power Budget")) {
								pb = item.attributeValue;
							} else if ((item.name == "Fiber Distance")) {
								dis = item.attributeValue;
							} else if(item.name == "Base Cost") {
								baseCost = item.attributeValue;
							}
						});

						if (values.status) {
							values.status = values.status.replace("NEW", "PLANNING PHASE");
							values.status = values.status.replace("INPROGRESS", "ROLL-OUT PHASE");
							values.status = values.status.replace("RESOLVED", "OPERATION PHASE");
						}

						var res = {
							id: values.id,
							description: values.description,
							address: values.address,
							escalated: values.escalated,
							status: values.status,
							reportedByUser: values.reportedByUser,
							assignedToUser: values.assignedToUser,
							caseTypeName: values.caseTypeName,
							pinLat: values.pinLat,
							pinLong: values.pinLong,
							caseType: values.caseType,
							priority: values.priority,
							fiberStatus: FiberStatus,
							groups: values.groups,
							geofenceName: values.geofenceName,
							caseAssets: values.caseAssets,
							Cost: "$" + Cost,
							pb:pb,
							dis:dis,
							baseCost : baseCost
						};

						trCasesSearchRes.push(res);
					});
					vm.trCases = trCasesSearchRes;
					vm.page = pagingParams.page;
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}
			} else {
				loadAll();
			}
		}

		function filterTrcaseSearch() {
			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				if ($stateParams.priority == undefined) {
					TrCaseSearch.query({
						search: $scope.search,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				} else {
					TrCasePrioritySearch.query({
						priority: $stateParams.priority,
						searchtext: $scope.search,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				}
				function sort() {
					var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
					if (vm.predicate !== 'id') {
						// result.push('id');
					}
					return result;
				}
				function onSuccess(data, headers) {
					vm.links = ParseLinks.parse(headers('link'));
					vm.totalItems = headers('X-Total-Count');
					vm.queryCount = vm.totalItems;
					vm.trCases = data;
					vm.page = pagingParams.page;

				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
			} else {
				loadAll();
			}

		}

		function filterGroupSearch() {
			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				if ($stateParams.priority == undefined) {
					TrCaseGroupSearch.query({
						search: $scope.search,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				} else {
					TrCaseGroupPrioritySearch.query({
						priority: $stateParams.priority,
						searchtext: $scope.search,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				}
				function sort() {
					var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
					if (vm.predicate !== 'id') {
						// result.push('id');
					}
					return result;
				}
				function onSuccess(data, headers) {
					vm.links = ParseLinks.parse(headers('link'));
					vm.totalItems = headers('X-Total-Count');
					vm.queryCount = vm.totalItems;
					vm.trCases = data;
					vm.page = pagingParams.page;

				}
				function onError(error) {
					AlertService.error(error.data.message);
				}
			} else {
				loadAll();
			}
		}
		
		function loadAll() {
			if ($stateParams.priority == undefined) {
				vm.groupCases = false;
				vm.showTrCaseActions = false;
				if ($scope.tab == 4) {
					TrCaseGroup.query({
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				} else {
					TrCase.query({
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				}
			} else {
				if ($scope.tab == 4) {
					TrCaseGroupPriority.query({
						priority: $stateParams.priority,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				} else {
					TrCasePriority.query({
						priority: $stateParams.priority,
						page: pagingParams.page - 1,
						size: vm.itemsPerPage,
						sort: sort()
					}, onSuccess, onError);
				}

			}

			function sort() {
				var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
				if (vm.predicate !== 'id') {
					// result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				var trCasesRes = [];
				vm.trBreakUps = data;
				data.forEach(function (values) {
					vm.parentGroup = values.groups[0].name;
					var FiberStatus = "";
					var Cost = "";
					var pb = "";
					var dis = "";
					var baseCost = "";
					var caseTypeAttrVal = values.caseTypeAttributeValues;

					caseTypeAttrVal.forEach(function (item) {
						if (item.caseDataTypeName == "Fiber Status") {
							FiberStatus = item.attributeValue;
						}
						if (item.name == "Cost") {
							Cost = item.attributeValue;
						} else if ((item.name == "Power Budget")) {
							pb = item.attributeValue;
						} else if ((item.name == "Fiber Distance")) {
							dis = item.attributeValue;
						} else if (item.name == "Base Cost") {
							baseCost = item.attributeValue;
						}
					});

					if (values.status) {
						values.status = values.status.replace("NEW", "PLANNING PHASE");
						values.status = values.status.replace("INPROGRESS", "ROLL-OUT PHASE");
						values.status = values.status.replace("RESOLVED", "OPERATION PHASE");
					}
					
					var res = {
						id: values.id,
						description: values.description,
						address: values.address,
						escalated: values.escalated,
						status: values.status,
						reportedByUser: values.reportedByUser,
						assignedToUser: values.assignedToUser,
						caseTypeName: values.caseTypeName,
						pinLat: values.pinLat,
						pinLong: values.pinLong,
						caseType: values.caseType,
						priority: values.priority,
						fiberStatus: FiberStatus,
						groups : values.groups,
						geofenceName: values.geofenceName,
						caseAssets: values.caseAssets,
						Cost:"$"+Cost,
						pb:pb,
						dis:dis,
						baseCost : baseCost
					};
					trCasesRes.push(res);
				});
				vm.trCases = trCasesRes;
				vm.page = pagingParams.page;
			}

			function onError(error) {
				AlertService.error(error.data.message);
			}
		}


		function filterTrCaseSearchMap() {
			var data;
			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				TrCaseSearchForMap.searchtrcaseformap($scope.search, function (response) {
					var searchArray = [];
					response.forEach(function(ele){
						if($scope.search == ele.id ){
							searchArray.push(ele)
						}
					})
					drawResponseMap(searchArray);
				});

			} else {
				if ($stateParams.priority == undefined) {
					TrCaseForMap.getAllCases(function (response) {
						drawResponseMap(response);
					});
				} else {

					TrCaseSearchForMap.prioritysearchtrcaseformap($stateParams.priority, function (response) {
						drawResponseMap(response);
					});
				}
			}
		};

		function filterSearchCasesByGroupMap() {
			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				TrGroupCaseSearchForMap.searchtrcaseformap($scope.search, function (response) {
					drawResponseMap(response);
				});
			} else {
				TrGroupCaseForMap.getAllCases(function (response) {
					drawResponseMap(response);
				});
			}
		};

		
		function showworkFlowBreakUps(trCase) {
			vm.trCaseName = trCase.description;
			vm.workBreakUps = [];
			var dateFormat = 'yyyy-MM-dd';
			TrCase.get({ id : trCase.id}, function (trCaseAsset) {
				trCaseAsset.caseAssets.forEach(function (caseAsset) {
					var assetAV = caseAsset.assetTypeAttributeValues;
					var orderCost = 0.0;
					var orderPb = 0.0;
					assetAV.forEach(function (data, index) {

						if (data.assetTypeAttribute.name == "Cost") {

							orderCost += parseInt(data.attributeValue);
						}
						if (data.assetTypeAttribute.name == "Power Budget") {

							orderPb += parseInt(data.attributeValue);
						}
					});

					var orders = { id: caseAsset.id, name: caseAsset.name, assettype: caseAsset.assetType.name, description: caseAsset.description, createDate: $filter('date')(caseAsset.createDate, dateFormat), Cost: "$" + orderCost, pb: orderPb };
					vm.workBreakUps.push(orders);
				});
			});
			$("#workFlowBreakUpModal").show();
		}
		function showPowerBudgetBreakUps(trCase) {
			vm.trCaseName = trCase.description;
			vm.workBreakUps = [];
			var dateFormat = 'yyyy-MM-dd';
			TrCase.get({ id : trCase.id}, function (trCaseAsset) {
				trCaseAsset.caseAssets.forEach(function (caseAsset) {
					var assetAV = caseAsset.assetTypeAttributeValues;
					var orderCost = 0.0;
					var orderPb = 0.0;
					assetAV.forEach(function (data, index) {

						if (data.assetTypeAttribute.name == "Cost") {

							orderCost += parseInt(data.attributeValue);
						}
						if (data.assetTypeAttribute.name == "Power Budget") {

							orderPb += parseInt(data.attributeValue);
						}
					});

					var orders = { id: caseAsset.id, name: caseAsset.name, assettype: caseAsset.assetType.name, description: caseAsset.description, createDate: $filter('date')(caseAsset.createDate, dateFormat), Cost: "$" + orderCost, pb: orderPb };
					vm.workBreakUps.push(orders);
				});
			});
			$("#PowerBudgetworkFlowBreakUp").show();
		}

		function hideWorkFlowModal() {
			$("#workFlowBreakUpModal").removeClass("in");
			$(".modal-backdrop").remove();
			$("#workFlowBreakUpModal").hide();
		}

		function hideWorkFlowPBModal() {
			$("#PowerBudgetworkFlowBreakUp").removeClass("in");
			$(".modal-backdrop").remove();
			$("#PowerBudgetworkFlowBreakUp").hide();
		}

		function selectTrcaseEntity(trCase) {
			if (trCase.id != null) {
				if (trCase.isChecked == true) {
					vm.totalTrcaseSelected++;
					vm.showTrCaseActions = true;
					vm.isAllTrCaseSelected = vm.trCases.every(function (itm) { return itm.isChecked; });
					vm.selectedWorkCases.push(trCase.id);
				} else {
					vm.totalTrcaseSelected--;
				}
				if(vm.totalTrcaseSelected == 0){
                    vm.groupAssets = true;
                    vm.showTrCaseActions = false;
                    filterTrcaseSearch();
                }
			}
		}
	
		function selectAllTrCases() {
			var toggleStatus = vm.isAllTrCaseSelected;
			if (toggleStatus) {
				vm.showTrCaseActions = true;
				vm.selectedWorkCases = [];
				vm.trCases.forEach(function (trCase) {
					vm.selectedWorkCases.push(trCase.id);
				});
			} else {
				vm.showTrCaseActions = false;
				vm.selectedWorkCases = [];
			}
			angular.forEach(vm.trCases, function (itm) { itm.isChecked = toggleStatus; });
		}

		function updateWorkFlowGroup(name) {
			vm.multgroup.forEach(function (group) {
				if (group.name == name) {
					if (vm.selectedWorkCases.length > 0) {
						var workFlowGroupArray = [];
						vm.selectedWorkCases.forEach(function (trCaseId) {
							workFlowGroupArray.push({ trCaseId: trCaseId, groupId: group.id });
						});
						TrCaseUpdateGroup.update(workFlowGroupArray, onSuccess, onError);
						function onSuccess() {
							vm.groupCases = false;
		                    vm.showTrCaseActions = false;
							filterTrcaseSearch();
							
							// $state.go('tr-case');
						}
						function onError() {
						}
					} else {
						alert("Please select trcase to update group");
					}
				}
			});
		}

		function selectedWorkFlow(name) {
			if (name == 'Update Group Mapping') {
				vm.groupCases = true;
			} else if (name == 'Delete Multiple Workflows') {
				vm.groupCases = false;
				if (vm.selectedWorkCases.length > 0) {
					var workFlowGroupArray = [];
					vm.selectedWorkCases.forEach(function (trCaseId) {
						workFlowGroupArray.push({ id: trCaseId });
					});
					TrCaseBulkDelete.save(workFlowGroupArray, onSuccess, onError);
					function onSuccess() {
						vm.groupCases = false;
						vm.showTrCaseActions = false;
						filterTrcaseSearch();
						// $state.go('tr-case');
					}
					function onError(error) {
						var msg = "Trcase deletion failed, WorkFlow has some dependent data";
						if (error.status == 400) {
							AlertService.error(msg);
						} else {
							AlertService.error(error.data.message);
						}
					}
				} else {
					alert("Please select trCase to delete list");
				}
			}
		}

		function animatedMove(marker, t, current, moveto) {
			var lat = current.lat();
			var lng = current.lng();

			var deltalat = (moveto.lat() - current.lat()) / 100;
			var deltalng = (moveto.lng() - current.lng()) / 100;

			var delay = 10 * t;
			for (var i = 0; i < 100; i++) {
				(function (ind) {
					setTimeout(
						function () {
							var lat = marker.position.lat();
							var lng = marker.position.lng();
							lat += deltalat;
							lng += deltalng;
							latlng = new google.maps.LatLng(lat, lng);
							marker.setPosition(latlng);
						}, delay * ind
					);
				})(i)
			}
		}

		function drawResponseMap(data) {
			var myOptions = {
				zoom: 10,
				center: new google.maps.LatLng("5.431587","101.128162"),
				mapTypeId: google.maps.MapTypeId.ROADMAP
			};

			if ($scope.tab == 2 || $scope.tab == 5) {

				locations.map = new google.maps.Map(document.getElementById("map_canvas1"), myOptions);
				locations.overlay = new google.maps.OverlayView();
				locations.overlay.draw = function () {
				};
				locations.overlay.setMap($scope.map);
				locations.element = document.getElementById('map_canvas1');
				drawLatestTrCases(data);
			} else if ($scope.tab == 3) {

				locations.map = new google.maps.Map(document.getElementById("map_canvas2"), myOptions);
				locations.overlay = new google.maps.OverlayView();
				locations.overlay.draw = function () {
				};
				locations.overlay.setMap($scope.map);
				locations.element = document.getElementById('map_canvas2');
				drawCasesAndNearByAgents(data);
				TrCaseLastLocation.getlastlogs(function (response) {
					var lastLocByAgents = response;
					var markerIcon = "inactive";
					var imageUrl = "";
					switch (markerIcon) {
						case "inactive":
							imageUrl = "content/images/marker_inactive.png"
							break;
						case "idle":
							imageUrl = "content/images/marker_idle.png"
							break;
						case "active":
							imageUrl = "content/images/marker_active.png"
							break;
						case "active_case":
							imageUrl = "content/images/marker_active_case.png"
							break;
						default:
							imageUrl = "content/images/green.png"
							break;
					}

					var lastLocVal = [];
					removeMarkers();
					for (var i = 0; i < lastLocByAgents.length; i++) {
						var path = lastLocByAgents[i].path;
						if (path.length > 1) {
							var marker = new google.maps.Marker({
								position: new google.maps.LatLng(path[0].lat, path[0].lng),
								map: locations.map,
								icon: {
									scaledSize: new google.maps.Size(
										42, 40),
									origin: new google.maps.Point(0, 0),
									url: imageUrl,
									anchor: new google.maps.Point(
										16, 16),

								},
								label: { color: '#000000', fontWeight: 'bold', fontSize: '16px', text: lastLocByAgents[i].userName, backgroundcolor: "#ff9800" },
								title: lastLocByAgents[i].userName
							});
							var markerContent = '<div class="infoWindowhead">'
								+ name
								+ '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> '
								+ '<br> <b>Lng :</b> '
								+ '</div>';
							var infowindow = new google.maps.InfoWindow({
								content: markerContent
							});
							marker.addListener('click', function () {
								infowindow.open(locations.map, marker);
							});

							animatedMove(marker, 50, marker.position, new google.maps.LatLng(path[1].lat, path[1].lng));
							nearbyAgentsMarkers.push(marker);
						}
					}
				});

				//					 var updateUserAgentPath = $interval(function(){
				//						TrCaseLastLocation.getlastlogs( function(response) {
				//							var lastLocByAgents = response;
				//							var markerIcon = "inactive";
				//							var imageUrl = "";
				//							switch (markerIcon) {
				//								case "inactive":
				//									imageUrl = "content/images/marker_inactive.png"
				//									break;
				//								case "idle":
				//									imageUrl = "content/images/marker_idle.png"
				//									break;
				//								case "active":
				//									imageUrl = "content/images/marker_active.png"
				//									break;
				//								case "active_case":
				//									imageUrl = "content/images/marker_active_case.png"
				//									break;
				//								default:
				//									imageUrl = "content/images/green.png"
				//									break;
				//							}
				//
				//							var lastLocVal = [];
				//							removeMarkers();
				//							for (var i = 0; i < lastLocByAgents.length; i++) {
				//								var path = lastLocByAgents[i].path;
				//								if(path.length > 1){
				//										var marker = new google.maps.Marker({
				//											position: new google.maps.LatLng(path[0].lat, path[0].lng),
				//											map: locations.map,
				//											icon : {
				//												scaledSize : new google.maps.Size(
				//														42, 40),
				//														origin : new google.maps.Point(0, 0),
				//														url : imageUrl ,
				//														anchor : new google.maps.Point(
				//																		16, 16),
				//						
				//											},
				//											label: { color: '#000000', fontWeight: 'bold', fontSize: '16px', text: lastLocByAgents[i].userName, backgroundcolor: "#ff9800" },
				//											title : lastLocByAgents[i].userName
				//										});
				//										var markerContent = '<div class="infoWindowhead">'
				//											+ name
				//											+ '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> '
				//											+ '<br> <b>Lng :</b> '
				//											+ '</div>';
				//										var infowindow = new google.maps.InfoWindow({
				//											content: markerContent
				//										});
				//										marker.addListener('click', function() {
				//											infowindow.open(locations.map, marker);
				//										});
				//										animatedMove(marker, 50, marker.position, new google.maps.LatLng(path[1].lat, path[1].lng));
				//										nearbyAgentsMarkers.push(marker);
				//									}
				//							}
				//						});
				//					}, 10000);
			}

		}
		function removeMarkers() {
			for (var i = 0; i < nearbyAgentsMarkers.length; i++) {
				nearbyAgentsMarkers[i].setMap(null);
			}
			nearbyAgentsMarkers = [];
		}
		/*function filterTrcases() {
			vm.itemsPerPage = 1000;
			if ($scope.search != null && $scope.search != "" && !angular.isUndefined($scope.search)) {
				if ($stateParams.priority == undefined) {

					TrCaseSearch.query({
						search : $scope.search,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				} else {
					TrCasePrioritySearch.query({
						searchtext : $scope.search,
						priority : $stateParams.priority,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				}
			} else {
				if ($stateParams.priority == undefined) {

					TrCase.query({
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				} else {
					TrCasePriority.query({
						priority : $stateParams.priority,
						page : pagingParams.page - 1,
						size : vm.itemsPerPage,
						sort : sort()
					}, onSuccess, onError);
				}
			}
			function sort() {
				var result = [ vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					// result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.page = pagingParams.page;
				var myOptions = {
					zoom : 10,
					center : new google.maps.LatLng("5.431587","101.128162"),
					mapTypeId : google.maps.MapTypeId.ROADMAP
				};

				if ($scope.tab == 2) {

					locations.map = new google.maps.Map(document.getElementById("map_canvas1"), myOptions);
					locations.overlay = new google.maps.OverlayView();
					locations.overlay.draw = function() {
					};
					locations.overlay.setMap($scope.map);
					locations.element = document.getElementById('map_canvas1');
					drawLatestTrCases(data);
				} else if ($scope.tab == 3) {

					locations.map = new google.maps.Map(document.getElementById("map_canvas2"), myOptions);
					locations.overlay = new google.maps.OverlayView();
					locations.overlay.draw = function() {
					};
					locations.overlay.setMap($scope.map);
					locations.element = document.getElementById('map_canvas2');
					drawCasesAndNearByAgents(data);
				}

			}
			function onError(error) {
				AlertService.error(error.data.message);
			}

		}*/


		/*if ($scope.search === null || $scope.search === "" || angular.isUndefined($scope.search)) {
			loadAll();
		} else {
			if ($scope.tab == 2) {
				filterTrcases();
				
			}
			if ($scope.tab == 3) {
				TrCaseUser.gettrcasesuser(function(response) {
					$scope.trcaseuser = response;
				});
				filterTrcases();
				
			} else if ($scope.tab == 1) {
				vm.itemsPerPage = paginationConstants.itemsPerPage;
				filterTrcaseSearch();
			}
		}*/



		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: $scope.search,
				priority: pagingParams.priority
			});
		}

		$scope.openInfoWindow = function (e, selectedMarker) {
			e.preventDefault();
			google.maps.event.trigger(selectedMarker, 'click');
		}



		function drawLatestTrCases(response) {

			if (response) {
				initializeTrcaseMap(response, function (latlang) {
					locations.latlng = latlang;
					// function() {
					$.each(response, function (index, value) {
						var infoWindow = new google.maps.InfoWindow();
						var startMarker = new google.maps.Marker({
							map: locations.map,
							position: new google.maps.LatLng(value.pinLat, value.pinLong),
							title: value.description,

						});
						var infoWindow2 = new google.maps.InfoWindow();
						// if(value.userCase=='C'){
						if (value.priority == 'LOW') {
							startMarker.setIcon('content/images/Pointers-L4.png');
						} else if (value.priority == 'MEDIUM') {
							startMarker.setIcon('content/images/Pointers-M4.png');
						} else if (value.priority == 'HIGH') {
							startMarker.setIcon('content/images/Pointers-H4.png');
						} else if (value.priority == 'CRITICAL') {
							startMarker.setIcon('content/images/Pointer-C4.png');
						}
						var span = '<a userId="' + value.geofenceName + '" class="userpath" >Load travelled path</a>';
						startMarker.content = '<div class="infoWindowContent"> <b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> ' + value.assignedToUser
							+ '<br> <b>Address :</b> ' + value.address + '</div>';
						$compile(startMarker.content)($scope);
						google.maps.event.addListener(startMarker, 'mouseover', function () {
							infoWindow.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
							infoWindow.open(locations.map, startMarker);
						});


						// }
						// on mouseout
						google.maps.event.addListener(startMarker, 'mouseout', function () {
							infoWindow.close();
						});


						//						var activeInfoWindow;
						//						// add content to InfoWindow for click event
						//						infoWindow2.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
						//
						//						// add listener on InfoWindow for click event
						//						google.maps.event.addListener(startMarker, 'click', function() {
						//
						//							if (activeInfoWindow != null)
						//								activeInfoWindow.close();
						//							// Open InfoWindow - on click
						//							infoWindow2.open(locations.map, startMarker);
						//							$(".userpath").click(function() {
						//								var ele = document.getElementById("userPathModal");
						//								$(ele).modal('show');
						//								vm.drawUserPath($(this).attr('userid'));
						//							});
						//
						//							// Store new open InfoWindow in global variable
						//							activeInfoWindow = infoWindow2;
						//						});
						//						resizeTrCaseMap();
					});
					// resizeTrCaseMap();
					// };
				});
			}

			/*
			 * function setupTrcaseMap(callback) { var myOptions = { zoom : 10,
			 * center : new google.maps.LatLng("5.431587","101.128162"), mapTypeId :
			 * google.maps.MapTypeId.ROADMAP }; locations.map = new
			 * google.maps.Map(document.getElementById("map_canvas1"),
			 * myOptions); locations.overlay = new google.maps.OverlayView();
			 * locations.overlay.draw = function() { };
			 * locations.overlay.setMap($scope.map); locations.element =
			 * document.getElementById('map_canvas1'); callback(); }
			 */
		}

		//		function resizeTrCaseMap() {
		//			setTimeout(function() {
		//				google.maps.event.trigger(locations.map, "resize");
		//				locations.map.setCenter(locations.latlng);
		//			}, 1000);
		//		}

		function initializeTrcaseMap(latlangs, callback) {
			if (latlangs && latlangs.length > 0) {
				latlng = new google.maps.LatLng(latlangs[0].pinLat, latlangs[0].pinLong);
				callback(latlng);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {

						latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
						callback(latlng);
					}, function () {

						callback(new google.maps.LatLng("5.431587","101.128162"));
					});
				} else {
					callback(new google.maps.LatLng("5.431587","101.128162"));
				}
			}
		}

		/*
		 * function drawCasesA() { drawLatestCases($scope.trcaseuser); }
		 */
		function drawCasesAndNearByAgents(response) {

			if (response) {
				initializeMap(response, function (latlang) {
					locations.latlng = latlang;
					// setupMap(function() {
					$.each(response, function (index, value) {
						var infoWindow = new google.maps.InfoWindow();
						var startMarker = new google.maps.Marker({
							map: locations.map,
							position: new google.maps.LatLng(value.pinLat, value.pinLong),
							title: value.description,

						});

						var infoWindow2 = new google.maps.InfoWindow();

						if (value.priority == 'LOW') {
							startMarker.setIcon('content/images/Pointers-L4.png');
						} else if (value.priority == 'MEDIUM') {
							startMarker.setIcon('content/images/Pointers-M4.png');
						} else if (value.priority == 'HIGH') {
							startMarker.setIcon('content/images/Pointers-H4.png');
						} else if (value.priority == 'CRITICAL') {
							startMarker.setIcon('content/images/Pointer-C4.png');
						}
						var span = '<a userId="' + value.geofenceName + '" class="userpath" >Load travelled path</a>';
						startMarker.content = '<div class="infoWindowContent"><b>Case Id : </b>' + value.id + '<br> <b>Reported By :</b> ' + value.reportedByUser + '<br> <b> Assigned To :</b> '
							+ value.assignedToUser + '<br> <b>Address :</b> ' + value.address + '</div>';

						$compile(startMarker.content)($scope);
						google.maps.event.addListener(startMarker, 'mouseover', function () {
							infoWindow.setContent('<div class="infoWindowhead">' + startMarker.title + '</div>' + startMarker.content);
							infoWindow.open(locations.map, startMarker);
						});

						// on mouseout
						google.maps.event.addListener(startMarker, 'mouseout', function () {
							infoWindow.close();
						});

						resizeMap();
					});
					// });
				});
			}
			//			drawAgents();
		}

		function drawAgents() {

			var infoWindow = null;
			var markers = [{
				Lat: "0.0",
				Lng: "0.0",
				name: "default"
			}];
			TrCaseUser.getAgents(function (response) {
				$.each(response.liveLogs,
					function (index, value) {

						infoWindow = new google.maps.InfoWindow();

						var imgbg = document.createElement('img');
						imgbg.className = "marker_back_image";

						if (value.status == 'ACTIVE') {
							imgbg.src = 'content/images/greenmarker.png';
						} else if (value.status == 'INACTIVE') {
							imgbg.src = 'content/images/redmarker.png';
						} else if (value.status == 'IDLE') {
							imgbg.src = 'content/images/yellowmarker.png';
						}

						function customMarker() {
							this.latlng = new google.maps.LatLng(value.latitude, value.longitude);
							this.setMap(locations.map);
							this.title = value.login;
							this.icon = imgbg;
						}
						var userContent;
						if (value.userImage != null) {
							userContent = '<div class="infoWindowhead">' + value.login
								+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="data:image/png;base64,' + value.userImage
								+ '" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>' + value.address + '</p><p>Phone : ' + value.phone + '</p><p>Battery :'
								+ value.batteryPercentage + ' %</p></div></div></div>';
						} else {
							userContent = '<div class="infoWindowhead">'
								+ value.login
								+ '</div><div class="infoWindowContent"><div class="rows"><div class="col-md-4"><img src="content/images/userimg.gif" style="width: 50px; height: 60px;"></div><div class="col-md-8"><p>'
								+ value.address + '</p><p>Phone : ' + value.phone + '</p><p>Battery :' + value.batteryPercentage + ' %</p></div></div></div>';
						}
						var infowindowPopup = new google.maps.InfoWindow({
							content: userContent,
							pixelOffset: new google.maps.Size(0, -50),
						});
						infowindowPopup.setPosition(new google.maps.LatLng(value.latitude, value.longitude));

						customMarker.prototype = new google.maps.OverlayView();
						customMarker.prototype.draw = function () {
							var self = this;
							var div = this.div;

							if (!div) {

								div = this.div = document.createElement('div');
								div.id = 'marker';
								div.style.width = '100px';
								div.style.height = '100px';

								var div_pointer = document.createElement('div');
								div_pointer.className = 'triangle';

								var image_container = document.createElement('div');
								image_container.className = 'image_container';

								var img = document.createElement('img');
								img.className = "marker_image";
								img.src = "data:image/png;base64,";
								img.src += value.userImage;
								if (value.userImage == undefined) {
									img.src = "content/images/userimg.gif";
								}

								var name_container = document.createElement('div');
								name_container.className = 'name_container';

								div.appendChild(image_container);
								image_container.appendChild(img);
								image_container.appendChild(imgbg);

								name_container.onmouseover = function () {
									name_container.style.opacity = '0.6';
									div.style.zIndex = '1000'
								};
								name_container.onmouseout = function () {
									name_container.style.opacity = '0';
									div.style.zIndex = '100'
								};

								google.maps.event.addDomListener(imgbg, "click", function (event) {
									google.maps.event.trigger(self, "click");
									infowindowPopup.open(locations.map);
								});

								var panes = this.getPanes();

								panes.overlayImage.appendChild(div);

							}

							var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

							if (point) {
								div.style.left = (point.x - 50) + 'px';
								div.style.top = (point.y - 125) + 'px';
							}
						}

						customMarker.prototype.getPosition = function () {
							return this.latlng;
						}
						markers.forEach(function (marker) {
							var overlay = new customMarker();
						});
					})
			});
		}
		$scope.caseLocation = function (selectedCase) {
			$("#caseLocationModal").show();
			var myCenter = new google.maps.LatLng(selectedCase.pinLat, selectedCase.pinLong);
			var ele = document.getElementById("caseLocationModal");
			$(ele).modal('show');

			var map = new google.maps.Map(document
				.getElementById('map_canvas_case_location'), {
				center: myCenter,
				zoom: 18
			});

			var markerIcon = '';
			if (selectedCase.priority == 'LOW') {
				markerIcon = 'content/images/Pointers-L4.png';
			} else if (selectedCase.priority == 'MEDIUM') {
				markerIcon = 'content/images/Pointers-M4.png';
			} else if (selectedCase.priority == 'HIGH') {
				markerIcon = 'content/images/Pointers-H4.png';
			} else if (selectedCase.priority == 'CRITICAL') {
				markerIcon = 'content/images/Pointer-C4.png';
			}

			var markerContent = null;
			if (selectedCase.caseDistance != null) {
				markerContent = '<div class="infoWindowhead">'
					+ '<b>Case Id : </b>' + selectedCase.id + '<br><b>Description : </b>' + selectedCase.description + '<br> <b>Lat : </b>' + selectedCase.pinLat + '<br> <b>Lng : </b>' + selectedCase.pinLong
					+ '<br><b>Address : </b>' + selectedCase.address + '<br><b>Distance (km) : </b>' + selectedCase.caseDistance + '</div>';
			} else {
				markerContent = '<div class="infoWindowhead">'
					+ '<b>Case Id : </b>' + selectedCase.id + '<br><b>Description : </b>' + selectedCase.description + '<br> <b>Lat : </b>' + selectedCase.pinLat + '<br> <b>Lng : </b>' + selectedCase.pinLong
					+ '<br><b>Address : </b>' + selectedCase.address + '</div>';
			}
			var marker = new google.maps.Marker({
				position: myCenter,
				content: markerContent,
				icon: {
					url: markerIcon,
					size: new google.maps.Size(70, 86), //marker image size
					origin: new google.maps.Point(0, 0), // marker origin
					anchor: new google.maps.Point(35, 86) // X-axis value (35, half of marker width) and 86 is Y-axis value (height of the marker).
				}
			});

			var infowindow = new google.maps.InfoWindow({
				content: markerContent
			});
			infowindow.open(map, marker);
			marker.addListener('click', function () {
				infowindow.open(map, marker);
			});
			marker.setMap(map);

			$('#caseLocationModal').on('show.bs.modal', function (event) {

				$("#location-map-case-location").css("width", "100%");
				$("#map_canvas_case_location").css("width", "100%");
			});
		}

		function resizeMap() {
			setTimeout(function () {
				google.maps.event.trigger(locations.map, "resize");
				locations.map.setCenter(locations.latlng);
			}, 1000);
		}

		function initializeMap(latlangs, callback) {
			if (latlangs && latlangs.length > 0) {
				latlng = new google.maps.LatLng(latlangs[0].pinLat, latlangs[0].pinLong);
				callback(latlng);
			} else {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {

						latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
						callback(latlng);
					}, function () {

						callback(new google.maps.LatLng("5.431587","101.128162"));
					});
				} else {
					callback(new google.maps.LatLng("5.431587","101.128162"));
				}
			}
		}

		$('.select2').select2();

		function assigncasetouser(userId, userLogin) {
			vm.editUserId = userId;
			$scope.edituserLogin = userLogin;
			TrCaseEdit.getalltrcases(function (response) {
				$scope.trallcases = response;

			});

		}
		vm.success = null;
		vm.error = null;
		function editcase() {
			var data = {
				userId: vm.editUserId,
				caseId: vm.seachCaseId
			}

			/*
			 * TrCaseEdit.edittrcase(data, function(response){
			 * $scope.edittrcaseresponse=response; vm.success = 'OK';
			 * 
			 * });
			 */
			TrCaseEdit.edittrcase(data, function (response, headers) {

				if (response.status == 200) {
					vm.error = null;
					vm.success = 'OK';

				}
				if (response.status == 400) {
					vm.success = null;
					vm.error = 'No case found with this case id';

				}
			});
			$timeout(function () {
				$('#assigncaseModal').modal('hide');
				vm.success = null;
				vm.error = null;
				vm.seachCaseId = null;
				vm.caseIds = null;
			}, 2000);
		}

	}

	var uniqueItems = function (data, key) {
		var result = [];

		for (var i = 0; i < data.length; i++) {
			var value = data[i][key];

			if (result.indexOf(value) == -1) {
				result.push(value);
			}

		}
		return result;
	};

})();
