(function() {
    'use strict';

    angular.module('trakeyeApp').controller('TrCaseWorkFlowController', TrCaseWorkFlowController);

    TrCaseWorkFlowController.$inject = ['$scope', '$state', '$stateParams', 'entity', 'AlertService', 'TrCase', 'AssetGroupSearch', 'AssetGroup', 'AllCaseTypes', 'AllCustomCaseTypeAttribute', 'AllCustomAssetTypeAttribute', 'UserGroupIDs', 'ActivatedUserSearch', 'AllAssetsTypes', 'AssetType', 'TrCaseCheckAsset'];

    function TrCaseWorkFlowController($scope, $state, $stateParams, entity, AlertService, TrCase, AssetGroupSearch, AssetGroup, AllCaseTypes, AllCustomCaseTypeAttribute, AllCustomAssetTypeAttribute, UserGroupIDs, ActivatedUserSearch, AllAssetsTypes, AssetType, TrCaseCheckAsset) {
        var vm = this;

        vm.trCase = entity;
        var entity_old = angular.copy(vm.trCase);
        vm.filterActivatedUser = filterActivatedUser;
        vm.selectattributes = selectedCaseTypeAttributes;
        vm.saveEditAsset = saveEditAsset;
        vm.selectedGroup = selectedGroup;
        var openedInfoWindow = null;
        vm.connectSelectedAssets = connectSelectedAssets;
		vm.saveLinkPits = saveLinkPits;
		vm.connectMultipleAssets = connectMultipleAssets;
		vm.hideConnectMultipleAssetsModal = hideConnectMultipleAssetsModal;
		vm.searchAssetName = searchAssetName;
		vm.refreshPage = refreshPage;
        vm.connectAssets = connectAssets;
		vm.recalculate = recalculate;
        vm.isSiteASelected = false;
        vm.isSiteBSelected = false;
        vm.selectedSiteA = {};
        vm.selectedSiteB = {};
		vm.linkAssetType = null;
		vm.manholeAssetType = null;
	
        vm.editAsset = editAsset;
        vm.caseTypes = [];
        vm.multigroup = [];
        vm.assetType = [];
        var parentSite = []; 

        vm.deleteAssetModal = deleteAssetModal;
        vm.confirmDeleteAsset = confirmDeleteAsset;
        vm.cleardeleteAsset = cleardeleteAsset;
        vm.clearCreateAsset = clearCreateAsset;
        var deleteAssetName = null;
        vm.createAssetModal = createAssetModal;
        vm.createAsset = createAsset;
        vm.assetNameVal = null;
        vm.assetLat = null;
        vm.assetLong = null;
        var createAssetData = {};

        vm.customAttributesMap = {};
        var usersMap = {};
        var mappedAssetTemp = {};
        vm.mappedAssetsList = [];
		vm.tempLinkWithPits = [];
		vm.deletedAssetsList = [];
        var selectedGroup = [];
        var assetsPlanningMap = null;
        var assetsDeployedMap = null;
        vm.isShowing = true;
        vm.save = save;
        vm.handleTabClick = handleTabClick;
        vm.assetExisting = false;
        $scope.search = null;
        vm.assets = [];
        vm.showAssetCreation = false;
        vm.showAssetName = false;
		var centerlatlng = null;
        vm.hideUser = true;
        var marker = null;
//        vm.searchAssets = searchAssets;
        var editAssetMap = null;
        vm.validUser = true;
        vm.assignedUsers = [];
        vm.validUser = true;
        vm.hideConnectSitesModal = hideConnectSitesModal;
        vm.previousId = sessionStorage.getItem("prevEditId");
        if(vm.previousId) {
            vm.validUser = false;
        }
        $scope.tab = 1;
        vm.siteAssets = [];
        if ($scope.tab == 1) {
            vm.toggleFirst = true;
            vm.toggleSecond = false;
            vm.toggleThird = false;
            AllCaseTypes.getAll(function(response) {
                vm.caseTypes = response;
            });

            AllCustomCaseTypeAttribute.getAll(function(response) {
                response.forEach(function(arrayItem) {
                    vm.customAttributesMap[arrayItem.name] = arrayItem.customCaseTypeAttributeValues;
                });
            });

            if (vm.trCase.id != null) {
				vm.hideUser = false;
                vm.trCase.groups.forEach(function(group) {
                    selectedGroup.push(group.id);
                });
            }

            UserGroupIDs.getAll(function(response) {
                response.forEach(function(group) {
                    if (selectedGroup.indexOf(group.id) !== -1) {
                        vm.multigroup.push({ name: group.name, id: group.id, ticked: true });
                    } else {
                        vm.multigroup.push({ name: group.name, id: group.id, ticked: false });
                    }
                });
            });
            AllAssetsTypes.getAll(function(response) {
                response.forEach(function(assettype) {
                    vm.assetType.push(assettype);
                });
            });

            AllCustomAssetTypeAttribute.getAll(function(response) {
                response
                    .forEach(function(arrayItem) {
                        vm.customAttributesMap[arrayItem.name] = arrayItem.customAssetTypeAttributeValues;
                    });
            });

            if (vm.trCase.id != null) {
                vm.trCase.caseAssets.forEach(function(asset) {
                    asset.isChecked = true;
                    vm.mappedAssetsList.push(asset);
                    vm.isShowing = false;
                    $scope.toggle = true;
                });
                vm.caseType = entity.caseType;
            }
        }

        $scope.isSet = function(tabNum) {
            return $scope.tab === tabNum;
        };

        $scope.setTab = function(newTab) {
            $scope.tab = newTab;
            $scope.search = "";

            if($scope.tab == 1){
                vm.toggleFirst = true;
                vm.toggleSecond = false;
                vm.toggleThird = false;
            }else if ($scope.tab == 2) {
                vm.toggleFirst = false;
                vm.toggleSecond = true;
                vm.toggleThird = false;
                initPlanningMap();
                for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                    if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                        createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                    } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                        var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                        drawPolylineForGeneratedLoc(vm.mappedAssetsList[i],assetCoordinateValues, assetsPlanningMap, "yellow");
                    }
                }
				
				AssetType.query(function(data) {
                    data.forEach(function(item) {
                        if (item.name == "Link") {
							vm.linkAssetType = {};
							vm.linkAssetType.assetType = item;
                            vm.linkAssetType.assetTypeAttributeValues = getConsolidatedAssetTypeAttributeValue(vm.linkAssetType.assetType);
//                            $.each(vm.linkAssetType.assetType.assetTypeAttributes,function(key, value) {
//                                vm.linkAssetType.assetTypeAttributeValues.push({
//                                        assetTypeAttribute: vm.linkAssetType.assetType.assetTypeAttributes[key]
//                                    });
//                            });
						} else if (item.name == "Manhole") {
							vm.manholeAssetType = {};
							vm.manholeAssetType.assetType = item;
                            vm.manholeAssetType.assetTypeAttributeValues = getConsolidatedAssetTypeAttributeValue(vm.manholeAssetType.assetType);
//                            $.each(vm.manholeAssetType.assetType.assetTypeAttributes,function(key, value) {
//                                vm.manholeAssetType.assetTypeAttributeValues.push({
//                                        assetTypeAttribute: vm.manholeAssetType.assetType.assetTypeAttributes[key]
//                                    });
//                            });
						}
					});
				});
				
            } else if ($scope.tab == 3) {
				vm.toggleFirst = false;
                vm.toggleSecond = false;
                vm.toggleThird = true;
				var totalCost = 0;
                var totalPowerBudget = 0;
				var totalFiberDistance = 0.0;
                for (var assetIndex = 0; assetIndex < vm.mappedAssetsList.length; assetIndex++) {
					if(vm.mappedAssetsList[assetIndex].name != null){
	                    var assetAV = vm.mappedAssetsList[assetIndex].assetTypeAttributeValues;
	                    var isSpread = false;
	                    var fiberDistance = 0.0;
	
						if (vm.mappedAssetsList[assetIndex].assetType.layout == "SPREAD") {
							isSpread = true;
		                    var spreadAV = vm.mappedAssetsList[assetIndex].assetTypeAttributeValues;
		                    for (var spreadAssetIndex = 0; spreadAssetIndex < spreadAV.length; spreadAssetIndex++) {
		                        if (spreadAV[spreadAssetIndex].assetTypeAttribute.name === "Fiber Distance (km)") {
		                          	fiberDistance = parseFloat("0"+spreadAV[spreadAssetIndex].attributeValue);
		                        }
		                    }
		                }
	
	                    assetAV.forEach(function(item) {
	                        var value = 0;
	                        if (item.attributeValue != null) {
	                            value = parseFloat(item.attributeValue);
	                        } else {
	                            value = parseFloat(item.assetTypeAttribute.defaultValue);
	                        }
	
	                        if (item.assetTypeAttribute.name == "Cost") {
	                            var tempCost = parseFloat(value);
	                            if (isSpread) {
	                                tempCost = parseFloat(value) * fiberDistance;
	                            }
	                            if (tempCost > 0) {
	                                totalCost += tempCost
	                            }
	                        }
	                        if (item.assetTypeAttribute.name == "Power Budget") {
	                            var tempPowerBudget = parseFloat(value);
	                            if (isSpread) {
	                                tempPowerBudget = parseFloat(value) * fiberDistance;
	                            }
	                            if (tempPowerBudget > 0) {
	                                totalPowerBudget += tempPowerBudget;
	                            }
	                        }
	                    });
						totalFiberDistance += fiberDistance;
					}
                }

                var caseAV = vm.trCase.caseTypeAttributeValues;
                for (var caseIndex = 0; caseIndex < caseAV.length; caseIndex++) {
                    if (caseAV[caseIndex].caseTypeAttribute.name === "Cost") {
                        caseAV[caseIndex].attributeValue = totalCost.toFixed(2);
                    }
                    if (caseAV[caseIndex].caseTypeAttribute.name === "Power Budget") {
                        caseAV[caseIndex].attributeValue = totalPowerBudget.toFixed(2);
                    }
					if (caseAV[caseIndex].caseTypeAttribute.name === "Fiber Distance") {
                        caseAV[caseIndex].attributeValue = totalFiberDistance.toFixed(2);
                    }
                }
                vm.trCase.caseTypeAttributeValues = caseAV;

                assetsDeployedMap = new google.maps.Map(document.getElementById('map_canvas_deployed'), {
                    center: {
                        lat: 5.431587,
                        lng: 101.128162
                    },
                    zoom: 13,
                    fullscreenControl: true
                });


                for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                    if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                        createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsDeployedMap);
                    } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                        var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                        drawPolylineForGeneratedLoc(vm.mappedAssetsList[i],assetCoordinateValues, assetsDeployedMap, "yellow");
                    }
                }

                if (vm.mappedAssetsList.length > 0) {
                    vm.trCase.pinLat = vm.mappedAssetsList[0].assetCoordinates[0].latitude;
                    vm.trCase.pinLong = vm.mappedAssetsList[0].assetCoordinates[0].longitude;
                    vm.trCase.address = '';
                    if (vm.trCase.id == null) {
                        vm.trCase.status = "NEW";
                    }
                }
                if (vm.mappedAssetsList.length > 0) {
                    assetsDeployedMap.setCenter(new google.maps.LatLng(vm.mappedAssetsList[0].assetCoordinates[0].latitude, vm.mappedAssetsList[0].assetCoordinates[0].longitude));
                }
//
//				var pointA = new google.maps.LatLng(12.917358683134225,77.62311194634297);
//            	var pointB = new google.maps.LatLng(12.915999226925456,77.63823960497554);
//
//				getPointsRoute(pointA, pointB, assetsDeployedMap, 200);
            }
        }

        function handleTabClick($event) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        }

        function clearCreateAsset() {
            $("#create-asset").removeClass("in");
            $(".modal-backdrop").remove();
            $("#create-asset").hide();
        }

        function createAssetModal(item) {
			vm.assetExisting = false;
			if(item.name == "LINK_WITH_MH"){
				connectMultipleAssets();
			}else{
	            vm.CreateAssetName = item.name;
	            vm.assetNameVal = null;
	            vm.assetLat = null;
	            vm.assetLong = null;
	            $('#create-asset').show();
	            createAssetData = item;
	            vm.showAssetName = false;
                vm.parentAsset = null;
			}
        }

		function getConsolidatedAssetTypeAttributeValue(assetType){
			var assetTypeAttributeValuesList = [];

            for (var index in assetType.assetTypeAttributes) {
                var assetTypeAttribute1 = assetType.assetTypeAttributes[index];
                assetTypeAttributeValuesList.push({ 'assetTypeAttribute': assetTypeAttribute1, 'attributeValue': assetType.assetTypeAttributes[index].defaultValue });
            }

            assetTypeAttributeValuesList
                .sort(function(a, b) {
                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                        nameB = b.assetTypeAttribute.name.toLowerCase()
                    if (nameA < nameB) // sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 // default return value (no sorting)
                });
            return assetTypeAttributeValuesList;
		}

        function searchAssetName() {
            vm.assetExisting = false;
            var searchName = vm.assetNameVal;
            vm.showAssetCreation = false;
            if (searchName != undefined && searchName.length > 2) {
                TrCaseCheckAsset.get({ name: searchName }, function (checkAsset) {
                    if (checkAsset.name == searchName) {
                        vm.assetExisting = true;
                        vm.showAssetCreation = true;
                    } else if (vm.mappedAssetsList.length > 0) {
                        vm.mappedAssetsList.forEach(function (maplist) {
                            if (maplist.name == searchName) {
                                vm.assetExisting = true;
                                vm.showAssetCreation = true;
                            }
                        });
                    }
                });
            }
        }
		
		function refreshPage(){
			if (marker != null) {
                marker.setMap(null);
            }

			initPlanningMap();
            
			for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                    createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                    var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                    drawPolylineForGeneratedLoc(vm.mappedAssetsList[i],assetCoordinateValues, assetsPlanningMap, "yellow");
                }
            }
		}
		
		function createAsset() {
            var assetName = vm.assetNameVal;
            vm.assetExisting = false;
            var coOrdinate =  [];
            parentSite = [];
            if (marker != null) {
                marker.setMap(null);
            }
            if (vm.parentAsset != null) {
                for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                    if (vm.parentAsset == vm.mappedAssetsList[i].name) {
                        parentSite.push(vm.mappedAssetsList[i]);
                        coOrdinate.push({
                            lat: vm.mappedAssetsList[i].assetCoordinates[0].latitude,
                            lng: vm.mappedAssetsList[i].assetCoordinates[0].longitude
                        });
                        assetsPlanningMap = new google.maps.Map(document.getElementById('map_canvas_planning'), {
                            center: coOrdinate[0],
                            zoom: 13,
                            fullscreenControl: true
                        });
                    }
                }
            } else {
                assetsPlanningMap = new google.maps.Map(document.getElementById('map_canvas_planning'), {
                    center: {
                        lat: 5.431587,
                        lng: 101.128162
                    },
                    zoom: 13,
                    fullscreenControl: true
                });
            }
             if(assetName && assetName.length > 2) {
                if (!isAssetAvailable(assetName)) {
                    for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                        if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                            createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                        } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                            var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                            drawPolylineForGeneratedLoc(vm.mappedAssetsList[i], assetCoordinateValues, assetsPlanningMap, "yellow");
                        }
                    }
                    mappedAssetTemp = {};
                    mappedAssetTemp.name = assetName;
                    mappedAssetTemp.description = assetName;
                    mappedAssetTemp.assetType = createAssetData;
                     // mappedAssetTemp.ownedBy = parentSite;
                     mappedAssetTemp.parentAsset = parentSite;
					var date = new Date();
					mappedAssetTemp.createDate = date.getTime();
                    mappedAssetTemp.assetTypeAttributeValues = getConsolidatedAssetTypeAttributeValue(createAssetData);
//                    createAssetData.assetTypeAttributeValues = [];
//                    $.each(createAssetData.assetTypeAttributes,function (key) {
//                            mappedAssetTemp.assetTypeAttributeValues.push({
//                                assetTypeAttribute: createAssetData.assetTypeAttributes[key]
//                            });
//                        });
//					
                    if (createAssetData.layout == 'SPREAD') {
                        drawingManagerSpread();
                        drawingManagerValue('polylinecomplete', assetsPlanningMap, false);
                    } else if (createAssetData.layout == 'FIXED') {
                        if (vm.assetLat > 0 && vm.assetLong > 0) {
                            vm.fixedArray = [];
                            var coordinates = {};
                            coordinates.latitude = vm.assetLat;
                            coordinates.longitude = vm.assetLong;

                            vm.fixedArray.push(coordinates);
                            mappedAssetTemp.assetCoordinates = vm.fixedArray;
                            addMappedAssets(mappedAssetTemp);
                            vm.isShowing = false;
                            initPlanningMap();
                            for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                                if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                                    createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                                } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                                    var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                                    drawPolylineForGeneratedLoc(vm.mappedAssetsList[i], assetCoordinateValues, assetsPlanningMap, "yellow");
                                }
                            }
                            var centerlatlng = {
                                lat: parseFloat(vm.assetLat),
                                lng: parseFloat(vm.assetLong)
                            };
                            assetsPlanningMap.setCenter(centerlatlng);
                        } else if(vm.parentAsset != null){
                            // setValues();
                            drawingManagerFixed();
                            drawMarker(assetsPlanningMap, JSON.stringify(coOrdinate));
                        } 
                        else {
                            drawingManagerFixed();
                            drawingManagerValue('click', assetsPlanningMap);
                        }
                    }
                } else {
                    initPlanningMap();
                    for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                        if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                            createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                        } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                            var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                            drawPolylineForGeneratedLoc(vm.mappedAssetsList[i], assetCoordinateValues, assetsPlanningMap, "yellow");
                        }
                    }
                }
                $("#create-asset").removeClass("in");
                $(".modal-backdrop").remove();
                $("#create-asset").hide();
            }
        }

        function initPlanningMap() {
            assetsPlanningMap = new google.maps.Map(document.getElementById('map_canvas_planning'), {
                center: {
                    lat: 5.431587,
                    lng: 101.128162
                },
                zoom: 13,
                fullscreenControl: true
            });
            if (vm.mappedAssetsList.length > 0) {
                assetsPlanningMap.setCenter(new google.maps.LatLng(vm.mappedAssetsList[0].assetCoordinates[0].latitude, vm.mappedAssetsList[0].assetCoordinates[0].longitude));
            }
        }

		function getPointsRoute(assetA, assetB, map, dist) {
            	var pointA = new google.maps.LatLng(assetA.assetCoordinates[0].latitude,assetA.assetCoordinates[0].longitude);
            	var pointB = new google.maps.LatLng(assetB.assetCoordinates[0].latitude,assetB.assetCoordinates[0].longitude);


			var directionsService = new google.maps.DirectionsService;
            
			directionsService.route({
                origin: pointA,
                destination: pointB,
                travelMode: google.maps.TravelMode.DRIVING
            }, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
					var route = response.routes[0];
	                var az = 0;
	                for (var i = 0; i < route.legs.length; ++i) {
	                    if (route.legs[i].distance) {
	                        az += route.legs[i].distance.value;
	                    }
	                }
	                dist = Math.max(dist, Math.round(az / 100));
	                var markerPoints = gMilestone(route, dist);
					var polylinePathPoints = gMilestone(route, 100);
					var responsePaths = response.routes;
                    vm.spreadArray = [];
                    var overViewPath = route.overview_path;
                    polylinePathPoints.forEach(function(value) {
                        var coordinates = {};
                        coordinates.latitude = value.lat();
                        coordinates.longitude = value.lng();
                        vm.spreadArray.push(coordinates);
                    });
					var newLinkAsset = {};
					newLinkAsset.name = assetA.name + "-"+ assetB.name;
					newLinkAsset.description = newLinkAsset.name;
					newLinkAsset.assetCoordinates = vm.spreadArray;
					newLinkAsset.assetType = vm.linkAssetType.assetType;
					var fiberDistance = 0.0;
                    var spreadCoordinates = newLinkAsset.assetCoordinates;
                    if (spreadCoordinates.length > 0) {
                        for (var index in spreadCoordinates) {
                            if (index > 0) {
                                fiberDistance += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
                            }
                        }
                    }
	                fiberDistance = fiberDistance.toFixed(2);
                    var spreadAV = vm.linkAssetType.assetTypeAttributeValues;
                    for (var spreadAssetIndex = 0; spreadAssetIndex < spreadAV.length; spreadAssetIndex++) {
                        if (spreadAV[spreadAssetIndex].assetTypeAttribute.name === "Fiber Distance (km)") {
                            spreadAV[spreadAssetIndex].attributeValue = fiberDistance;
                        }
                    }
                    newLinkAsset.assetTypeAttributeValues = spreadAV;
					newLinkAsset.isChecked = true;
					vm.tempLinkWithPits.push(newLinkAsset);
					//drawPolylineForSelectedPath(assetA.name + "-"+ assetB.name, vm.fixedArray, map, 0);
					markerPoints.forEach(function(point, index){
						var newManholeAsset = {};
						newManholeAsset.name = assetA.name + "-"+ assetB.name + " Pit"+ (index+1);
						newManholeAsset.description = newManholeAsset.name;
						newManholeAsset.assetType = vm.manholeAssetType.assetType;
						newManholeAsset.assetTypeAttributeValues = vm.manholeAssetType.assetTypeAttributeValues;
						vm.fixedArray = [];
						var coordinates = {};
                        coordinates.latitude = point.lat();
                        coordinates.longitude = point.lng();
                        vm.fixedArray.push(coordinates);
						newManholeAsset.assetCoordinates = vm.fixedArray;
						newManholeAsset.isChecked = true;
						vm.tempLinkWithPits.push(newManholeAsset);
					});
//					map.clear();
					for (var i = 0; i < vm.tempLinkWithPits.length; i++) {
                        if (vm.tempLinkWithPits[i].assetType.layout == 'FIXED') {
                            createMarkerForSelectedAsset(vm.tempLinkWithPits[i], map);
                        } else if (vm.tempLinkWithPits[i].assetType.layout == 'SPREAD') {
                            var assetCoordinateValues = vm.tempLinkWithPits[i].assetCoordinates;
                            drawPolylineForGeneratedLoc(vm.tempLinkWithPits[i], assetCoordinateValues, map, "yellow");
                        }
                    }
                } else {
                    window.alert('Directions request failed due to ' + status);
                }
            });
        }

		function saveLinkPits(){
			vm.tempLinkWithPits.forEach(function(asset){
				vm.mappedAssetsList.push(asset);
			});
			hideConnectMultipleAssetsModal();
		}
		function gMilestone(route, dist) {
			var path = route.overview_path;
			var geo = google.maps.geometry.spherical;
			var point = path[0];
			var distance = 0;
			var overflow = null;
			var pos = null;
			var leg = null;
			var pointsPosition = [];
			var d1 = 0;
			
		    for (var p = 1; p < path.length; ++p) {
		        leg = Math.round(geo.computeDistanceBetween(point, path[p]));
		        d1 = distance + 0
		        distance += leg;
		        overflow = dist - (d1 % dist);
		
		        if (distance >= dist && leg >= overflow) {
		            if (overflow && leg >= overflow) {
		                pos = geo.computeOffset(point, overflow, geo.computeHeading(point, path[p]));
						pointsPosition.push(pos);
		                distance -= dist;
		            }
		
		            while (distance >= dist) {
		                pos = geo.computeOffset(point, dist + overflow, geo.computeHeading(point, path[p]));
						pointsPosition.push(pos);
		                distance -= dist;
		            }
		        }
		        point = path[p]
		    }
		    return pointsPosition;
		}

        function createMarkerForSelectedAsset(asset, map) {
            if (asset.assetCoordinates.length > 0) {
                var myLatLng = ({ lat: parseFloat(asset.assetCoordinates[0].latitude), lng: parseFloat(asset.assetCoordinates[0].longitude) });
				if(asset.assetType.name == "Manhole"){
					var iconSize = 4;
					var marker = new google.maps.Marker({
	                    position: myLatLng,
	                    map: map,
						icon: {
	                            scaledSize: new google.maps.Size(
	                                iconSize, iconSize),
	                            url: 'content/images/green_circle.png',
	                            anchor: new google.maps.Point(
	                                iconSize / 2, iconSize / 2),
	
	                        }
	                });
				}else{
	                var marker = new google.maps.Marker({
	                    position: myLatLng,
	                    map: map,
	                    icon: {
	                        url: 'content/images/yellow.png'
	                    },
	                    label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: asset.name, backgroundcolor: "#ff9800" },
	                });
				}
            }
        }

		function createMarkerForSiteAsset(asset, map) {
            if (asset.assetCoordinates.length > 0) {
                var myLatLng = ({ lat: parseFloat(asset.assetCoordinates[0].latitude), lng: parseFloat(asset.assetCoordinates[0].longitude) });
				if(asset.assetType.name == "Manhole"){
					var iconSize = 4;
					var marker = new google.maps.Marker({
	                    position: myLatLng,
	                    map: map,
						icon: {
                                scaledSize: new google.maps.Size(
                                    iconSize, iconSize),
                                url: 'content/images/green_circle.png',
                                anchor: new google.maps.Point(
                                    iconSize / 2, iconSize / 2),

                            }
	                });
				}else{
	                var marker = new google.maps.Marker({
	                    position: myLatLng,
	                    map: map,
	                    icon: 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
	                    label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: asset.name, backgroundcolor: "#ff9800" },
	                });
				}
            }
        }

        function drawPolylineForGeneratedLoc(asset, latlngsToDraw, map, color) {
            var spreadAssetCoordinates = [];

            latlngsToDraw.forEach(function(value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude
                });
            });

            if (spreadAssetCoordinates.length > 0) {
                var ColorValue = '#ff0000';
                if (color == "red") {
                    ColorValue = '#ff0000';
                } else if (color == "black") {
                    ColorValue = '#000000';
                } else if (color == "yellow") {
                    ColorValue = '#F4A500';
                } else {
                    ColorValue = '#208000';
                }
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: ColorValue,
                    fillOpacity: 0.3,
                    editable: false
                });

                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                polylinepath.setMap(map);

                google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    var infoStr = "";
                    var otdr = "";
                    var infoWindowContent = "";
                    var assetTypeAttributeValuesList = asset.assetTypeAttributeValues;
                    assetTypeAttributeValuesList.forEach(function (attrValues) {
                        if(attrValues.attributeValue != undefined) {
                            vm.fiberLength = attrValues.attributeValue;
                        }
                        var home = attrValues;
                        var value = null;
                        if (home.attributeValue != undefined) {
                            value = home.attributeValue;
                        } else {
                            value = home.assetTypeAttribute.defaultValue;
                        }
                        infoStr += '<br><b>' +
                            home.assetTypeAttribute.name +
                            '</b> : ' +
                            value;
                        otdr = home;

                        infoWindowContent = '<div class="info_content">' +
                        '<b>' +
                        'Name' +
                            '</b> : ' +
                            asset.name +
                            '<br><b>' +
                            'Fiber Distance (km)' +
                            '</b> : ' +
                            vm.fiberLength +
                        '<hr></div><div>' + infoStr + '</div>';
                    })

                    var infoWindow2 = new google.maps.InfoWindow({
                        content: infoWindowContent,
                    });
                    infoWindow2.open(map);
                    infoWindow2.setPosition(event.latLng);
                    openedInfoWindow = infoWindow2;
                });
            }
        }

        function drawingManagerSpread() {
            vm.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYLINE,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['polyline']
                },
                polylineOptions: {
                    fillColor: '#ffff00',
                    fillOpacity: 1,
                    strokeWeight: 5,
                    clickable: true,

                    editable: true,
                    zIndex: 1,
                    geodesic: true
                }
            });
        }

        function drawingManagerFixed() {
            vm.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.MARKER,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['marker']
                }
            });
        }

        function drawingManagerValue(option, map, isEdit) {
            if (option == 'polylinecomplete') {
                vm.isShowing = false;
                google.maps.event.addListener(vm.drawingManager, option,
                    function(event) {
                        google.maps.event.addListener(event.getPath(), 'insert_at', function() {
                            setGoefenceCoordinate(event, isEdit);
                        });
                        google.maps.event.addListener(event.getPath(), 'set_at', function(path) {
                            setGoefenceCoordinate(event, isEdit);
                        });
                        vm.drawingManager.setOptions({
                            drawingControl: false
                        });
                        vm.drawingManager.setMap(null);
                        setGoefenceCoordinate(event, isEdit);
						if (mappedAssetTemp.assetType.layout == "SPREAD") {
							var fiberDistance = 0.0;
		                    var spreadCoordinates = mappedAssetTemp.assetCoordinates;
		                    if (spreadCoordinates.length > 0) {
		                        for (var index in spreadCoordinates) {
		                            if (index > 0) {
		                                fiberDistance += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
		                            }
		                        }
		                    }
			                fiberDistance = fiberDistance.toFixed(2);
		                    var spreadAV = mappedAssetTemp.assetTypeAttributeValues;
		                    for (var spreadAssetIndex = 0; spreadAssetIndex < spreadAV.length; spreadAssetIndex++) {
		                        if (spreadAV[spreadAssetIndex].assetTypeAttribute.name === "Fiber Distance (km)") {
		                            spreadAV[spreadAssetIndex].attributeValue = fiberDistance;
		                        }
		                    }
		                    mappedAssetTemp.assetTypeAttributeValues = spreadAV;
		                }
                        addMappedAssets(mappedAssetTemp);
                    });
                vm.drawingManager.setMap(map);
            } else if (option == 'click') {
                vm.isShowing = false;
                marker = new google.maps.Marker({
                    position: location,
                    map: map
                });

                google.maps.event.addListener(map, 'click', function(e) {
                    placeMarker(e.latLng, map);
                    vm.fixedArray = [];
                    var coordinates = {};
                    coordinates.latitude = e.latLng.lat();
                    coordinates.longitude = e.latLng.lng();
                    vm.fixedArray.push(coordinates);
                    mappedAssetTemp.assetCoordinates = vm.fixedArray;
                    addMappedAssets(mappedAssetTemp);
                });
            }
        }

        function placeMarker(location, map) {
            if (marker) {
                if (location.lat != null && location.lng != null) {
                    marker.setPosition(location);
                }
            } else {
                marker = new google.maps.Marker({
                    position: location,
                    map: map
                });
            }
        }

        function addMappedAssets(newAsset) {
            if (!isAssetAvailable(newAsset.name)) {
                newAsset.isChecked = true;
                vm.mappedAssetsList.push(newAsset);
            }
			// initPlanningMap();
            // for (var i = 0; i < vm.mappedAssetsList.length; i++) {
            //     if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
            //         createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
            //     } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
            //         var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
            //         drawPolylineForGeneratedLoc(vm.mappedAssetsList[i],assetCoordinateValues, assetsPlanningMap, "yellow");
            //     }
            // }
        }

        function isAssetAvailable(assetName) {
            var isAssetAvailable = false;

            vm.mappedAssetsList.forEach(function(assetVal) {
                if (assetVal.name === assetName) {
                    isAssetAvailable = true;
                }
            });
            return isAssetAvailable;
        }

        function selectedCaseTypeAttributes() {
            if (vm.caseType) {
                vm.trCase.caseType = vm.caseType;
                if (entity_old.caseType && vm.caseType.id === entity_old.caseType.id) {
                    vm.trCase.caseTypeAttributeValues = [];
                    vm.trCase.caseTypeAttributeValues = entity_old.caseTypeAttributeValues;
                } else {
                    vm.trCase.caseTypeAttributeValues = [];
                    $.each(vm.caseType.caseTypeAttribute, function(key, value) {
                        vm.trCase.caseTypeAttributeValues.push({
                            caseTypeAttribute: vm.caseType.caseTypeAttribute[key]
                        });
                    });
                }
            }
        }

        function deleteAssetModal(name) {
            $('#delete-asset').show();
            deleteAssetName = name;
        }

        function confirmDeleteAsset() {
            var assetArray = vm.mappedAssetsList;
            for (var i = assetArray.length - 1; i >= 0; --i) {
                if (assetArray[i].name == deleteAssetName) {
					vm.deletedAssetsList.push(assetArray[i]);
                    assetArray.splice(i, 1);
                }
            }
            initPlanningMap();
            for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                    createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                    var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                    drawPolylineForGeneratedLoc(vm.mappedAssetsList[i],assetCoordinateValues, assetsPlanningMap, "yellow");
                }
            }
            vm.mappedAssetsList = [];
            assetArray.forEach(function(asset) {
                vm.mappedAssetsList.push(asset);
            });
            $("#delete-asset").hide();
        }

        function cleardeleteAsset() {
            $("#delete-asset").removeClass("in");
            $(".modal-backdrop").remove();
            $("#delete-asset").hide();
        }

        function setGoefenceCoordinate(event, isEdit) {
            vm.assetCoordinateValues = [];
            var tempAsset = {};
            tempAsset.assetCoordinates = [];
            var radius = event.getPath();
            for (var i = 0; i < radius.getLength(); i++) {
                var coordinates = {};
                coordinates.latitude = radius.getAt(i).lat();
                coordinates.longitude = radius.getAt(i).lng();
                tempAsset.assetCoordinates.push(coordinates);
            }
            if (isEdit) {
                vm.asset.assetCoordinates = tempAsset.assetCoordinates;
            } else {
                mappedAssetTemp.assetCoordinates = tempAsset.assetCoordinates;
            }
        }

        function saveEditAsset() {
            //vm.tempAssetArray.push(vm.asset);
            vm.mappedAssetsList = [];
            vm.tempAssetArray.forEach(function(assetVal) {
                vm.mappedAssetsList.push(assetVal);
            });
            vm.asset = [];
            vm.tempAssetArray = [];
            hideUserEditModal();
            initPlanningMap();
            for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                    createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                    var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                    drawPolylineForGeneratedLoc(vm.mappedAssetsList[i],assetCoordinateValues, assetsPlanningMap, "yellow");
                }
            }
        };

        function hideUserEditModal() {
            $("#userPathModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#userPathModal").hide();
        }

        function editAsset(asset) {
            vm.tempAssetArray = [];

            vm.mappedAssetsList.forEach(function(assetVal) {
                vm.tempAssetArray.push(assetVal);
            });
            for (var i = 0; i >= vm.tempAssetArray.length; --i) {
                if (vm.tempAssetArray[i].name == asset.name) {
                    vm.tempAssetArray.splice(i, 1);
                }
            }

            vm.asset = asset;
            var maplatlng = {
                lat: parseFloat(asset.assetCoordinates[0].latitude),
                lng: parseFloat(asset.assetCoordinates[0].longitude)
            };
            editAssetMap = new google.maps.Map(document.getElementById('map_canvas_edit_asset'), {
                center: maplatlng,
                zoom: 13,
                fullscreenControl: true
            });


            if (asset.assetType.layout == 'SPREAD') {
                drawingManagerSpread();
                drawingManagerValue('polylinecomplete', editAssetMap);
            } else if (asset.assetType.layout == 'FIXED') {
                drawingManagerFixed();
                drawingManagerValue('click', editAssetMap);
            }
            if (asset.name && asset.name != null) {
                if (asset.assetType.layout == 'SPREAD') {
                    drawPolyline(function(polyline) {
                        polyline.setMap(editAssetMap);
                        google.maps.event.addListener(polyline.getPath(),
                            'insert_at',
                            function() {
                                setGoefenceCoordinate(polyline, true);
                            });
                        google.maps.event.addListener(polyline.getPath(),
                            'set_at',
                            function(path) {
                                setGoefenceCoordinate(polyline, true);
                            });

                        vm.drawingManager.setOptions({
                            drawingControl: false
                        });
                        vm.drawingManager.setMap(null);

                    })
                } else if (asset.assetType.layout == 'FIXED') {
                    setValues();
                    drawingManagerFixed();
                    drawMarker(editAssetMap, vm.coOrdinate);
                }

                function drawPolyline(callback) {
                    setValues();
                    var centerlatlng = {};
                    if (vm.coOrdinates.length > 1) {
                        vm.coOrdinates.forEach(function(value) {
                            centerlatlng = {
                                lat: value.lat,
                                lng: value.lng
                            };
                        });

                        callback(new google.maps.Polyline({
                            path: vm.coOrdinates,
                            geodesic: true,
                            strokeColor: vm.asset.assetType.colorcode,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: vm.asset.assetType.colorcode,
                            fillOpacity: 0.3,
                            editable: true
                        }));
                        editAssetMap.setCenter(centerlatlng);
                    }
                }

            }
            var ele = document.getElementById("userPathModal");
            $(ele).modal('show');
            $("#userPathModal").show();


            $("#location-map-edit").css("width", "100%");
            $("#map_canvas_edit_asset").css("width", "100%");
        }

        function hideConnectAssetsModal() {
            $("#connectAssetsModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#connectAssetsModal").hide();
        }

        function hideConnectSitesModal () {
            $("#connectAssetsModal").hide();
        }

        function connectSelectedAssets() {
            var ele = document.getElementById("connectAssetsModal");
            $(ele).modal('show');
            $("#connectAssetsModal").show();

            $("#location-map-edit").css("width", "100%");
            $("#map_canvas_connect_assets").css("width", "100%");
        }

		function hideConnectMultipleAssetsModal() {
            $("#connectMultipleAssetsModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#connectMultipleAssetsModal").hide();
			vm.tempLinkWithPits = [];
			initPlanningMap();
            for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
                    createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
                } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
                    var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
                    drawPolylineForGeneratedLoc(vm.mappedAssetsList[i],assetCoordinateValues, assetsPlanningMap, "yellow");
                }
            }
        }

        function connectMultipleAssets() {
			vm.siteAssets = [];
			vm.tempLinkWithPits = [];
					
            var ele = document.getElementById("connectMultipleAssetsModal");
            $(ele).modal('show');
            $("#connectMultipleAssetsModal").show();
            $("#location-map-edit").css("width", "100%");
            $("#map_canvas_connect_multiple_assets").css("width", "100%");
			var multipleSiteMap = new google.maps.Map(document.getElementById('map_canvas_connect_multiple_assets'), {
                    center: new google.maps.LatLng(vm.mappedAssetsList[0].assetCoordinates[0].latitude, vm.mappedAssetsList[0].assetCoordinates[0].longitude),
                    zoom: 12,
                    fullscreenControl: true
                });
			for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                if (vm.mappedAssetsList[i].assetType.layout == 'FIXED' && vm.mappedAssetsList[i].assetType.name == "Site" && vm.mappedAssetsList[i].id == null) {
					vm.siteAssets.push(vm.mappedAssetsList[i]);
                    createMarkerForSiteAsset(vm.mappedAssetsList[i], multipleSiteMap);
                }
            }
			
			vm.siteAssets.sort(function(assetA, assetB) {
                var assetATime = assetA.createDate,
                    assetBTime = assetB.createDate
                if (assetATime < assetBTime) // sort string ascending
                    return -1
                if (assetATime > assetBTime)
                    return 1
                return 0 // default return value (no sorting)
            });
			for(var siteIndex = 1; siteIndex < vm.siteAssets.length; siteIndex++){
				getPointsRoute(vm.siteAssets[siteIndex-1], vm.siteAssets[siteIndex], multipleSiteMap, 200);
			}
        }

		function recalculate(){
            var totalCost = 0;
            var totalPowerBudget = 0;
			var totalFiberDistance = 0.0;
            for (var assetIndex = 0; assetIndex < vm.mappedAssetsList.length; assetIndex++) {
                var isSpread = false;
                var fiberDistance = 0.0;

				if (vm.mappedAssetsList[assetIndex].assetType.layout == "SPREAD") {
					isSpread = true;
                    var spreadAV = vm.mappedAssetsList[assetIndex].assetTypeAttributeValues;
                    for (var spreadAssetIndex = 0; spreadAssetIndex < spreadAV.length; spreadAssetIndex++) {
                        if (spreadAV[spreadAssetIndex].assetTypeAttribute.name === "Fiber Distance (km)") {
                           fiberDistance = parseFloat("0"+spreadAV[spreadAssetIndex].attributeValue);
                        }
                    }
                }

				for(var attrIndex = 0;attrIndex < vm.mappedAssetsList[assetIndex].assetTypeAttributeValues.length;attrIndex++) {
					var item = vm.mappedAssetsList[assetIndex].assetTypeAttributeValues[attrIndex];
                    var value = parseFloat(item.assetTypeAttribute.defaultValue);

                    if (item.assetTypeAttribute.name == "Cost") {
                        var tempCost = parseFloat(value);
                        if (isSpread) {
                            tempCost = parseFloat(value) * fiberDistance;
                        }
                        if (tempCost > 0) {
                            totalCost += tempCost
                        }
						item.attributeValue = value;
                    }
                    if (item.assetTypeAttribute.name == "Power Budget") {
                        var tempPowerBudget = parseFloat(value);
                        if (isSpread) {
                            tempPowerBudget = parseFloat(value) * fiberDistance;
                        }
                        if (tempPowerBudget > 0) {
                            totalPowerBudget += tempPowerBudget;
                        }
						item.attributeValue = value;
                    }
					vm.mappedAssetsList[assetIndex].assetTypeAttributeValues[attrIndex] = item;
				}
				totalFiberDistance += fiberDistance;
            }

            var caseAV = vm.trCase.caseTypeAttributeValues;
            for (var caseIndex = 0; caseIndex < caseAV.length; caseIndex++) {
                if (caseAV[caseIndex].caseTypeAttribute.name === "Cost") {
                    caseAV[caseIndex].attributeValue = totalCost.toFixed(2);;
                }
                if (caseAV[caseIndex].caseTypeAttribute.name === "Power Budget") {
                    caseAV[caseIndex].attributeValue = totalPowerBudget.toFixed(2);;
                }
				if (caseAV[caseIndex].caseTypeAttribute.name === "Fiber Distance") {
                    caseAV[caseIndex].attributeValue = totalFiberDistance.toFixed(2);;
                }
            }
            vm.trCase.caseTypeAttributeValues = caseAV;
        }

        function connectAssets() {
            if (vm.isSiteASelected == false && vm.isSiteBSelected == false) {
                alert("Please Select Site A");
            } else if (vm.isSiteASelected == true && vm.isSiteBSelected == false) {
                alert("Please Select Site B");
            } else {
                var siteALatLng = new google.maps.LatLng(vm.selectedSiteA.assetCoordinates[0].latitude, vm.selectedSiteA.assetCoordinates[0].longitude);
                var siteBLatLng = new google.maps.LatLng(vm.selectedSiteB.assetCoordinates[0].latitude, vm.selectedSiteB.assetCoordinates[0].longitude);
                var map = new google.maps.Map(document.getElementById('map_canvas_connect_assets'), {
                    center: siteALatLng,
                    zoom: 16,
                    fullscreenControl: true
                });
                createMarker(map, vm.selectedSiteA.name, siteALatLng);
                createMarker(map, vm.selectedSiteB.name, siteBLatLng);

                getShortestRoute(vm.selectedSiteA, vm.selectedSiteB, map);
            }
        }

		function setValues() {
            vm.coOrdinates = [];
            for (var i = 0; i < vm.asset.assetCoordinates.length; i++) {
                vm.coOrdinates.push({
                    lat: vm.asset.assetCoordinates[i].latitude,
                    lng: vm.asset.assetCoordinates[i].longitude
                });
                if (i == vm.asset.assetCoordinates.length - 1) {
                    vm.coOrdinate = JSON.stringify(vm.coOrdinates);
                }
            }
        }

        $("#fromAssetsView").change(function() {
            $("#toAssetsView")[0].selectedIndex = 0;
            var result = $("#fromAssetsView").val();
            if (result != null) {
                for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                    if (vm.mappedAssetsList[i].name == result) {
                        vm.selectedSiteA = vm.mappedAssetsList[i];
                    }
                }
                vm.isSiteASelected = true;
            }
        });

        $("#toAssetsView").change(function() {
            if (vm.isSiteASelected == true) {
                var result = $("#toAssetsView").val();
                if (result != null) {
                    for (var i = 0; i < vm.mappedAssetsList.length; i++) {
                        if (vm.mappedAssetsList[i].name == result) {
                            vm.selectedSiteB = vm.mappedAssetsList[i];
                        }
                    }
                    vm.isSiteBSelected = true;
                }
            } else {
                $("#toAssetsView")[0].selectedIndex = 0;
                alert("Please Select Site A");
            }

        });

        function createMarker(map, assetName, myLatLng) {
            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                icon: {
                    scaledSize: new google.maps.Size(
                        24, 24),
                    origin: new google.maps.Point(0, 0),
                    url: 'content/images/yellow.png',
                    anchor: new google.maps.Point(
                        16, 16),

                },
                label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: assetName, backgroundcolor: "#ff9800" },
            });

        }

        function drawMarker(map, response) {
            $.each(JSON.parse(response), function (index, value) {
                centerlatlng = {
                    lat: value.lat,
                    lng: value.lng
                };

                if (marker) {
                    marker.setMap(null);
                }
                marker = new google.maps.Marker({
                    position: centerlatlng,
                    icon: {
                        size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(16, 16)
                    },
                });

                marker.setMap(map);
                vm.fixedArray = [];
                var coordinates = {};
                coordinates.latitude = centerlatlng.lat;
                coordinates.longitude =centerlatlng.lng;
                vm.fixedArray.push(coordinates);
                mappedAssetTemp.assetCoordinates = vm.fixedArray;
                addMappedAssets(mappedAssetTemp);
            });
        }

        function getShortestRoute(SiteA, SiteB, map) {
            var pointA = new google.maps.LatLng(SiteA.assetCoordinates[0].latitude, SiteA.assetCoordinates[0].longitude);
            var pointB = new google.maps.LatLng(SiteB.assetCoordinates[0].latitude, SiteB.assetCoordinates[0].longitude);

            var newAsset = {
                name: '',
                assetCoordinates: []
            };
            newAsset.name = SiteA.name + "-" + SiteB.name;

            var directionsService = new google.maps.DirectionsService;

            directionsService.route({
                origin: pointA,
                destination: pointB,
                avoidTolls: true,
                avoidHighways: false,
                provideRouteAlternatives: true,
                travelMode: google.maps.TravelMode.DRIVING
            }, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    var responsePaths = response.routes;
                    responsePaths.forEach(function(pathVal, index) {
                        if (index < 3) {
                            vm.fixedArray = [];
							var route = pathVal;
			                var az = 0;
			                for (var i = 0; i < route.legs.length; ++i) {
			                    if (route.legs[i].distance) {
			                        az += route.legs[i].distance.value;
			                    }
			                }
							var polylinePathPoints = gMilestone(route, 100);
//                            var overViewPath = pathVal.overview_path;
                            polylinePathPoints.forEach(function(value) {
                                var coordinates = {};
                                coordinates.lat = value.lat();
                                coordinates.lng = value.lng();
                                vm.fixedArray.push(coordinates);
                            });
                            drawPolylineForSelectedPath(SiteA.name + "-" + SiteB.name, vm.fixedArray, map, index);
                        }
                    });
                } else {
                    window.alert('Directions request failed due to ' + status);
                }
            });
            return newAsset;
        }

        function drawPolylineForSelectedPath(assetName, spreadAssetCoordinates, map, index) {
            if (spreadAssetCoordinates.length > 0) {
                map.setZoom(14);

                var ColorValue = '#ff0000';
                if (index == 0) {
                    ColorValue = '#ff0000';
                } else if (index == 1) {
                    ColorValue = '#000000';
                } else {
                    ColorValue = '#208000';
                }

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: ColorValue,
                    fillOpacity: 0.3,
                    editable: false
                });

                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                polylinepath.setMap(map);

                google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    var pointContent = document.createElement('div'),
                        button;

                    pointContent.innerHTML = '<div class="infoWindowhead">Point ' + (index + 1) +
                        '</div>';
                    button = pointContent.appendChild(document.createElement('input'));
                    button.type = 'button';
                    button.value = 'Click to select';
                    button.className = "button";

                    google.maps.event.addDomListener(button, 'click', function() {
                        var newAsset = {};
                        newAsset.name = assetName;
                        newAsset.assetCoordinates = [];
                        spreadAssetCoordinates.forEach(function(assetCoor) {
                            newAsset.assetCoordinates.push({ latitude: assetCoor.lat, longitude: assetCoor.lng });
                        });

						AssetType.query({}, function(data) {
                            data.forEach(function(item) {
                                if (item.name == "Link") {
									vm.isShowing = false;
                                    newAsset.assetType = item;
                                    newAsset.assetTypeAttributeValues = getConsolidatedAssetTypeAttributeValue(newAsset.assetType);
//	                                $.each(newAsset.assetType.assetTypeAttributes,function(key, value) {
//	                                    newAsset.assetTypeAttributeValues.push({
//	                                            assetTypeAttribute: newAsset.assetType.assetTypeAttributes[key]
//	                                        });
//	                                });
									if (newAsset.assetType.layout == "SPREAD") {
										var fiberDistance = 0.0;
					                    var spreadCoordinates = newAsset.assetCoordinates;
					                    if (spreadCoordinates.length > 0) {
					                        for (var index in spreadCoordinates) {
					                            if (index > 0) {
					                                fiberDistance += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
					                            }
					                        }
					                    }
						                fiberDistance = fiberDistance.toFixed(2);
					                    var spreadAV = newAsset.assetTypeAttributeValues;
					                    for (var spreadAssetIndex = 0; spreadAssetIndex < spreadAV.length; spreadAssetIndex++) {
					                        if (spreadAV[spreadAssetIndex].assetTypeAttribute.name === "Fiber Distance (km)") {
					                            spreadAV[spreadAssetIndex].attributeValue = fiberDistance;
					                        }
					                    }
					                    newAsset.assetTypeAttributeValues = spreadAV;
					                }
	                                newAsset.isChecked = true;
	                                vm.mappedAssetsList.push(newAsset);
									initPlanningMap();
		                            for (var i = 0; i < vm.mappedAssetsList.length; i++) {
		                                if (vm.mappedAssetsList[i].assetType.layout == 'FIXED') {
		                                    createMarkerForSelectedAsset(vm.mappedAssetsList[i], assetsPlanningMap);
		                                } else if (vm.mappedAssetsList[i].assetType.layout == 'SPREAD') {
		                                    var assetCoordinateValues = vm.mappedAssetsList[i].assetCoordinates;
		                                    drawPolylineForGeneratedLoc(vm.mappedAssetsList[i], assetCoordinateValues, assetsPlanningMap, "yellow");
		                                }
		                            }
                                }
                            });
                        });
                        hideConnectAssetsModal();
                    })

                    var infoWindow2 = new google.maps.InfoWindow({
                        content: pointContent,
                    });
                    infoWindow2.open(map);
                    infoWindow2.setPosition(event.latLng);
                    openedInfoWindow = infoWindow2;
                });
            }
        }

        function save() {
            vm.isShowing = false;
            vm.trCase.caseAssets = [];
			vm.trCase.deletedAssets = [];
			
            vm.mappedAssetsList.forEach(function(caseAssetVal) {
                if (caseAssetVal.isChecked) {
                    vm.trCase.caseAssets.push(caseAssetVal);
                }
            });

			vm.deletedAssetsList.forEach(function(asset){
				vm.trCase.deletedAssets.push(asset);
			});
			
            if (vm.trCase.caseAssets.length > 0) {
                vm.isShowing = true;
                vm.isSaving = true;
                var assignedToUser = $("#field_assignedToUser").val();
                vm.trCase.assignedTo = usersMap[assignedToUser];
                angular.forEach(vm.assetList, function(value, key) {
                    if (vm.trCase.asset.name === value.name) {
                        vm.trCase.asset = value;
                    }
                });

                if (vm.trCase.id !== null) {
                    TrCase.update(vm.trCase, onSaveSuccess, onSaveError);
                } else {
                    TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
                }
            } else {
                alert("No assets are mapped to this work flow");
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('trakeyeApp:trCaseUpdate', result);
            $state.go('tr-case');
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function selectedGroup(value){
            var input = document.getElementById("field_assignedToUser");
            input.value = '';
            vm.hideUser = false;
        }
        function filterActivatedUser() {
            if (vm.trCase.assignedToUser != null && vm.trCase.assignedToUser != "" && vm.trCase.assignedToUser != "." && !angular.isUndefined(vm.trCase.assignedToUser)) {
                $('input[name="assignedToUser"]').keyup(function () {
                    vm.user = $(this).val();
                });
                    ActivatedUserSearch.query({
                        // userId: vm.trCase.assignedToUser,
                        userId: vm.trCase.groups[0].id,
                        user: vm.user
                    }, onSuccess, onError);
                
                function onSuccess(data, headers) {
                    data.forEach(function (users) {
                        vm.assignedUsers.push(users.login);
                    });
                    vm.activatedusers = data;

                    angular.forEach(vm.activatedusers, function (value, key) {
                        usersMap[value.login] = value.id;

                    });
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            
            }
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return (dist);
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }
        $(document).on('change', 'input', function () {
            var options = $('datalist')[0].options;
            for (var i = 0; i < options.length; i++) {
                if (options[i].value == $(this).val()) {
                    if (vm.assignedUsers.includes($(this).val())) {
                        vm.validUser = false;
                    } else {
                        vm.validUser = true;
                    }
                    break;
                }
            }
        });
    }
})();