(function() {
    'use strict';

    angular.module('trakeyeApp').controller('AssetController', AssetController);

    AssetController.$inject = ['$scope', '$state', '$interval', 'pagingParams', 'paginationConstants', 'Asset', 'AssetsUpdateGroup', 'AssetsBulkDelete', 'GroupAssets', 'AttrTypeVal', 'AssetGroup', 'ChildAssets', 'ParseLinks', 'Principal',
        'AlertService', 'AssetByID', 'GroupFixedAssets', 'GroupSpreadAssets', 'AssetSearch', 'UserGroupIDs', 'AssetSearchForMap', 'AssetGroupSearch', 'FilterAssetsForMap', 'AssetsForMap', 'ChildAssetsType', 'SiteAssetById', 'AssetChildren'
    ];

    function AssetController($scope, $state, $interval, pagingParams, paginationConstants, Asset, AssetsUpdateGroup, AssetsBulkDelete, GroupAssets, AttrTypeVal, AssetGroup, ChildAssets, ParseLinks, Principal,
        AlertService, AssetByID, GroupFixedAssets, GroupSpreadAssets, AssetSearch, UserGroupIDs, AssetSearchForMap, AssetGroupSearch, FilterAssetsForMap, AssetsForMap, ChildAssetsType, SiteAssetById, AssetChildren) {
        var vm = this;
        $scope.search = pagingParams.search;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.filterAssetSearch = filterAssetSearch;
        vm.filterAssetGroupSearch = filterAssetGroupSearch;
		vm.refreshPage = refreshPage;
        vm.fiberTubeData = [];
		vm.fiberSlotData = [];
		vm.fiberRibbonData = [];
        vm.fiberCoreData = [];
        vm.splitterCoreData = [];
        vm.selectedCable = null;
        vm.selectedTube = null;
        vm.selectedCore = null;
        vm.selectedSplitterCore = null;
        vm.splitterCore = null;
        vm.prevAssetCoordinates = [];
        $scope.tubeName = "";
        vm.asset = {};
        vm.assetFixedCurrentPage = 0;
        vm.assetFixedTotalPage = 0;
        vm.assetSpreadCurrentPage = 0;
        vm.assetSpreadTotalPage = 0;
        
        vm.generatedLat = 0.0;
        vm.generatedLng = 0.0;
        vm.parentName = "";
        vm.childName = "";
        vm.generatedAssetName;
        vm.mapAssets = null;
        vm.filterAssetsformap = filterAssetsformap;
        vm.selectAllAssets = selectAllAssets;
        vm.selectAssetEntity = selectAssetEntity;
        vm.updateAssetGroup = updateAssetGroup;
        vm.selectAsets = selectAsets;
        vm.searchAssets = searchAssets;
        vm.allAssets = null;
        vm.assets = [];
        $scope.tab = 5;
        var latlng;
        var locations = {};
        var userPaths = {};
        var assetFromToDistance = 0.0;
        var prevInfoWindow;
        $scope.isSet = function(tabNum) {
            return $scope.tab === tabNum;
        };
        vm.customAttributesMap = [];
        vm.multiassets = [];
        vm.fromAssetsView = [];
        vm.toAssetsView = [];
        vm.toAssetsMap = [];
        var openedInfoWindow = null;
        var assetsArray = [];
        var selctedAssets = [];
        var selectedAssetForIssue = [];
        var selectedFromAsset = 0;
        var selectedFromAssetMap = 0;
        var selectedToAssetMap = 0;
        var selectedToAsset = 0;
        var markers = [];
        var polylines = [];
        var isFromAssetSelected = false;
        var isToAssetSelected = false;
        var isFromAssetMapSelected = false;
        var parentAssetList = [];
        var savedAssetFromMapLoc = null;
        vm.assetByTypeList = ['Site', 'Link', 'NTTN Site', 'KML Link'];
        vm.assetByStatusList = ['PLANNING', 'DEPLOYMENT', 'ON AIR'];
        vm.assetByOwner = ['Robi'];
        vm.assetByType = [];
        vm.assetStatus = [];
        vm.assetOwner = [];
        var prevAssetByType = [];
        var prevAssetStatus = [];
        var prevAssetOwner = [];
        vm.EditAssetName = '';
        vm.selectEditAssetType = false;;

        vm.createAsset = createAsset;
        vm.navigateEditPage = navigateEditPage;
        vm.navigateToEdit0 = navigateToEdit0;
        vm.navigateToEdit1 = navigateToEdit1;
        vm.navigateToEdit2 = navigateToEdit2;
        vm.navigateToEdit3 = navigateToEdit3;
        vm.navigateToEdit4 = navigateToEdit4;
        vm.navigateToEdit5 = navigateToEdit5;
        vm.navigateToEdit6 = navigateToEdit6;
        vm.navigateToEdit7 = navigateToEdit7;
        vm.navigateToEdit8 = navigateToEdit8;
        vm.navigateToEdit9 = navigateToEdit9;
        vm.navigateToEdit10 = navigateToEdit10;
        vm.navigateToEdit11 = navigateToEdit11;

//        if ($scope.tab == 1) {
//            filterAssetSearch();
//        }
        vm.assetTotalPage = 0;
        vm.checkedAssets = [];
        vm.selectedAssets = [];
        vm.multgroup = [];
        var selctedGroup = [];
        vm.groupAssets = false;
        vm.showAssetActions = false;
        vm.totalAssetSelected = 0;
        // vm.parentAssets = [];

        vm.childTable = childTable;
        vm.subChildTable = subChildTable;
        vm.grandChildTable = grandChildTable;

        vm.filterParentAsset = filterParentAsset;

        vm.items = [{
            'name': 'Update Group Mapping'
        }, {
            'name': 'Delete Multiple Asset'
        }];
   
        if ($scope.tab == 5) {
            UserGroupIDs.getAll(function (response) {
                response.forEach(function (group) {
                    if (selctedGroup.indexOf(group.id) !== -1) {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: true });
                    } else {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: false });
                    }
                });
            });
            filterAssetGroupSearch();
        }

		function refreshPage(){
			filterGroupAssetsformap();
		}
        var loggedInUserId = 0;
        Principal.identity().then(function(identity) {
            if (identity) {
                loggedInUserId = identity.id;
            }
        })

        $scope.showSelectColor = false;
        vm.coreSize = 0;
        vm.coreColor = function(value) {
			vm.fiberCoreData = [];
            $scope.showSelectColor = true;
            $scope.tubeName = value.name;
            var tubeId = value.id;
            ChildAssets.query({
                parentId: tubeId
            }, onSuccess1);

            function onSuccess1(data, headers) {
                vm.fiberCoreData = [];
				data.forEach(function(asset) {
                    var assetAV = asset.assetTypeAttributeValues;
                    var fiber = { id: asset.id, name: asset.name, status: "free" };
                    assetAV.forEach(function(item, index) {
                        if (item.assetTypeAttribute.name == "Core Status") {
                            fiber.status = item.attributeValue;
                        }
                    });
                    vm.fiberCoreData.push(fiber);
                });
            }
        }

		vm.coreRibbon = function(value) {
			vm.fiberRibbonData = [];
			vm.fiberCoreData = [];
             ChildAssets.query({
                    parentId: value.id
                }, onSuccess);

                function onSuccess(data) {
					vm.fiberRibbonData = [];
                    var index = 1;
                    data.forEach(function(ribbonData) {
                        ribbonData.img = "Tube" + index;
                        index = index +1;
                        vm.fiberRibbonData.push(ribbonData);
                    });
                }

                var ele = document.getElementById("cablePathModal");
                $(ele).modal('show');
        }

        var updateAssets = $interval(function() {
            if ($scope.tab == 2 || $scope.tab == 3 || $scope.tab == 4) {
                filterAssetsformap();
            } else if ($scope.tab == 6) {
                filterGroupAssetsformap();
            } else if ($scope.tab == 5) {
                filterAssetGroupSearch();
            }
            // }, 1000000);
        }, 100000000);
        $(".select2").select2();


        $("#searchAssetsOnMap").change(function() {
            var assetSelected = JSON.parse($("#searchAssetsOnMap").val());
            AssetByID.get(assetSelected.id, function(assetVal) {
                var assetLatLng = ({
                    lat: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].latitude,
                    lng: assetSelected.assetCoordinates[assetSelected.assetCoordinates.length - 1].longitude
                });
                locations.map.setCenter(assetLatLng);
            })

        });
        $("#fromAssetMap").change(function() {
            selectedFromAssetMap = $("#fromAssetMap").val();
            var child = null;
            vm.toAssetsMap = [];
            var multiassets = vm.multiassets;
            multiassets.forEach(function(item, index) {
                if (selectedFromAssetMap == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            if (selectedFromAssetMap.length > 0) {
                for (var i = 0; i < assetsArray.length; i++) {
                    if (assetsArray[i].id == selectedFromAssetMap) {

                        selectedAssetForIssue = assetsArray[i];
                        var cableLengthFromBaseForSelectedAsset = 0.0;
                        var assetTypeAttributeValuesList = selectedAssetForIssue.assetTypeAttributeValues;
                        for (var index in assetTypeAttributeValuesList) {
                            if (assetTypeAttributeValuesList[index].name == "Cable length") {
                                cableLengthFromBaseForSelectedAsset = assetTypeAttributeValuesList[index].attributeValue;
                            }
                        }
                        $("#myText").html("Fiber length from POP : " + cableLengthFromBaseForSelectedAsset);
                        var lati = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length - 1].latitude;
                        var lngi = selectedAssetForIssue.assetCoordinates[selectedAssetForIssue.assetCoordinates.length - 1].longitude;
                        createMarkerForSelectedAsset(lati, lngi, selectedAssetForIssue.name);
                        isFromAssetMapSelected = true;
                        document.getElementById("distanceValue").value = "";
                    }
                }

                parent = child.ownedBy;
                if (parent.length > 0) {
                    vm.toAssetsMap.push(recursiveOwnedByAssetsMap(parent, multiassets));
                }
            } else {
                isFromAssetMapSelected = false;
                $("#myText").html("");
            }

        });

        $("#fromAssetsView").change(function() {
            var child = {};
            vm.toAssetsView = [];
            var multiassets = vm.multiassets;
            selectedFromAsset = $(this).val();
            multiassets.forEach(function(item, index) {
                if (selectedFromAsset == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            isFromAssetSelected = true;

            isToAssetSelected = false;
            $("#toAssetsView")[0].selectedIndex = 0;
            parent = child.ownedBy;
            if (parent.length > 0) {
                vm.toAssetsView.push(recursiveOwnedByAssets(parent, multiassets));
            }
        });

        function selectAssetEntity(asset) {
            if (asset.id != null) {
                if (asset.isChecked) {
                    vm.showAssetActions = true;
                    vm.selectedAssets.push(asset.id);
                } else {
                    const index = vm.selectedAssets.indexOf(asset.id);
                    if (index > -1) {
                        vm.selectedAssets.splice(index, 1);
                    }
                }
                if (vm.selectedAssets.length == 0) {
                    vm.groupAssets = false;
                    vm.showAssetActions = false;
                    filterAssetGroupSearch();
                } else {
                    vm.showAssetActions = true;
                }
            }
        };

        function selectAllAssets() {
            var toggleStatus = vm.isAllAssetSelected;
            if (toggleStatus) {
                vm.showAssetActions = true;
                vm.selectedAssets = [];
                vm.assets.forEach(function (asset) {
                    vm.selectedAssets.push(asset.id);
                });
            } else {
                vm.showAssetActions = false;
                vm.selectedAssets = [];
            }
            angular.forEach(vm.assets, function (item) { item.isChecked = toggleStatus; });
        }

        function updateAssetGroup(name) {
            vm.multgroup.forEach(function (group) {
                if (group.name == name) {
                    if (vm.selectedAssets.length > 0) {
                        var assetGroupArray = [];
                        vm.selectedAssets.forEach(function (assetId) {
                            assetGroupArray.push({ assetId: assetId, groupId: group.id });
                        });
                        AssetsUpdateGroup.update(assetGroupArray, onSuccess, onError);

                        function onSuccess() {
                            vm.groupAssets = false;
                            vm.showAssetActions = false;
                            vm.isAllAssetSelected = false;
                            filterAssetGroupSearch();
                        }
                        function onError() {
                        }
                    } else {
                        alert("Please select assets to update group");
                    }
                }
            });
        }

        function selectAsets(name) {
            if (name == 'Update Group Mapping') {
                vm.groupAssets = true;
            } else if (name == 'Delete Multiple Asset') {
                vm.groupAssets = false;
                if (vm.selectedAssets.length > 0) {
                    var assetGroupArray = [];
                    vm.selectedAssets.forEach(function (assetId) {
                        assetGroupArray.push({ id: assetId });
                    });
                    AssetsBulkDelete.save(assetGroupArray, onSuccess, onError);
                    function onSuccess() {
                        vm.groupAssets = false;
                        vm.showAssetActions = false;
                        vm.isAllAssetSelected = false;
                        filterAssetGroupSearch();
                        // $state.go('asset');
                    }
                    function onError(error) {
                        var msg = "Asset deletion failed, Asset has some dependent data";
                        if (error.status == 400) {
                            AlertService.error(msg);
                        } else {
                            AlertService.error(error.data.message);
                        }
                    }
                } else {
                    alert("Please select assets to delete list");
                }
            }
        }

        var recursiveOwnedByAssets = function(childVal, multiassets) {
            var child = childVal[0];
            multiassets.forEach(function(item, index) {
                if (child.id == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            var parentVal = child.ownedBy;

            if (parentVal != null) {
                if (parentVal.length > 0) {
                    vm.toAssetsView.push(recursiveOwnedByAssets(parentVal, multiassets));
                }
            }
            return child;
        };
        var recursiveOwnedByAssetsMap = function(childVal, multiassets) {
            var child = childVal[0];
            multiassets.forEach(function(item, index) {
                if (child.id == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            var parentVal = child.ownedBy;

            if (parentVal != null) {
                if (parentVal.length > 0) {
                    vm.toAssetsMap.push(recursiveOwnedByAssetsMap(parentVal, multiassets));
                }
            }
            return child;
        };

        $("#toAssetsView").change(function() {
            var toAssetId = $(this).val();
            var toAssetAV = [];
            var child = {};
            var childAV = [];
            var fromAssetDistance = 0.0;
            var toAssetDistance = 0.0;
            var multiassets = vm.multiassets;
            latlngs = [];
            redLatLngs = [];
            var isValidLength = true;
            selectedToAsset = $(this).val();
            if (selectedToAsset != "" || selectedToAsset != null) {
                isToAssetSelected = true;

                for (var i = 0; i < multiassets.length; i++) {
                    if (multiassets[i].id == selectedFromAsset) {
                        if (isFromAssetSelected) {
                            child = multiassets[i];
                            childAV = multiassets[i].assetTypeAttributeValues;
                        }
                    }
                }
                childAV.forEach(function(item, index) {
                    if (item.name == "Distance") {
                        fromAssetDistance = parseFloat(item.attributeValue);
                    }
                });

                multiassets.forEach(function(item, index) {
                    if (toAssetId == multiassets[index].id) {
                        toAssetAV = multiassets[index].assetTypeAttributeValues;
                    }
                });

                toAssetAV.forEach(function(item, index) {
                    if (item.name == "Distance") {
                        toAssetDistance = parseFloat(item.attributeValue);
                    }
                });
                assetFromToDistance = (fromAssetDistance - toAssetDistance);
            } else {
                isToAssetSelected = false;
            }
        })

        $("#toAssetsView").click(function() {
            if (!isFromAssetSelected) {
                alert("No From Asset Selected");
            }
        })

        function createMarkerForSelectedAsset(lati, lngi, name) {
            var pos = {
                lat: lati,
                lng: lngi
            };
            var marker = new google.maps.Marker({
                position: pos,
                map: locations.map,
                animation: google.maps.Animation.DROP,
                content: markerContent,
                icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                label: {
                    color: '#000000',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    text: name,
                    backgroundcolor: "#ff9800"
                },
                title: name
            });
            var markerContent = '<div class="infoWindowhead">' +
                name +
                '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                lati +
                '<br> <b>Lng :</b> ' +
                lngi +
                '<br> <b><a target="_blank" href=https://www.google.com/maps/search/?api=1&query=' + lati + ',' + lngi + '>Go To</a></b>' +
                '</div>';

            // markerVal = marker;
            var infowindow = new google.maps.InfoWindow({
                content: markerContent
            });
            //locations.map.setCenter(pos);
            marker.addListener('click', function() {
                infowindow.open(map, marker);
            });
        }
        $scope.$on('$destroy', function() {
            $interval.cancel(updateAssets);
        });
        $scope.clearSearch = function() {
            $scope.searchAll = null;
        }

        function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        }


        $scope.gotoGeneratedLocation = function() {
            hideModal();
            $state.go('tr-case.new', {
                'lat': vm.generatedLat,
                'lng': vm.generatedLng,
                'assetdetails': vm.childName,
                'desc': "Faulty cable between " + vm.parentName + " and " + vm.childName
            });
        };

        $scope.saveEditAsset = function() {
            vm.isSaving = false;
            var assetAV = vm.asset.assetTypeAttributeValues;

            assetAV.forEach(function(item, index) {

                if (item.name == "Fiber Core") {
                    item.attributeValue = $scope.selectJustId;

                }
                if (item.name == "Cable Length From Base") {
                    item.attributeValue = $("#otdr-val").val();
                }
            });
            vm.asset.assetTypeAttributeValues = assetAV;
            if (vm.asset != null) {
                Asset.update(vm.asset, onSaveSuccess, onSaveError);
            }

            savedAssetFromMapLoc = new google.maps.LatLng(vm.asset.assetCoordinates[vm.asset.assetCoordinates.length - 1].latitude,
                vm.asset.assetCoordinates[vm.asset.assetCoordinates.length - 1].longitude);
            vm.asset = null;
        };

        $scope.saveAssetPath = function() {
            Asset.update(vm.asset, onSaveSuccess, onSaveError);
        };

        $scope.closeUserEditModal = function() {
            $("#userEditModel").hide();
        }

        $scope.closeFilterModal = function() {
            vm.assetByType = prevAssetByType;
            vm.assetStatus = prevAssetStatus;
            vm.assetOwner = prevAssetOwner;
            $scope.hideFilterModal();
        }

		$scope.hideEditModal = function() {
            $("#ribbonPathModal").hide();
        }

        $scope.saveFilter = function() {
            noDataText.style.display = "none";
            if (vm.mapAssets != null) {
                if (vm.assetByType.length > 0 || vm.assetStatus.length > 0 || vm.assetOwner.length > 0) {
                    vm.filteredAsset = [];

                    if (vm.assetByType.length > 0) {
                        vm.assetByType.forEach(function(assettype) {
                            vm.mapAssets.forEach(function(asset) {
                                if (assettype == "KML Link") {
                                    if (asset.assetTypeName == "KML Link" || asset.assetTypeName == "KML Link_Vendor1" || asset.assetTypeName == "KML Link_Vendor2" || asset.assetTypeName == "KML Link_Vendor3") {
                                        vm.filteredAsset.push(asset);
                                    }
                                } else if (assettype == asset.assetTypeName) {
                                    vm.filteredAsset.push(asset);
                                }
                            });
                        });
                    } else {
                        vm.filteredAsset = vm.mapAssets;
                    }

                    if (vm.assetStatus.length > 0) {
                        var tempFilteredAsset = [];
                        vm.assetStatus.forEach(function(assetStatus) {
                            vm.filteredAsset.forEach(function(asset) {
                                if (assetStatus == asset.status) {
                                    tempFilteredAsset.push(asset);
                                }
                            });
                        });
                        vm.filteredAsset = tempFilteredAsset;
                    }

                    if (vm.assetOwner.length > 0) {
                        var tempFilteredAsset = [];
                        vm.assetOwner.forEach(function(assetOwner) {
                            vm.filteredAsset.forEach(function(asset) {
                                if (assetOwner == asset.owner) {
                                    tempFilteredAsset.push(asset);
                                }
                            });
                        });
                        vm.filteredAsset = tempFilteredAsset;
                    }


                    if (vm.filteredAsset.length > 0) {

                        var myOptions = {
                            zoom: 14,
                            center: locations.latlng,
                            mapTypeId: google.maps.MapTypeId.TERRAIN
                        };

                        locations.map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);

                        google.maps.event.addListener(locations.map, 'zoom_changed', function() {
                            vm.zoomLevel = locations.map.getZoom();

                            if ((vm.prevZoomLevel == 10 && vm.zoomLevel == 11) || (vm.prevZoomLevel == 11 && vm.zoomLevel == 10)) {
//                                if (vm.filteredAsset != null) {
//                                    drawLatestAssets(vm.filteredAsset);
//                                } else {
                                    drawLatestAssets(vm.mapAssets);
//                                }
                            }
                        });

                        prevAssetByType = vm.assetByType;
                        prevAssetStatus = vm.assetStatus;
                        prevAssetOwner = vm.assetOwner;


                        $scope.hideFilterModal();

                    } else {
                        noDataText.style.display = "block";
                    }
                } else {
                    var myOptions = {
                        zoom: 14,
                        center: locations.latlng,
                        mapTypeId: google.maps.MapTypeId.TERRAIN
                    };
                    locations.map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);

                    drawLatestAssets(vm.mapAssets);

                    prevAssetByType = vm.assetByType;
                    prevAssetStatus = vm.assetStatus;
                    prevAssetOwner = vm.assetOwner;

                    $scope.hideFilterModal();
                }
            }


        };

        function onSaveSuccess(result) {
            $scope.$emit('trakeyeApp:assetUpdate', result);
            //vm.isSaving = false;
            //$state.go('asset');
            var updatedAsset = {
                assetColor: result.assetType.colorcode,
                assetCoordinates: result.assetCoordinates,
                assetTypeImagePath: result.assetType.imagePath,
                assetTypeLayout: result.assetType.layout,
                assetTypeName: result.assetType.name,
				assetOwner:result.ownedBy,
                id: result.id,
                name: result.name
            };
            hideUserEditModal();
            var allAssetVal = vm.allAssets;
            vm.allAssets = [];

            allAssetVal.forEach(function(assets) {
                if (updatedAsset.id == assets.id) {
                    vm.allAssets.push(updatedAsset);
                } else {
                    vm.allAssets.push(assets);
                }
            });
            drawLatestAssets(vm.allAssets);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        $scope.gotoGeneratedLocation2 = function() {
            var transform = $(".gm-style>div:first>div:first>div:last>div").css("transform")
            var comp = transform.split(",") // split up the transform matrix
            var mapleft = parseFloat(comp[4]) // get left value
            var maptop = parseFloat(comp[5]) // get top value
            $(".gm-style>div:first>div:first>div:last>div").css({
                "transform": "none",
                "left": mapleft,
                "top": maptop,
            });


            $(".gm-style>div:first>div:first>div:nth-child(2)>div:first>div").css({
                "transform": "none",
                "left": mapleft,
                "top": maptop,
            });

            html2canvas($('#mainDiv'), {
                useCORS: true,
                onrendered: function(canvas) {
                    var dataUrl = canvas.toDataURL('image/png');
                    var doc = new jsPDF('l', 'px', 'a3');
                    doc.addImage(dataUrl, 'PNG', 10, 10, 605, 400);
                    doc.save('SLD_Export.pdf');
                }
            });
        }

        $scope.gotoGeneratedLocation1 = function() {
            html2canvas([document.getElementById('location-map-asset')], {
                useCORS: true,
                optimized: false,
                allowTaint: false,
                onrendered: function(canvas) {
                    var imgData = canvas.toDataURL(
                        'image/png');

                    var doc = new jsPDF('l', 'px', 'a4');
                    doc.addImage(imgData, 'PNG', 20, 20, 1000, 640);
                    doc.save('sample-file.pdf');
                }
            });

        };

        function saveAs(uri, filename) {
            var link = document.createElement('a');
            if (typeof link.download === 'string') {
                link.href = uri;
                link.download = filename;

                // Firefox requires the link to be in the body
                document.body.appendChild(link);

                // simulate click
                link.click();

                // remove the link when done
                document.body.removeChild(link);
            } else {
                window.open(uri);
            }
        }

        function hideModal() {
            $("#mapModalForFiberifying").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapModalForFiberifying").hide();
        }

        function hideUserEditModal() {
            $("#userEditModel").removeClass("in");
            $(".modal-backdrop").remove();
            $("#userEditModel").hide();
        }

        function hideribbonEditModal() {
            $("#ribbonPathModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#ribbonPathModal").hide();
        }


        $scope.setTab = function(newTab) {
            $scope.tab = newTab;

            if ($scope.tab == 5) {
                filterAssetGroupSearch();
            } else if ($scope.tab == 6) {
                $scope.search = "";
                if (vm.mapAssets == null) {

                    vm.assetByType = [];
                    vm.assetStatus = [];
                    vm.assetOwner = [];

                    $scope.toggleAssetTypeSelection = function toggleAssetTypeSelection(assetTypeName) {
                        var idx = vm.assetByType.indexOf(assetTypeName);

                        if (idx > -1) {
                            vm.assetByType.splice(idx, 1);
                        } else {
                            vm.assetByType.push(assetTypeName);
                        }
                    };

                    $scope.toggleAssetStatusSelection = function toggleAssetStatusSelection(statusName) {
                        var idx = vm.assetStatus.indexOf(statusName);

                        if (idx > -1) {
                            vm.assetStatus.splice(idx, 1);
                        } else {
                            vm.assetStatus.push(statusName);
                        }
                    };

                    $scope.toggleAssetOwnerSelection = function toggleAssetOwnerSelection(ownerName) {
                        var idx = vm.assetOwner.indexOf(ownerName);

                        if (idx > -1) {
                            vm.assetOwner.splice(idx, 1);
                        } else {
                            vm.assetOwner.push(ownerName);
                        }
                    };

                    filterGroupAssetsformap();
                } else {
                    vm.assetByType = [];
                    vm.assetStatus = [];
                    vm.assetOwner = [];

                    $scope.toggleAssetTypeSelection = function toggleAssetTypeSelection(assetTypeName) {
                        var idx = vm.assetByType.indexOf(assetTypeName);

                        if (idx > -1) {
                            vm.assetByType.splice(idx, 1);
                        } else {
                            vm.assetByType.push(assetTypeName);
                        }
                    };

                    $scope.toggleAssetStatusSelection = function toggleAssetStatusSelection(statusName) {
                        var idx = vm.assetStatus.indexOf(statusName);

                        if (idx > -1) {
                            vm.assetStatus.splice(idx, 1);
                        } else {
                            vm.assetStatus.push(statusName);
                        }
                    };

                    $scope.toggleAssetOwnerSelection = function toggleAssetOwnerSelection(ownerName) {
                        var idx = vm.assetOwner.indexOf(ownerName);

                        if (idx > -1) {
                            vm.assetOwner.splice(idx, 1);
                        } else {
                            vm.assetOwner.push(ownerName);
                        }
                    };
                    drawLatestAssets(vm.mapAssets);
                }
            } else if ($scope.tab == 2 || $scope.tab == 3 || $scope.tab == 4) {
                filterAssetsformap();
            } else if ($scope.tab == 1) {
                filterAssetSearch();
            } else if ($scope.tab == 8) {
                filterAssetSchematic();
            } 
            // else if ($scope.tab == 9) {
            //     filterAssetTree();
            // }
        };

        function select2() {
            $(".select2").select2();
            $(".select2").on('select2:select', function(e) {
                alert($(".select2").val());
            });
        }

        function drawPolylineForGeoFence(latlngsToDraw, map, details, geofence) {
            var spreadAssetCoordinates = [];
            $.each(latlngsToDraw, function(index, value) {

                spreadAssetCoordinates.push({
                    lat: value.lat,
                    lng: value.lng
                });
            });

            if (spreadAssetCoordinates.length == 1) {

                var midLatLng = {
                    lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
                    lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
                };
                // Create our info window content
                var infoStr = "";
                var geoAssets = geofence.assets;

                var wdmCount = 0;
                var nttnCount = 0;
                var othersCount = 0;
                var backtobackCount = 0;
                var routerCount = 0;

                var assetStr = "";

                geoAssets.forEach(function(item1) {
                    if (item1.assetType.name == 'WDM') {
                        wdmCount++;
                        assetStr += '<b>WDM</b> : ' + item1.name + '<br>'
                    } else if (item1.assetType.name == 'NTTN') {
                        nttnCount++;
                        assetStr += '<b>NTTN</b> : ' + item1.name + '<br>'
                    } else if (item1.assetType.name == 'Router') {
                        routerCount++;
                        assetStr += '<b>Router</b> : ' + item1.name + '<br>'
                    } else if (item1.assetType.name == 'BackToBack') {
                        backtobackCount++;
                        assetStr += '<b>BackToBack</b> : ' + item1.name + '<br>'
                    } else if (item1.assetType.name == 'Others') {
                        othersCount++;
                        assetStr += '<b>Others</b> : ' + item1.name + '<br>'
                    }

                });
                var assetTypeAttributeValuesList = fixed.assetTypeAttributeValues;
                assetTypeAttributeValuesList.sort(function(a, b) {
                    var nameA = a.id,
                        nameB = b.id
                    if (nameA < nameB) // sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 // default return value (no sorting)
                });
                for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
                    var home = assetTypeAttributeValuesList[i];
                    infoStr += '<br><b>' +
                        home.name +
                        '</b> : ' +
                        home.attributeValue;
                    //otdr = home;
                }
                var infotable = '<table border="1" style="width:100%;text-align:center">' +
                    '<tr>' +
                    '<th style="padding: 5px;text-align:center">WDM</th>' +
                    '<th style="padding: 5px;text-align:center">NTTN</th>' +
                    '<th style="padding: 5px;text-align:center">Router</th>' +
                    '<th style="padding: 5px;text-align:center">BackToBack</th>' +
                    '<th style="padding: 5px;text-align:center">Others</th>' +
                    '</tr>' +
                    '<tr>' +
                    '<td style="padding: 5px;text-align:center">' + wdmCount + '</td>' +
                    '<td style="padding: 5px;text-align:center">' + nttnCount + '</td>' +
                    '<td style="padding: 5px;text-align:center">' + routerCount + '</td>' +
                    '<td style="padding: 5px;text-align:center">' + backtobackCount + '</td>' +
                    '<td style="padding: 5px;text-align:center">' + othersCount + '</td>' +
                    '</tr>' +
                    '</table>';

                var infoWindowContent = '<div class="info_content">' +
                    '<b>' + geofence.name + '</b>' +
                    '<br><br>' + infotable +
                    infoStr + '</div>';

                // Initialise the inforWindow
                var infoWindow = new google.maps.InfoWindow({
                    content: infoWindowContent
                });

                var labelVal = geofence.name.charAt(0).toUpperCase();

                var iconUrl = '';

                if (wdmCount > 0 && nttnCount > 0 && routerCount > 0) {
                    iconUrl = 'content/images/green_circle1.png';
                } else if (wdmCount > 0 && nttnCount > 0) {
                    iconUrl = 'content/images/blue_circle1.png';
                } else if (nttnCount > 0 && routerCount > 0) {
                    iconUrl = 'content/images/pink_circle1.png';
                } else if (nttnCount > 0) {
                    iconUrl = 'content/images/orange_circle1.png';
                } else {
                    iconUrl = 'content/images/red_circle1.png';
                }

                // Add a marker to the map based on our coordinates
                var marker = new google.maps.Marker({
                    position: midLatLng,
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(24, 24),
                        origin: new google.maps.Point(0, 0),
                        url: iconUrl,
                        anchor: new google.maps.Point(0, 0),

                    },
                    title: geofence.name
                });

                // Display our info window when the marker is clicked
                google.maps.event.addListener(marker, 'click', function(e) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infoWindow.open(map, marker);
                    openedInfoWindow = infoWindow;
                });


            } else if (spreadAssetCoordinates.length > 1) {
                var midLatLng = {
                    lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
                    lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
                };
                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        "name" +
                        '<div class="infoWindowContent"> <b>Description :</b> ' +
                        "des" + '</div></div>'
                });

                infowindow.setPosition(midLatLng);
                var ColorValue = '#33001a';

                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeWeight: 3
                });

                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
                    infowindow.close();
                });

                google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent('<div class="infoWindowhead">' +
                        '<b></b>' + geofence.name + '</br></div>'
                    );

                    infowindow.mousemove;
                    infowindow.open(map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                });
                polylinepath.setMap(map);
            }
        }
        vm.fiberClick = function(index) {
            //editAsset(vm.fiberCoreData[index]);
        }

        function editJoinerPath(assetVal) {
            $('#corecreate').css('color', 'white');
            vm.tubeoperators = [];
            vm.coreoperators = [];
            var ele = document.getElementById("userEditModel");
            $(ele).modal('show');
            AssetByID.get(assetVal.id, function(assetVal) {
                var map = new google.maps.Map(document.getElementById('map_canvas_edit_path'), {
                    center: new google.maps.LatLng(assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude, assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude),
                    zoom: 18
                });
                assetVal.assetCoordinates.forEach(function(coordinate) {
                    var lat1 = coordinate.latitude;
                    var lng1 = coordinate.longitude;

                    var assetLatLng = { lat: lat1, lng: lng1 };
                    var marker = new google.maps.Marker({
                        position: assetLatLng,
                        map: map,
                        icon: {
                            scaledSize: new google.maps.Size(
                                24, 24),
                            origin: new google.maps.Point(0, 0),
                            url: 'content/images/yellow.png',
                            anchor: new google.maps.Point(
                                16, 16),

                        },
                        label: assetVal.name
                    });
                    marker.setMap(map);
                    var spreadAssetCoordinates = [];
                    $.each(assetVal.ownedBy[0].assetCoordinates, function(index, value) {
                        spreadAssetCoordinates.push({
                            lat: value.latitude,
                            lng: value.longitude
                        });
                    });
                    var polylinepath = new google.maps.Polyline({
                        path: spreadAssetCoordinates,
                        geodesic: true,
                        strokeColor: '#F4A500',
                        strokeOpacity: 1.0,
                        strokeWeight: 2.5,
                        fillColor: '#F4A500',
                        fillOpacity: 0.3,
                        editable: false
                    });
                    polylinepath.setMap(map);

                    google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                    });

                    google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var infowindow = new google.maps.InfoWindow({
                            content: '<div class="infoWindowhead">' +
                                "name : " + assetVal.ownedBy[0].name + '</div>'
                        });
                        infowindow.setPosition(event.latLng);
                        infowindow.open(map);
                        openedInfoWindow = infowindow;
                    });
                })
                ChildAssets.query({
                    parentId: assetVal.ownedBy[0].id
                }, onSuccess);

                function onSuccess(data, headers) {
                    vm.tubeoperators = [];
                    data.forEach(function(value) {
                        if (value.id != assetVal.id) {
                            vm.tubeoperators.push(value)
                        }
                    })
                    $("#child1dropdown").change(function() {
                        var tubeAsset = JSON.parse($("#child1dropdown").val());
                        AssetByID.get(tubeAsset.id, function(tubeAsset) {
                            vm.selectedTube = tubeAsset;
                            ChildAssets.query({
                                parentId: tubeAsset.id
                            }, onSuccess1);

                            function onSuccess1(data, headers) {

                                vm.coreoperators = [];
                                data.forEach(function(value) {
                                    vm.coreoperators.push(value)
                                })
                                $("#child2dropdown").change(function() {
                                    var tubeAsset = JSON.parse($("#child2dropdown").val());
                                    AssetByID.get(tubeAsset.id, function(tubeAsset) {
                                        vm.selectedCore = tubeAsset;
                                        $('#corecreate').css('color', 'blue');
                                    })
                                });

                            }
                        });
                    });
                }
            });
        }

        function editSplitter(assetdetails) {
            vm.splitterCore = [];
            var ele = document.getElementById("splitterEditModel");
            $(ele).modal('show');
            AssetByID.get(assetdetails.id, function(assetVal) {
                var map = new google.maps.Map(document.getElementById('map_canvas_splitter_path'), {
                    center: new google.maps.LatLng(assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude, assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude),
                    zoom: 18
                });
                assetVal.assetCoordinates.forEach(function(coordinate) {
                    var lat1 = coordinate.latitude;
                    var lng1 = coordinate.longitude;

                    var assetLatLng = { lat: lat1, lng: lng1 };
                    var marker = new google.maps.Marker({
                        position: assetLatLng,
                        map: map,
                        icon: {
                            scaledSize: new google.maps.Size(24, 24),
                            origin: new google.maps.Point(0, 0),
                            url: 'content/images/yellow.png',
                            anchor: new google.maps.Point(16, 16),
                        },
                        label: assetVal.name
                    });
                    marker.setMap(map);
                })

                ChildAssets.query({
                    parentId: assetVal.id
                }, onSuccess1);

                function onSuccess1(data, headers) {
                    vm.splitterCore = [];
                    data.forEach(function(value) {
                        vm.splitterCore.push(value)
                    });
                    $("#splittercoredropdown").change(function() {
                        vm.selectedSplitterCore = null;
                        var tubeAsset = JSON.parse($("#splittercoredropdown").val());
                        AssetByID.get(tubeAsset.id, function(tubeAsset) {
                            vm.selectedSplitterCore = tubeAsset;
                            var assetVal = {};
                            var assetLatLng = [];
                            var assetLabel = "";
                            var value = 3;
                            if (value == 0) {
                                assetVal = vm.selectedCable;
                                vm.prevAssetCoordinates = assetVal.assetCoordinates;
                                assetLatLng = ({
                                    lat: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude,
                                    lng: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude
                                });

                            } else if (value == 1) {
                                assetVal = vm.selectedTube;
                                assetLabel = assetVal.name;
                                assetLatLng = ({
                                    lat: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].latitude,
                                    lng: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].longitude
                                });
                            } else if (value == 2) {
                                assetVal = vm.selectedCore;
                                assetLabel = assetVal.name;
                                assetLatLng = ({
                                    lat: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].latitude,
                                    lng: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].longitude
                                });
                            } else if (value == 3) {
                                assetVal = vm.selectedSplitterCore;
                                assetLabel = assetVal.name;
                                assetLatLng = ({
                                    lat: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude,
                                    lng: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude
                                });
                            }

                            vm.asset = assetVal;
                            var map = new google.maps.Map(document.getElementById('map_canvas_splitter_path'), {
                                center: new google.maps.LatLng(assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude, assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude),
                                zoom: 18
                            });

                            var marker = new google.maps.Marker({
                                position: assetLatLng,
                                map: map,
                                icon: {
                                    scaledSize: new google.maps.Size(
                                        24, 24),
                                    origin: new google.maps.Point(0, 0),
                                    url: 'content/images/yellow.png',
                                    anchor: new google.maps.Point(
                                        16, 16),

                                },
                                label: assetLabel
                            });
                            marker.setMap(map);

                            var spreadAssetCoordinates = [];
                            $.each(assetVal.assetCoordinates, function (index, value) {
                                spreadAssetCoordinates.push({
                                    lat: value.latitude,
                                    lng: value.longitude
                                });
                            });
                            var polylinepath = new google.maps.Polyline({
                                path: spreadAssetCoordinates,
                                geodesic: true,
                                strokeColor: '#F4A500',
                                strokeOpacity: 1.0,
                                strokeWeight: 2.5,
                                fillColor: '#F4A500',
                                fillOpacity: 0.3,
                                editable: false
                            });
                            polylinepath.setMap(map);

                            google.maps.event.addListener(polylinepath, 'mouseout', function (event) {
                                if (openedInfoWindow != null) {
                                    openedInfoWindow.close();
                                }
                            });

                            google.maps.event.addListener(polylinepath, 'mouseover', function (event) {
                                if (openedInfoWindow != null) {
                                    openedInfoWindow.close();
                                }
                                var infowindow = new google.maps.InfoWindow({
                                    content: '<div class="infoWindowhead">' +
                                        "name : " + assetVal.ownedBy[0].name + '</div>'
                                });
                                infowindow.setPosition(event.latLng);
                                infowindow.open(map);
                                openedInfoWindow = infowindow;
                            });
                            //vm.asset.assetCoordinates = [];
                            // drawingManagerSpread();
                            // drawingManagerValue('polylinecomplete', map);
                            drawPolyline(function (polyline) {
                                polyline.setMap(map);
                                google.maps.event.addListener(polyline.getPath(),
                                    'insert_at',
                                    function () {
                                        setGoefenceCoordinate(polyline);
                                    });
                                google.maps.event.addListener(polyline.getPath(),
                                    'set_at',
                                    function (path) {
                                        setGoefenceCoordinate(polyline);
                                    });

                                vm.drawingManager.setOptions({
                                    drawingControl: false
                                });
                                vm.drawingManager.setMap(null);

                            })
                        });
                    });
                }
            });
        }

        $scope.drawPath = function(value) {
            var assetVal = {};
            var assetLatLng = [];
            var assetLabel = "";
            if (value == 0) {
                assetVal = vm.selectedCable;
                vm.prevAssetCoordinates = assetVal.assetCoordinates;
                assetLatLng = ({
                    lat: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude,
                    lng: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude
                });
            } else if (value == 1) {
                assetVal = vm.selectedTube;
                assetLabel = assetVal.name;
                assetLatLng = ({
                    lat: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].latitude,
                    lng: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].longitude
                });
            } else if (value == 2) {
                assetVal = vm.selectedCore;
                assetLabel = assetVal.name;
                assetLatLng = ({
                    lat: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].latitude,
                    lng: assetVal.ownedBy[0].assetCoordinates[assetVal.ownedBy[0].assetCoordinates.length - 1].longitude
                });
            } else if (value == 3) {
                assetVal = vm.selectedSplitterCore;
                if(assetVal.assetCoordinates.length > 0){
                    assetLabel = assetVal.name;
                    assetLatLng = ({
                        lat: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude,
                        lng: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude
                    });
                }else{
                }
            }

            vm.asset = assetVal;
            var map = new google.maps.Map(document.getElementById('map_canvas_splitter_path'), {
                center: new google.maps.LatLng(assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude, assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude),
                zoom: 18
            });

            var marker = new google.maps.Marker({
                position: assetLatLng,
                map: map,
                icon: {
                    scaledSize: new google.maps.Size(
                        24, 24),
                    origin: new google.maps.Point(0, 0),
                    url: 'content/images/yellow.png',
                    anchor: new google.maps.Point(
                        16, 16),

                },
                label: assetLabel
            });
            marker.setMap(map);

            var spreadAssetCoordinates = [];
            $.each(assetVal.assetCoordinates, function(index, value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude
                });
            });
            var polylinepath = new google.maps.Polyline({
                path: spreadAssetCoordinates,
                geodesic: true,
                strokeColor: '#F4A500',
                strokeOpacity: 1.0,
                strokeWeight: 2.5,
                fillColor: '#F4A500',
                fillOpacity: 0.3,
                editable: false
            });
            polylinepath.setMap(map);

            google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
            });

            google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        "name : " + assetVal.ownedBy[0].name + '</div>'
                });
                infowindow.setPosition(event.latLng);
                infowindow.open(map);
                openedInfoWindow = infowindow;
            });
            //vm.asset.assetCoordinates = [];
            drawingManagerSpread();
            drawingManagerValue('polylinecomplete', map);
            drawPolyline(function(polyline) {
                polyline.setMap(map);
                google.maps.event.addListener(polyline.getPath(),
                    'insert_at',
                    function() {
                        setGoefenceCoordinate(polyline);
                    });
                google.maps.event.addListener(polyline.getPath(),
                    'set_at',
                    function(path) {
                        setGoefenceCoordinate(polyline);
                    });

                vm.drawingManager.setOptions({
                    drawingControl: false
                });
                vm.drawingManager.setMap(null);

            })
        }


	  $scope.saveSplitterAssetPath = function() {
            Asset.update(vm.asset, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess(result) {
            $("#splitterEditModel").hide();
            $scope.$emit('trakeyeApp:assetUpdate', result);
            vm.isSaving = false;
            window.location.reload(true)
            $state.go('asset');
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        function drawPolyline(callback) {
            setValues();
            var centerlatlng = {};
            if(vm.coOrdinates.length > 1){
                JSON.parse(vm.coOrdinates).forEach(function(value, key) {
                    centerlatlng = {
                        lat: value.lat,
                        lng: value.lng
                    };
                });    
            
            
                callback(new google.maps.Polyline({
                    path: JSON.parse(vm.coOrdinates),
                    geodesic: true,
                    strokeColor: vm.asset.assetType.colorcode,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: vm.asset.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: true
                }));
                map.setCenter(centerlatlng);
            }
        }

        function setGoefenceCoordinate(event) {
            vm.assetCoordinateValues = [];
            vm.asset.assetCoordinates = [];
            var radius = event.getPath();
            for (var i = 0; i < radius.getLength(); i++) {
                var coordinates = {};
                coordinates.latitude = radius.getAt(i).lat();
                coordinates.longitude = radius.getAt(i).lng();
                vm.asset.assetCoordinates.push(coordinates);
            }
        }

        function setValues() {
            vm.coOrdinates = [];
            for (var i = 0; i < vm.asset.assetCoordinates.length; i++) {
                vm.coOrdinates.push({
                    lat: vm.asset.assetCoordinates[i].latitude,
                    lng: vm.asset.assetCoordinates[i].longitude
                });
                if (i == vm.asset.assetCoordinates.length - 1) {
                    vm.coOrdinate = JSON.stringify(vm.coOrdinates);
                }
            }
        }

        function drawingManagerSpread() {
            vm.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYLINE,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['polyline']
                },
                polylineOptions: {
                    fillColor: '#ffff00',
                    fillOpacity: 1,
                    strokeWeight: 5,
                    clickable: true,

                    editable: true,
                    zIndex: 1,
                    geodesic: true
                }
            });
        }

        function drawingManagerValue(option, map) {
            google.maps.event.addListener(vm.drawingManager, option,
                function(event) {
                    google.maps.event.addListener(event.getPath(),
                        'insert_at',
                        function() {
                            setGoefenceCoordinate(event);
                        });
                    google.maps.event.addListener(event.getPath(),
                        'set_at',
                        function(path) {
                            setGoefenceCoordinate(event);
                        });
                    vm.drawingManager.setOptions({
                        drawingControl: false
                    });
                    vm.drawingManager.setMap(null);
                    setGoefenceCoordinate(event);
                });
            vm.drawingManager.setMap(map);
        }

        function filterAssetsformap() {
            if (locations.map != null) {
                var myOptions = {
                    zoom: 11,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.TERRAIN
                };
                locations.map = new google.maps.Map(document
                    .getElementById("map_canvas"), myOptions);
            }

            if ($scope.search != null && $scope.search != "" &&
                !angular.isUndefined($scope.search)) {
                vm.multiassets = [];
                assetsArray = [];

                AssetSearchForMap.searchassetsformap($scope.search, function(
                    response) {
                    vm.fromAssetsView = [];
                    response.forEach(function(asset) {
                        vm.fromAssetsView.push({
                            id: asset.id,
                            name: asset.name,
                            ticked: false,
                            ownedBy: asset.ownedBy
                        });
                        if (selctedAssets.indexOf(asset.id) !== -1) {
                            vm.multiassets.push({
                                lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                name: asset.name,
                                id: asset.id,
                                ownedBy: asset.ownedBy,
                                typeName: asset.assetType.name,
                                ticked: true,
                                assetCoordinates: asset.assetCoordinates,
                                imgPath: asset.assetType.imagePath
                            });
                        } else {
                            vm.multiassets.push({
                                lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                name: asset.name,
                                id: asset.id,
                                ownedBy: asset.ownedBy,
                                typeName: asset.assetType.name,
                                ticked: false,
                                assetCoordinates: asset.assetCoordinates,
                                imgPath: asset.assetType.imagePath
                            });
                        }
                        assetsArray.push(asset);
                    });
                    drawLatestAssets(response);
                });

            } else {
                vm.multiassets = [];
                assetsArray = [];
                AssetsForMap.assetsformap(function(response) {
                    vm.fromAssetsView = [];
                    response.forEach(function(asset) {
                        vm.fromAssetsView.push({
                            id: asset.id,
                            name: asset.name,
                            ticked: false,
                            ownedBy: asset.ownedBy
                        });

                        try {
                            if (selctedAssets.indexOf(asset.id) !== -1) {
                                vm.multiassets.push({
                                    lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                    lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                    assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                    name: asset.name,
                                    id: asset.id,
                                    ownedBy: asset.ownedBy,
                                    typeName: asset.assetType.name,
                                    ticked: true,
                                    assetCoordinates: asset.assetCoordinates,
                                    imgPath: asset.assetType.imagePath
                                });
                            } else {
                                vm.multiassets.push({
                                    lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                    lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                    assetTypeAttributeValues: asset.assetTypeAttributeValues,
                                    name: asset.name,
                                    id: asset.id,
                                    ownedBy: asset.ownedBy,
                                    typeName: asset.assetType.name,
                                    ticked: false,
                                    assetCoordinates: asset.assetCoordinates,
                                    imgPath: asset.assetType.imagePath
                                });
                            }
                            assetsArray.push(asset);
                        } catch (err) {

                        }

                    });
                    drawLatestAssets(response);
                });
            }

            if (savedAssetFromMapLoc != null) {
                if (locations.map != null) {
                    var myOptions = {
                        zoom: 17,
                        center: savedAssetFromMapLoc
                    };
                    locations.map = new google.maps.Map(document
                        .getElementById("map_canvas"), myOptions);
                }
            }
        }

        var noDataText = null;
        $scope.filterDialog = function() {
            $("#mapFilterModal").show();
            noDataText = document.getElementById("noData");
            noDataText.style.display = "none";
        }

        $scope.hideFilterModal = function() {
            $("#mapFilterModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#mapFilterModal").hide();
        }

        $scope.closeAssetPathModal = function() {
            $("#assetPathModal").removeClass("in");
            $(".modal-backdrop").remove();
            $("#assetPathModal").hide();
        }

        function filterGroupAssetsformap() {
            if (locations.map != null) {
                var myOptions = {
                    zoom: 11,
                    center: locations.latlng,
                    mapTypeId: google.maps.MapTypeId.TERRAIN
                };
                locations.map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
            }

            vm.multiassets = [];
            assetsArray = [];
            AttrTypeVal.loaddata('Link', 'Fiber Owner', function(data) {
                if (data.length > 0) {
                    vm.assetByOwner = [];
                    data.forEach(function(val) {
                        vm.assetByOwner.push(val);
                    });
                }
            });
            getGroupAsset(0, vm.multiassets);
            if (savedAssetFromMapLoc != null) {
                if (locations.map != null) {
                    var myOptions = {
                        zoom: 12,
                        center: savedAssetFromMapLoc
                    };
                    locations.map = new google.maps.Map(document
                        .getElementById("map_canvas"), myOptions);
                }
            }
        }

        function getGroupAsset(pageNum, mapAssetLists) {
            GroupAssets.query({
                page: pageNum,
                size: 500
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.mapAssets = mapAssetLists;
                data.forEach(function(asset) {
                    var tmpAsset = {};
                    tmpAsset.assetCoordinates = asset.assetCoordinates;
                    tmpAsset.assetTypeName = asset.assetType.name;
                    tmpAsset.assetTypeColor = asset.assetType.colorcode;
					tmpAsset.assetTypeImagePath= asset.assetType.imagePath,
                    tmpAsset.assetTypeLayout = asset.assetType.layout;
					tmpAsset.assetTypeAttributeValues = asset.assetTypeAttributeValues;
					tmpAsset.assetOwner = asset.ownedBy;
                    tmpAsset.id = asset.id;
                    tmpAsset.name = asset.name;
                    vm.mapAssets.push(tmpAsset);
                });
                drawLatestAssets(vm.mapAssets);
                if (vm.assetTotalPage == 0) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assetTotalPage = Math.ceil(vm.totalItems / 500);
                }

                vm.assetCurrentPage = pageNum;

                if (vm.assetTotalPage > 1) {
                    if (vm.assetCurrentPage != vm.assetTotalPage) {
                        getGroupAsset(pageNum + 1, vm.multiassets)
                    }else{
						var lastAsset = vm.multiassets[vm.multiassets.length-1];
						if(lastAsset.assetCoordinates.length > 0){
							var latestLatLng = {
		                    	lat: lastAsset.assetCoordinates[0].latitude,
		                    	lng: lastAsset.assetCoordinates[0].longitude
			                };
							locations.map.setCenter(latestLatLng);
						}
					}
                }

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }	

        function getFixedAsset(pageNum) {
            GroupFixedAssets.query({
                page: pageNum,
                size: 50,
                defaultSort: 'desc'
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                if (vm.assetFixedTotalPage == 0) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assetFixedTotalPage = (Math.ceil(vm.totalItems / 100)) - 1;
                }
                if (vm.mapAssets != null) {
                    data.forEach(function(asset) {
                        vm.mapAssets.push(asset);
                    });
                } else {
                    vm.mapAssets = data;
                }

                drawLatestAssets(vm.mapAssets);
                vm.assetFixedCurrentPage = pageNum;

                if (vm.assetFixedTotalPage > 1) {
                    if (vm.assetFixedCurrentPage != vm.assetFixedTotalPage) {
                        getFixedAsset(pageNum + 1)
                    } else {
                        getSpreadAsset(0);
                    }
                } else {
                    getSpreadAsset(0);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getSpreadAsset(pageNum) {
            GroupSpreadAssets.query({
                page: pageNum,
                size: 30,
                defaultSort: 'desc'
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                if (vm.assetSpreadTotalPage == 0) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assetSpreadTotalPage = (Math.ceil(vm.totalItems / 30));
                }
                if (vm.mapAssets != null) {
                    data.forEach(function(asset) {
                        vm.mapAssets.push(asset);
                    });
                } else {
                    vm.mapAssets = data;
                }
                vm.allAssets = vm.mapAssets;
                drawLatestAssets(vm.mapAssets);
                vm.assetSpreadCurrentPage = pageNum;

                if (vm.assetSpreadTotalPage > 1) {
                    if (vm.assetSpreadCurrentPage != vm.assetSpreadTotalPage) {
                        getSpreadAsset(pageNum + 1)
                    } else {
                        FilterAssetsForMap.loaddata(function(data) {
                            for (var i = 0; i < vm.mapAssets.length; i++) {
                                data.forEach(function(value) {
                                    if (vm.mapAssets[i].id == value.id) {
                                        vm.mapAssets[i].owner = value.owner;
                                        vm.mapAssets[i].siteColor = value.siteColor;
                                        vm.mapAssets[i].status = value.status;
                                    }
                                });

                            }
                            drawLatestAssets(vm.mapAssets);
                            vm.allAssets = vm.mapAssets;
                        });
                    }
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        $scope.anyFunction = function() {}

        function searchAssets() {
            if ($scope.search != null && $scope.search != "" &&
                !angular.isUndefined($scope.search) && $scope.search.length != 1 && $scope.search.length != 2 && $scope.search.length != 3 && $scope.search.length != 4) {
                    AssetSearch.query({
                        search: $scope.search,
                        page: pagingParams.page - 1,
                        size: vm.dropDownValue,
                        sort: sort()
                    }, onSuccess, onError);
                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        // result.push('id');
                    }
                    return result;
                }
                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assets = data;
                    vm.page = pagingParams.page;

                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }

        }

        function filterAssetSearch() {
            if ($scope.search != null && $scope.search != "" &&
                !angular.isUndefined($scope.search)) {
                AssetSearch.query({
                    search: $scope.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        // result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assets = data;
                    vm.page = pagingParams.page;

                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                loadAll();
            }
        }

        function filterAssetSchematic() {
            getGroupAsset(0, vm.multiassets);
        }
        // function filterAssetTree() {
        //     getGroupAsset(0, vm.multiassets);
        // }
        function filterParentAsset(data) {
            var selectedParent = vm.parentAsset
            AssetGroupSearch.query({
                search: vm.parentAsset,
            }, onSuccess);

            function onSuccess(data, headers) {
                vm.parentAssets = data;
            }
            AssetChildren.getAssetChildren(selectedParent, function (response) {
                vm.treeData = [];
                vm.treeData.push(response);

                // ************** Generate the tree diagram	 *****************
                // var margin = { top: 20, right: 120, bottom: 20, left: 120 },
                //     width = 960 - margin.right - margin.left,
                //     height = 500 - margin.top - margin.bottom;

                // var i = 0,
                //     duration = 750,
                //     root;

                var margin = { top: 25, right: 120, bottom: 20, left: 120 },
                    width = 10000 - margin.right - margin.left,
                    height = 500 - margin.top - margin.bottom;

                var i = 0,
                    duration = 750,
                    root;

                // var margin = { top: 25, right: 120, bottom: 20, left: 120 },
                //     width = 10000 - margin.right - margin.left,
                //     height = 5000 - margin.top - margin.bottom;

                // var i = 0,
                //     duration = 750,
                //     rectW = 100,
                //     rectH = 30,
                //     root;

                var tree = d3.layout.tree()
                    .size([height, width]);

                var diagonal = d3.svg.diagonal()
                    .projection(function (d) { return [d.y, d.x]; });

                var svg = d3.select("#asset-tree-container").append("svg")
                    .attr("width", width + margin.right + margin.left)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                root = vm.treeData[0];
                root.x0 = height / 2;
                root.y0 = 0;

                // function collapse(d) {
                //     if (d.children) {
                //         d._children = d.children;
                //         d._children.forEach(collapse);
                //         d.children = null;
                //     }
                // }
                // root.children.forEach(collapse);
                update(root);

                d3.select(self.frameElement).style("height", "500px");

                function update(source) {

                    // Compute the new tree layout.
                    var nodes = tree.nodes(root).reverse(),
                        links = tree.links(nodes);

                    // Normalize for fixed-depth.
                    nodes.forEach(function (d) { d.y = d.depth * 180; });

                    // Update the nodes…
                    var node = svg.selectAll("g.node")
                        .data(nodes, function (d) { return d.id || (d.id = ++i); });

                    // Enter any new nodes at the parent's previous position.
                    var nodeEnter = node.enter().append("g")
                        .attr("class", "node")
                        .attr("transform", function (d) { return "translate(" + source.y0 + "," + source.x0 + ")"; })
                        .on("click", click);

                    // nodeEnter.append("circle")
                    //     .attr("r", 1e-6)
                    //     .style("fill", function(d) { return d._children ? "lightsteelblue" : "#fff"; });
                    nodeEnter.append("image")
                        .attr("xlink:href", function (d) { return d.icon; })
                        .attr("x", "-12px")
                        .attr("y", "-12px")
                        .attr("width", "24px")
                        .attr("height", "24px");

                    nodeEnter.append("text")
                        .attr("x", function (d) { return d.children || d._children ? -13 : 13; })
                        .attr("dy", ".35em")
                        .attr("text-anchor", function (d) { return d.children || d._children ? "end" : "start"; })
                        .style("fill", "black")
                        .text(function (d) {
                            return d.name;
                        })
                        .style("fill-opacity", 1e-6);

                    // Transition nodes to their new position.
                    var nodeUpdate = node.transition()
                        .duration(duration)
                        .attr("transform", function (d) { return "translate(" + d.y + "," + d.x + ")"; });

                    nodeUpdate.select("circle")
                        .attr("r", 10)
                        .style("fill", function (d) { return d._children ? "lightsteelblue" : "#fff"; });

                    nodeUpdate.select("text")
                        .style("fill-opacity", 1);

                    // Transition exiting nodes to the parent's new position.
                    var nodeExit = node.exit().transition()
                        .duration(duration)
                        .attr("transform", function (d) { return "translate(" + source.y + "," + source.x + ")"; })
                        .remove();

                    nodeExit.select("circle")
                        .attr("r", 1e-6);

                    nodeExit.select("text")
                        .style("fill-opacity", 1e-6);

                    // Update the links…
                    var link = svg.selectAll("path.link")
                        .data(links, function (d) { return d.target.id; });

                    // Enter any new links at the parent's previous position.
                    link.enter().insert("path", "g")
                        .attr("class", "link")
                        .style("stroke", function (d) {
                            if ((d.target.type = 'false') && (d.source.type = 'false')) {
                                return d.target.color
                            } else if ((d.target.type = 'true') && (d.source.type = 'true')) {
                                return d.target.color;
                            } else {
                                return d.target.color;
                            }
                        })
                        .attr("d", function (d) {
                            var o = { x: source.x0, y: source.y0 };
                            return diagonal({ source: o, target: o });
                        });



                    // Transition links to their new position.
                    link.transition()
                        .duration(duration)
                        .attr("d", diagonal);

                    // Transition exiting nodes to the parent's new position.
                    link.exit().transition()
                        .duration(duration)
                        .attr("d", function (d) {
                            var o = { x: source.x, y: source.y };
                            return diagonal({ source: o, target: o });
                        })
                        .remove();

                    // Stash the old positions for transition.
                    nodes.forEach(function (d) {
                        d.x0 = d.x;
                        d.y0 = d.y;
                    });
                }

                // Toggle children on click.
                function click(d) {
                    if (d.children) {
                        d._children = d.children;
                        d.children = null;
                    } else {
                        // if(d.depth == 0) {
                        // }
                        d.children = d._children;
                        d._children = null;
                    }
                    update(d);
                }
            });
        }

        function filterAssetGroupSearch() {
            if ($scope.search != null && $scope.search != "" &&
                !angular.isUndefined($scope.search)) {
                AssetGroupSearch.query({
                    search: $scope.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);

                function sort() {
                    var result = [vm.predicate + ',' +
                        (vm.reverse ? 'asc' : 'desc')
                    ];
                    if (vm.predicate !== 'id') {
                        // result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assets = data;
                    vm.page = pagingParams.page;

                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            } else {
                vm.dropDownValue = 10;
                AssetGroup.query({
                    page: pagingParams.page - 1,
                    search: $scope.search,
                    // size: vm.itemsPerPage,
                    size: 10,
                    sort: sort()
                }, onSuccess, onError);
                $("#tableDropdown").change(function () {
                    vm.dropDownValue = document.getElementById("tableDropdown").value;
                    AssetGroup.query({
                        page: pagingParams.page - 1,
                        search: $scope.search,
                        // size: vm.itemsPerPage,
                        size: vm.dropDownValue,
                        sort: sort()
                    }, onSuccess, onError);
                });
                function sort() {
                    var result = [vm.predicate + ',' +
                        (vm.reverse ? 'asc' : 'desc')
                    ];
                    if (vm.predicate !== 'id') {
                        // result.push('id');
                    }
                    return result;
                }

                function onSuccess(data, headers) {
                    vm.groupAssets = false;
                    vm.showAssetActions = false;
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assets = data;

                    vm.page = pagingParams.page;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
        }


        function loadAll() {
            Asset.query({
                page: pagingParams.page - 1,
                search: $scope.search,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' +
                    (vm.reverse ? 'asc' : 'desc')
                ];
                if (vm.predicate !== 'id') {
                    // result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.assets = data;
                vm.page = pagingParams.page;

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: $scope.search
            });
        }

        function drawLatestAssets(response) {
            if (response) {
                initializeAssetMap(response, function(latlang) {
                    userPaths.latlng = latlang;
                    locations.latlng = latlang;
					
                    setupAssetMap(function() {
                        $.each(response, function(index, value) {
                            if (value.assetTypeLayout != null && value.assetTypeLayout == 'FIXED') {
                                if (value.assetCoordinates.length > 1) {
                                    drawPolyline(value);
                                    var value1 = value;
                                    value1.assetCoordinates = [value.assetCoordinates[value.assetCoordinates.length - 1]];

                                    drawFixedAsset(value1, false);

                                } else {
                                    drawFixedAsset(value, false);
                                }
                            }
                            if (value.assetTypeLayout != null && value.assetTypeLayout == 'SPREAD') {
                                drawPolyline(value, locations.map);
                            }
							
                        });
                    });

					
                });
				
            }

            function setupAssetMap(callback) {
                if (locations.map == null) {
                    var myOptions = {
                        zoom: 14,
                        center: locations.latlng,
                        mapTypeId: google.maps.MapTypeId.TERRAIN
                    };

                    locations.map = new google.maps.Map(document
                        .getElementById("map_canvas"), myOptions);

                    google.maps.event.addListener(locations.map, 'zoom_changed', function() {
                        vm.zoomLevel = locations.map.getZoom();
                        if ((vm.prevZoomLevel == 17 && vm.zoomLevel == 18) || (vm.prevZoomLevel == 18 && vm.zoomLevel == 17)) {
//                            if (vm.filteredAsset != null) {
//                                drawLatestAssets(vm.filteredAsset);
//                            } else {
                                drawLatestAssets(vm.mapAssets);
//                            }
                        }
                        vm.prevZoomLevel = vm.zoomLevel;

                    });
                } else {
                    // Loop through all the markers and polylines to remove

                    for (var i = 0; i < markers.length; i++) {
                        markers[i].setMap(null);
                    }

                    for (i = 0; i < polylines.length; i++) {
                        polylines[i].setMap(null);
                    }

                    markers = [];
                    polylines = [];
                }
                locations.overlay = new google.maps.OverlayView();
                locations.overlay.draw = function() {};
                locations.overlay.setMap($scope.map);
                locations.element = document.getElementById('map_canvas');
                callback();
            }

            function addInfoWindow(marker, message) {

                var infoWindow = new google.maps.InfoWindow({
                    content: message
                });

                google.maps.event.addListener(marker, 'click', function() {
                    infoWindow.open(map, marker);
                });
            }

            function initializeAssetMap(latlangs, callback) {

                if (latlangs && latlangs.length > 0) {
                    var latVal = null;
                    var lngVal = null;
                    latlangs.forEach(function(value) {
                        if (latVal == null && lngVal == null) {
                            if (value.assetCoordinates.length > 0) {
                                latVal = value.assetCoordinates[value.assetCoordinates.length - 1].latitude;
                                lngVal = value.assetCoordinates[value.assetCoordinates.length - 1].longitude;
                            }
                        }
                    });
                    if (latVal != null && lngVal != null) {
                        latlng = new google.maps.LatLng(latVal, lngVal);
                        callback(latlng);
                    }
                } else {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function(
                            position) {

                            latlng = new google.maps.LatLng(
                                position.coords.latitude,
                                position.coords.longitude);
                            callback(latlng);
                        }, function() {

                            // callback(new google.maps.LatLng("12.9716", "77.5946"));
                            callback(new google.maps.LatLng("5.431587","101.128162"));
                        });
                    } else {
                        callback(new google.maps.LatLng("5.431587","101.128162"));
                    }
                }
            }

            function drawFixedAsset(fixed, hasParent) {

                $.each(fixed.assetCoordinates, function(index, value1) {
                    if (fixed.assetTypeName == "Site") {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }

                        var infoWindow = new google.maps.InfoWindow();

                        var iconUrl = "";

                        if (fixed.siteColor == "green") {
                            iconUrl = 'content/images/green_circle.png';
                        } else if (fixed.siteColor == "blue") {
                            iconUrl = 'content/images/blue_circle.png';
                        } else if (fixed.siteColor == "pink") {
                            iconUrl = 'content/images/pink_circle.png';
                        } else {
                            iconUrl = 'content/images/red_circle.png';
                        }
                        var iconSize = 8;
                        if (vm.zoomLevel == 10) {
                            iconSize = 8;
                        } else if (vm.zoomLevel == 11) {
                            iconSize = 16;
                        }

						if(fixed.assetTypeName == "Site"){
							var assetAV = fixed.assetTypeAttributeValues;
		                    assetAV.forEach(function(item, index) {
		                        if (item.assetTypeAttribute.name == "Link Status") {
									if(item.attributeValue == "Active"){
										iconUrl = 'content/images/green_circle.png';
									}else if(item.attributeValue == "Inactive"){
										iconUrl = 'content/images/red_circle.png';
									}
		                        }
		                    });
						}
                        var startMarker = new google.maps.Marker({

                            map: locations.map,
                            position: new google.maps.LatLng(
                                value1.latitude,
                                value1.longitude),
                            title: fixed.name,
                            icon: {
                                scaledSize: new google.maps.Size(
                                    iconSize, iconSize),
                                url: iconUrl,
                                anchor: new google.maps.Point(
                                    iconSize / 2, iconSize / 2),

                            }
                        });

                        if (vm.zoomLevel == 10) {
                            startMarker.setLabel('');
                        } else if (vm.zoomLevel == 11) {
                            var label = { color: '#000000', fontWeight: 'bold', fontSize: '12px', text: fixed.name };
                            startMarker.setLabel(label);
                        }

						

                        if (fixed.status != null &&
                            fixed.status === false) {
                            startMarker.setLabel({
                                color: 'red',
                                fontSize: '30px',
                                fontWeight: '20px',
                                text: '\u2297'
                            });
                        }
                        // Add marker to the array.
                        markers.push(startMarker);

                        var infoWindow2 = new google.maps.InfoWindow();
                        var infoStr = "";
                        var otdr = "";
                        /*var assetTypeAttributeValuesList = fixed.assetTypeAttributeValues;
                            assetTypeAttributeValuesList.sort(function(a, b) {
                                var nameA = a.name.toLowerCase(),
                                    nameB = b.name.toLowerCase()
                                if (nameA < nameB) // sort string ascending
                                    return -1
                                if (nameA > nameB)
                                    return 1
                                return 0 // default return value (no sorting)
                            });
                            for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
                                var home = assetTypeAttributeValuesList[i];
                                infoStr += '<br><b>' +
                                    home.name +
                                    '</b> : ' +
                                    home.attributeValue;
                                otdr = home;
                            }
*/
                        var userContent = document.createElement('div'),
                            button;

                        userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
                            '</div><div class="infoWindowContent">' +
                            /*'<b>User:</b> '*/
                            /*'<b>Vendor :</b> '*/
                            /*+
                            fixed.firstName +
                            '<br><b>Description :</b> ' +
                            fixed.description +
                            '<br>'+*/
                            '<b>Lat</b> :' +
                            fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
                            '<br><b>Long</b> :' +
                            fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
                            '<br>' + infoStr + '</div>';


                        google.maps.event.addListener(startMarker,
                            'dblclick',
                            function() {
                                infoWindow2.close();
                            });

                        var activeInfoWindow;
                        var infoWindow2 = new google.maps.InfoWindow({
                            content: userContent
                        });
                        // add listener on InfoWindow for click
                        // event
                        google.maps.event.addListener(startMarker, 'click', function() {
                            AssetByID.get(fixed.id, function(assetVal) {
//                                ChildAssetsType.query({
//                                    parentId: fixed.id
//                                }, onSuccess);
//
//                                function onSuccess(data, headers) {
                                    var midLatLng = {
                                        lat: fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude,
                                        lng: fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude
                                    };

                                    var infoStr = "";
                                    var assetStr = "";
									SiteAssetById.get(fixed.id, function(data) {
										var siteAssets = [];
										var siteAssetsData = Object.entries(data);
					                    siteAssetsData.forEach(function (value) {
					                        siteAssets.push({ name: value[0].toUpperCase(), count: parseFloat(value[1]) });
					                    });

	                                    var assetTypeAttributeValuesList = assetVal.assetTypeAttributeValues;
	                                    assetTypeAttributeValuesList.sort(function(a, b) {
	                                        var nameA = a.assetTypeAttribute.name.toLowerCase(),
	                                            nameB = b.assetTypeAttribute.name.toLowerCase()
	                                        if (nameA < nameB) // sort string ascending
	                                            return -1
	                                        if (nameA > nameB)
	                                            return 1
	                                        return 0 // default return value (no sorting)
	                                    });

										var siteChildCol = "";
										var siteChildRow = "";
										siteAssets.forEach(function(value){
											siteChildCol += '<th style="padding: 5px;text-align:center">'+value.name+'</th>';
											siteChildRow += '<td style="padding: 5px;text-align:center">' + value.count + '</td>';
										});
										var infotable = '<table border="1" style="width:100%;text-align:center"><tr>';
										infotable += siteChildCol;
										infotable += '</tr><tr>';
										infotable += siteChildRow;
										infotable +='</tr></table>';
	                                    for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
	                                        var home = assetTypeAttributeValuesList[i];
	                                        infoStr += '<br><b>' +
	                                            home.assetTypeAttribute.name +
	                                            '</b> : ' +
	                                            home.attributeValue;
	                                        otdr = home;
	                                    }
										siteAssets.forEach(function(assetVal){
											assetStr += '<br><b>' +
	                                            assetVal.name +
	                                            '</b> : ' +
	                                            assetVal.count;
										});
	
	                                    var infoWindowContent = '<div class="info_content">' +
	                                        '<b>' + fixed.name + '</b>' +
	                                        '<br><br>' + infotable +
	                                        '</div><div>' + infoStr + '</div>';
	
	                                    // Initialise the inforWindow
	                                    var infoWindow = new google.maps.InfoWindow({
	                                        content: infoWindowContent
	                                    });
	
	                                    if (prevInfoWindow != null) {
	                                        prevInfoWindow.close();
	                                    }
	
	                                    infoWindow.open(locations.map, startMarker);
	
	                                    activeInfoWindow = infoWindow2;
	                                    prevInfoWindow = infoWindow2;
									});
//                                }
                            });

                        });

                        openedInfoWindow = infoWindow2;
                    } else if (fixed.assetTypeName != "WDM" && fixed.assetTypeName != "Router" && fixed.assetTypeName != "NTTN" && fixed.assetTypeName != "Others" && fixed.assetTypeName != "BackToBack") {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }

                        var infoWindow = new google.maps.InfoWindow();


                        var iconUrl = "";
                        var isFixedAsset = false;
                        if (fixed.assetTypeName == "NTTN Site") {
                            iconUrl = 'content/images/black_circle.png';
                        } else {
                            iconUrl = fixed.assetTypeImagePath;
                            isFixedAsset = true;
                        }
                        var iconSize = 8;
                        if (vm.zoomLevel == 10) {
                            iconSize = 8;
                        } else if (vm.zoomLevel == 11) {
                            iconSize = 16;
                        }

                        if (isFixedAsset) {
                            iconSize = 24;
                        }

                        var startMarker = new google.maps.Marker({
                            map: locations.map,
                            position: new google.maps.LatLng(
                                value1.latitude,
                                value1.longitude),
                            title: fixed.name,
                            icon: {
                                scaledSize: new google.maps.Size(
                                    iconSize, iconSize),
                                url: iconUrl,
                                anchor: new google.maps.Point(
                                    iconSize / 2, iconSize / 2),
                            },
                        });

                        if (vm.zoomLevel == 10) {
                            startMarker.setLabel('');
                        } else if (vm.zoomLevel == 11) {
                            var label = { color: '#000000', fontWeight: 'bold', fontSize: '12px', text: fixed.name };
                            startMarker.setLabel(label);
                        }

                        if (fixed.status != null &&
                            fixed.status === false) {
                            startMarker.setLabel({
                                color: 'red',
                                fontSize: '30px',
                                fontWeight: '20px',
                                text: '\u2297'
                            });
                        }
                        // Add marker to the array.
                        markers.push(startMarker);

                        var infoWindow2 = new google.maps.InfoWindow();
                        var infoStr = "";
                        var otdr = "";
                        /*var assetTypeAttributeValuesList = fixed.assetTypeAttributeValues;
                        assetTypeAttributeValuesList.sort(function(a, b) {
                            var nameA = a.name.toLowerCase(),
                                nameB = b.name.toLowerCase()
                            if (nameA < nameB) // sort string ascending
                                return -1
                            if (nameA > nameB)
                                return 1
                            return 0 // default return value (no sorting)
                        });
                        for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
                            var home = assetTypeAttributeValuesList[i];
                            infoStr += '<br><b>' +
                                home.name +
                                '</b> : ' +
                                home.attributeValue;
                            otdr = home;
                        }*/

                        var userContent = document.createElement('div'),
                            button;

                        userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
                            '</div><div class="infoWindowContent">' +
                            '<b>Lat</b> :' +
                            fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
                            '<br><b>Long</b> :' +
                            fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
                            '<br>' + infoStr + '</div>';

                        google.maps.event.addListener(startMarker,
                            'dblclick',
                            function() {
                                infoWindow2.close();
                            });

                        var activeInfoWindow;
                        var infoWindow2 = new google.maps.InfoWindow({
                            content: userContent
                        });
                        // add listener on InfoWindow for click
                        // event
                        google.maps.event.addListener(startMarker, 'click', function() {
                            AssetByID.get(fixed.id, function(response) {
                                var infoWindow2 = new google.maps.InfoWindow();
                                var infoStr = "";
                                var otdr = "";
                                var assetTypeAttributeValuesList = response.assetTypeAttributeValues;
                                assetTypeAttributeValuesList.sort(function(a, b) {
                                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                        nameB = a.assetTypeAttribute.name.toLowerCase()
                                    if (nameA < nameB) // sort string ascending
                                        return -1
                                    if (nameA > nameB)
                                        return 1
                                    return 0 // default return value (no sorting)
                                });
                                for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
                                    var home = assetTypeAttributeValuesList[i];
                                    infoStr += '<br><b>' +
                                        home.assetTypeAttribute.name +
                                        '</b> : ' +
                                        home.attributeValue;
                                    otdr = home;
                                }

                                var userContent = document.createElement('div'),
                                    buttonA, buttonB, buttonC, buttonD;
                                userContent.innerHTML = '<div class="infoWindowhead">' + startMarker.title +
                                    '</div><div class="infoWindowContent">' +
                                    /* '<b>User:</b> '*/
                                    /*'<b>Vendor :</b> '*/
                                    /*+
                                    fixed.firstName +
                                    '<br><b>Description :</b> ' +
                                    fixed.description +
                                    '<br>'*/
                                    '<b>Lat</b> :' +
                                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1].latitude +
                                    '<br><b>Long</b> :' +
                                    fixed.assetCoordinates[fixed.assetCoordinates.length - 1].longitude +
                                    '<br>' + infoStr + '</div>';

                                if (response.ownedBy.length > 0) {
                                    buttonA = userContent.appendChild(document.createElement('input'));
                                    buttonA.type = 'buttonA';
                                    buttonA.value = 'Backward Direction';
                                    buttonA.className = "buttonA";
                                    // buttonA.style.width = '100px';
                                    buttonA.style.textAlign = 'left';
                                    buttonA.style.color = '#ffffff'
                                    buttonA.style.backgroundColor = '#005fae';

                                    google.maps.event.addDomListener(buttonA, 'click', function() {
                                        vm.selectEditAssetType = false;;
                                        $("#assetPathModal").show();
                                        $("#AssetEditText").html("Asset Name : " + fixed.name);
                                        backwardPath(fixed);
                                    });
                                }

                                ChildAssetsType.query({
                                    parentId: response.id
                                }, onSuccess);

                                function onSuccess(data, headers) {
                                    if (data.length > 0) {
                                        buttonB = userContent.appendChild(document.createElement('input'));
                                        buttonB.type = 'buttonB';
                                        buttonB.value = 'Forward Direction';
                                        buttonB.className = "buttonB";
                                        // buttonB.style.width = '100px';
                                        buttonB.style.textAlign = 'left';
                                        buttonB.style.color = '#ffffff'
                                        buttonB.style.backgroundColor = '#005fae';

                                        google.maps.event.addDomListener(buttonB, 'click', function() {
                                            vm.selectEditAssetType = false;;
                                            $("#assetPathModal").show();
                                            $("#AssetEditText").html("Asset Name : " + fixed.name);
                                            forwardPath(fixed);
                                        });
                                    }

                                }

                                buttonC = userContent.appendChild(document.createElement('input'));
                                buttonC.type = 'buttonC';
                                buttonC.value = 'Edit';
                                buttonC.className = "buttonC";
                                // buttonB.style.width = '100px';
                                buttonC.style.textAlign = 'left';
                                buttonC.style.color = '#ffffff'
                                buttonC.style.backgroundColor = '#005fae';

                                google.maps.event.addDomListener(buttonC, 'click', function() {
                                    if (fixed.assetTypeName == "Joiner") {
                                        $("#userEditModel").show();
                                        $("#userEditText").html("Asset Name : " + fixed.name);
                                        vm.fiberTubeData = [];
										vm.fiberSlotData = [];
										vm.fiberRibbonData = [];
                                        vm.fiberCoreData = [];
                                        vm.selectedCable = null;
                                        vm.selectedTube = null;
                                        vm.selectedCore = null;
                                        editJoinerPath(fixed);
                                    } else if (fixed.assetTypeName == "Splitter") {
                                        $("#splitterEditModel").show();
                                        $("#splitterEditText").html("Asset Name : " + fixed.name);
                                        vm.splitterCoreData = [];
                                        vm.splitterCore = null;
                                        editSplitter(fixed);
                                    }
                                });

                                if (fixed.assetTypeName == "Joiner") {
                                    buttonD = userContent.appendChild(document.createElement('input'));
                                    buttonD.type = 'buttonD';
                                    buttonD.value = 'Create';
                                    buttonD.className = "buttonD";
                                    buttonD.style.textAlign = 'left';
                                    buttonD.style.color = '#ffffff'
                                    buttonD.style.backgroundColor = '#005fae';
                                    google.maps.event.addDomListener(buttonD, 'click', function() {
                                        AssetByID.get(fixed.id, function(fixed) {
                                            $("#userEditModel").show();
                                            $("#userEditText").html("Asset Name : " + fixed.ownedBy[0].name);
                                            vm.fiberTubeData = [];
											vm.fiberSlotData = [];
											vm.fiberRibbonData = [];
                                            vm.fiberCoreData = [];
                                            vm.selectedSplitterCore = null;
                                            vm.selectedCable = null;
                                            vm.selectedTube = null;
                                            vm.selectedCore = null;

                                            AssetByID.get(fixed.ownedBy[0].id, function(assetVal) {
                                                vm.selectedCable = assetVal;
                                                $scope.drawPath(0);
                                            });
                                        });
                                    });

                                }


                                var infoWindow2 = new google.maps.InfoWindow({
                                    content: userContent
                                });
                                if (prevInfoWindow != null)
                                    prevInfoWindow.close();
                                // Open InfoWindow - on
                                // click
                                infoWindow2.open(locations.map, startMarker);
                                setTimeout(function () { infoWindow2.close(); }, 5000);
                                
                                activeInfoWindow = infoWindow2;
                                prevInfoWindow = infoWindow2;
                            });

                        });
                        openedInfoWindow = infoWindow2;
                    }
                });
            }

            function forwardPath(assetVal) {

                var map = new google.maps.Map(document.getElementById('map_canvas_asset_path'), {
                    center: new google.maps.LatLng(assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude, assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude),
                    zoom: 15
                });

                AssetByID.get(assetVal.id, function(response) {
                    assetVal = response;
                    createMarkerOfAllAssets(assetVal, map);
                    ChildAssets.query({
                        parentId: assetVal.id
                    }, onSuccess);

                    function onSuccess(data, headers) {
                        data.forEach(function(childAsset) {
                            if (childAsset.assetType.layout == "SPREAD") {
                                var assetCoordinateValues = childAsset.assetCoordinates;
                                var myLatLng = [];
                                for (var j = 0; j < assetCoordinateValues.length; j++) {
                                    myLatLng.push({
                                        lat: assetCoordinateValues[j].latitude,
                                        lng: assetCoordinateValues[j].longitude
                                    });
                                }
                                if (myLatLng.length > 0) {
                                    var midLatLng = {
                                        lat: myLatLng[myLatLng.length - 1].lat,
                                        lng: myLatLng[myLatLng.length - 1].lng
                                    };
                                    var infowindow = new google.maps.InfoWindow({
                                        content: '<div class="infoWindowhead">' +
                                            "name" + childAsset.name +
                                            '<div class="infoWindowContent"> <b>Description :</b> ' +
                                            childAsset.description + '</div></div>'
                                    });
                                    infowindow.setPosition(midLatLng);
                                    var ColorValue = childAsset.assetType.colorcode;
                                    var polylinepath = new google.maps.Polyline({
                                        path: myLatLng,
                                        geodesic: true,
                                        strokeColor: ColorValue,
                                        // strokeColor: childAsset.assetType.colorcode,
                                        strokeOpacity: 1.0,
                                        strokeWeight: 2.5,
                                        fillColor: ColorValue,
                                        fillOpacity: 0.3,
                                        editable: false
                                    });

                                    polylinepath.setOptions({
                                        strokeColor: ColorValue
                                    });

                                    polylinepath.setMap(map);

                                    google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                    });

                                    google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
                                        if (openedInfoWindow != null) {
                                            openedInfoWindow.close();
                                        }
                                        var infowindow = new google.maps.InfoWindow({
                                            content: '<div class="infoWindowhead">' +
                                                "name" + childAsset.name + '</div>'
                                        });
                                        infowindow.setPosition(event.latLng);
                                        infowindow.open(map);
                                        openedInfoWindow = infowindow;
                                    });
                                }

                                ChildAssets.query({
                                    parentId: childAsset.id
                                }, onSuccess1);

                                function onSuccess1(data, headers) {
                                    data.forEach(function(value) {
                                        createMarkerOfAllAssets(value, map);
                                    });
                                }
                            }
                        });

                    }
                });
            }

            function backwardPath(assetVal) {

                var map = new google.maps.Map(document.getElementById('map_canvas_asset_path'), {
                    center: new google.maps.LatLng(assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude, assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude),
                    zoom: 15
                });

                AssetByID.get(assetVal.id, function(response) {
                    assetVal = response;
                    createMarkerOfAllAssets(assetVal, map);
                    var parentAssets = assetVal.ownedBy;
                    if (parentAssets.length > 0) {
                        parentAssets.forEach(function(parentAsset) {
                            var assetCoordinateValues = parentAsset.assetCoordinates;
                            var myLatLng = [];
                            for (var j = 0; j < assetCoordinateValues.length; j++) {
                                myLatLng.push({
                                    lat: assetCoordinateValues[j].latitude,
                                    lng: assetCoordinateValues[j].longitude
                                });
                            }
                            if (myLatLng.length > 0) {
                                var midLatLng = {
                                    lat: myLatLng[myLatLng.length - 1].lat,
                                    lng: myLatLng[myLatLng.length - 1].lng
                                };
                                var infowindow = new google.maps.InfoWindow({
                                    content: '<div class="infoWindowhead">' +
                                        "name" + parentAsset.name +
                                        '<div class="infoWindowContent"> <b>Description :</b> ' +
                                        parentAsset.description + '</div></div>'
                                });
                                infowindow.setPosition(midLatLng);
                                var ColorValue = 'blue';
                                var polylinepath = new google.maps.Polyline({
                                    path: myLatLng,
                                    geodesic: true,
                                    strokeColor: ColorValue,
                                    strokeOpacity: 1.0,
                                    strokeWeight: 2.5,
                                    fillColor: ColorValue,
                                    fillOpacity: 0.3,
                                    editable: false
                                });

                                polylinepath.setOptions({
                                    strokeColor: ColorValue
                                });

                                polylinepath.setMap(map);

                                google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
                                    if (openedInfoWindow != null) {
                                        openedInfoWindow.close();
                                    }
                                });

                                google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
                                    if (openedInfoWindow != null) {
                                        openedInfoWindow.close();
                                    }
                                    var infowindow = new google.maps.InfoWindow({
                                        content: '<div class="infoWindowhead">' +
                                            "name" + parentAsset.name + '</div>'
                                    });
                                    infowindow.setPosition(event.latLng);
                                    infowindow.open(map);
                                    openedInfoWindow = infowindow;
                                });
                            }
                        });
                    }
                });
            }

            function showFiberDetails(parentValue, data, lat, lng, latlngs, assetImgPath) {
				 $scope.showSelectColor = false;
				$scope.tubeName = "";
				if(parentValue.assetType.name == "432FR"){
					vm.fiberTubeData = [];
					vm.fiberSlotData = [];
					vm.fiberRibbonData = [];
					vm.fiberCoreData = [];
                    var index = 1;
                    data.forEach(function(slotData) {
                        slotData.img = "Tube" + index;
                        index = index +1;
                        vm.fiberSlotData.push(slotData);
                    });
				}else{
					vm.fiberTubeData = [];
					vm.fiberSlotData = [];
					vm.fiberRibbonData = [];
					vm.fiberCoreData = [];
                    var index = 1;
                    data.forEach(function(tubeData) {
                        tubeData.img = "Tube" + index;
                        index++;
						if(index == 13){
							index = 1;
						}
                        vm.fiberTubeData.push(tubeData);
                    });
				}
               
                var ele = document.getElementById("cablePathModal");
                $(ele).modal('show');
            }


            function createMarkerOfAllAssets(assetVal, map) {
                var assetLatLngs = ({
                    lat: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude,
                    lng: assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude
                });
                var marker = new google.maps.Marker({
                    position: assetLatLngs,
                    map: map,
                    icon: {
                        scaledSize: new google.maps.Size(
                            24, 24),
                        origin: new google.maps.Point(0, 0),
                        // url: 'content/images/yellow.png',
                        url: assetVal.assetType.imagePath,
                        anchor: new google.maps.Point(0, 0)
                    },
                });

                google.maps.event.addListener(marker, 'click', function() {
                    AssetByID.get(assetVal.id, function(assetVal) {
                        var infoWindow2 = new google.maps.InfoWindow();
                        var infoStr = "";
                        var assetTypeAttributeValuesList = assetVal.assetTypeAttributeValues;
                        assetTypeAttributeValuesList.sort(function(a, b) {
                            var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                nameB = a.assetTypeAttribute.name.toLowerCase()
                            if (nameA < nameB) // sort string ascending
                                return -1
                            if (nameA > nameB)
                                return 1
                            return 0 // default return value (no sorting)
                        });
                        for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
                            var home = assetTypeAttributeValuesList[i];
                            infoStr += '<br><b>' +
                                home.assetTypeAttribute.name +
                                '</b> : ' +
                                home.attributeValue;
                        }

                        var userContent = document.createElement('div');
                        userContent.innerHTML = '<div class="infoWindowhead">' + assetVal.name +
                            '</div><div class="infoWindowContent">' +
                            '<b>Lat</b> :' +
                            assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].latitude +
                            '<br><b>Long</b> :' +
                            assetVal.assetCoordinates[assetVal.assetCoordinates.length - 1].longitude +
                            '<br>' + infoStr + '</div>';

                        var infoWindow2 = new google.maps.InfoWindow({
                            content: userContent
                        });
                        if (prevInfoWindow != null)
                            prevInfoWindow.close();

                        infoWindow2.open(locations.map, marker);

                        prevInfoWindow = infoWindow2;
                    });

                });
            }

            function drawPolyline(spread, map) {
				
                var spreadAssetCoordinates = [];
                $.each(spread.assetCoordinates, function(index, value) {

                    spreadAssetCoordinates.push({
                        lat: value.latitude,
                        lng: value.longitude
                    });
                });
                if (spreadAssetCoordinates.length > 1) {
                    var midLatLng = {
                        lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
                        lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
                    };

                    var polylineColor = '';
                    var distanceFromPOP = '';
                    var fiberCoreVal = '';



//                    var infowindow = new google.maps.InfoWindow({
//                        content: '<div class="infoWindowhead">' +
//                            spread.name +
//                            '</div>'
//                    });

 					var infowindow = null;
                    if (spread.assetTypeLayout == "SPREAD") {
                        //polylineColor = '#B22222';
                       // polylineColor = spread.assetColor;
                       //Modified 
                       polylineColor = spread.assetTypeColor
                    }

//                    infowindow.setPosition(midLatLng);
                    var polylineWeight = 2.5;
                    var strokeOpacityVal = 1.0;
                    
                    if(spread.assetTypeName == "Duct" || spread.assetTypeName == "Duct1" || spread.assetTypeName == "Duct2" || spread.assetTypeName == "Duct3" 
                       || spread.assetTypeName == "Duct4" || spread.assetTypeName == "Duct5" || spread.assetTypeName == "Duct6"){
                        polylineWeight = 3.5;
                        strokeOpacityVal = 0.4;

						var assetAV = spread.assetTypeAttributeValues;
	                    assetAV.forEach(function(item, index) {
	                        if (item.assetTypeAttribute.name == "Color") {
	                            polylineColor = item.attributeValue;
	                        }
	                    });
                    }

					if(spread.assetTypeName == "Link"){
						var assetAV = spread.assetTypeAttributeValues;
	                    assetAV.forEach(function(item, index) {
	                        if (item.assetTypeAttribute.name == "Link Status") {
								if(item.attributeValue == "Active"){
									polylineColor = "#008000";
								}else if(item.attributeValue == "Inactive"){
									polylineColor = "#FF0000";
								}
	                        }
	                    });
					}
					
                    var polylinepath = new google.maps.Polyline({
                        path: spreadAssetCoordinates,
                        geodesic: true,
                        strokeColor: polylineColor,
                        strokeOpacity: strokeOpacityVal,
                        strokeWeight: polylineWeight,
                        fillColor: polylineColor,
                        fillOpacity: 0.3,
                        editable: false
                    });

//                    google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
//                        infowindow.close();
//                    });

                    google.maps.event.addListener(polylinepath, 'click', function(event) {
                        if (openedInfoWindow != null) {
                            openedInfoWindow.close();
                        }
                        var disVal = 0;
                        var cLocations = [];
                        vm.multiassets.forEach(function(item, index) {
                            if (spread.id == vm.multiassets[index].id) {
                                // cLocations = vm.multiassets[index].assetCoordinates;
                            }
                        });
						
						if(spread.description == null || spread.description == undefined){
							spread.description = "";
						}

                        if (spread.assetTypeLayout == "SPREAD") {

                            AssetByID.get(spread.id, function(assetVal) {
                                spread = assetVal;
                                var infoStr = "";
                                var assetTypeAttributeValuesList = spread.assetTypeAttributeValues;
                                assetTypeAttributeValuesList.sort(function(a, b) {
                                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                                        nameB = b.assetTypeAttribute.name.toLowerCase()
                                    if (nameA < nameB) // sort string ascending
                                        return -1
                                    if (nameA > nameB)
                                        return 1
                                    return 0 // default return value (no sorting)
                                });
                                for (var i = 0; i < assetTypeAttributeValuesList.length; i++) {
                                    var home = assetTypeAttributeValuesList[i];

                                    var attrVal = "";
                                    if (home.attributeValue != null) {
                                        attrVal = home.attributeValue;
                                    }
                                    infoStr += '<br><b>' +
                                        home.assetTypeAttribute.name +
                                        '</b> : ' +
                                        attrVal;
                                }
                                var spreadCoordinates = spread.assetCoordinates;
                                if (spreadCoordinates.length > 0) {
                                    for (var index in spreadCoordinates) {
                                        if (index > 0) {
                                            disVal += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
                                        }
                                    }
                                }
                                // var userContent = document.createElement('div');

                                var userContent = document.createElement('div'),
                                    button;

                                userContent.innerHTML = '<div class="infoWindowhead">' +
                                    '<b>Name : </b> ' + spread.name + '</br>' +
                                    '<b>Distance : </b> ' + (parseFloat(disVal)).toFixed(2) + ' Km</br>' +
                                    //'<b>User : </b> ' + spread.firstName + " " + spread.lastName + '</br>' +
                                    '</div><div>' + infoStr + '</div><br><div><b>Fiber Details : </b></div>';
                                /*'<b>Vendor : </b> '*/
								if(spread.assetType.name == "Duct"){
									
									ChildAssets.query({
                                        parentId: spread.id
                                    }, onSuccess);

                                    function onSuccess(assetVal, headers) {
										assetVal.forEach(function(assetChildVal, index){
											button = userContent.appendChild(document.createElement('input'));
			                                button.type = 'button';
			                                button.value = (index+1)+ ". " + assetChildVal.name;
			                                button.className = "button";
			                                button.style.width = '500px';
			                                button.style.textAlign = 'left';
			
			                                infowindow = new google.maps.InfoWindow({
			                                    content: userContent
			                                });
			
			                                google.maps.event.addDomListener(button, 'click', function() {
												ChildAssets.query({
			                                        parentId: assetChildVal.id
			                                    }, onSuccess);
			
			                                    function onSuccess(data, headers) {
			                                        if (assetChildVal.name == "432FR") {
			                                            $("#ribbonPathModal").show();
			                                            try {
			                                                var assetCoordinateValues = spread.assetCoordinates;
			                                                latlngs.push({
			                                                    lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
			                                                    lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude
			                                                });
			                                            } catch (err) {
			                                                var event232 = err;
			                                            }
			                                            showFiberDetails(assetChildVal, data, assetChildVal.assetCoordinates[assetChildVal.assetCoordinates.length - 1].latitude, assetChildVal.assetCoordinates[assetChildVal.assetCoordinates.length - 1].longitude, latlngs);
			                                        } else {
			                                            $("#cablePathModal").show();
			                                            try {
			                                                var assetCoordinateValues = spread.assetCoordinates;
			                                                latlngs.push({
			                                                    lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
			                                                    lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude
			                                                });
			                                            } catch (err) {
			                                                var event232 = err;
			                                            }
			                                            showFiberDetails(assetChildVal, data, assetChildVal.assetCoordinates[assetChildVal.assetCoordinates.length - 1].latitude, assetChildVal.assetCoordinates[assetChildVal.assetCoordinates.length - 1].longitude, latlngs);
			                                        }
			
			                                    }
			                                });
										});
									}
									
									 
								}else{
	                                button = userContent.appendChild(document.createElement('input'));
	                                button.type = 'button';
	                                button.value = 'Edit';
	                                button.className = "button";
	                                button.style.width = '300px';
	                                button.style.textAlign = 'right';
	
	                                infowindow = new google.maps.InfoWindow({
	                                    content: userContent
	                                });
	
	                                google.maps.event.addDomListener(button, 'click', function() {
	
	                                    ChildAssets.query({
	                                        parentId: spread.id
	                                    }, onSuccess);
	
	                                    function onSuccess(data, headers) {
	                                        if (spread.assetType.name == "432FR") {
	                                            $("#ribbonPathModal").show();
	                                            try {
	                                                var assetCoordinateValues = spread.assetCoordinates;
	                                                latlngs.push({
	                                                    lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
	                                                    lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude
	                                                });
	                                            } catch (err) {
	                                                var event232 = err;
	                                            }
	                                            showFiberDetails(spread, data, spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude, spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude, latlngs);
	                                        } else {
	                                            $("#cablePathModal").show();
	                                            try {
	                                                var assetCoordinateValues = spread.assetCoordinates;
	                                                latlngs.push({
	                                                    lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
	                                                    lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude
	                                                });
	                                            } catch (err) {
	                                                var event232 = err;
	                                            }
	                                            showFiberDetails(spread, data, spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude, spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude, latlngs);
	                                        }
	
	                                    }
	                                });
								}
                            });
                        }
						if(infowindow != null){
							infowindow.setPosition(event.latLng);
                        	infowindow.open(locations.map);
                        	openedInfoWindow = infowindow;	
						}
                        
                    });

                    if (spread.status != null && spread.status === false) {
                        polylinepath.setOptions({
                            strokeColor: polylineColor
                        });
                    }
					var hasDuct = false;
					if(spread.assetOwner != null){
						 if(spread.assetOwner.length > 0){
							spread.assetOwner.forEach(function(ownerVal){
								if(ownerVal.assetType != null){
									if(ownerVal.assetType.name != null && ownerVal.assetType.name == "Duct"){
										hasDuct = true;
									}
								}else{
									AssetByID.get(ownerVal.id, function(ownerVal) {
										if(ownerVal.assetType.name != null && ownerVal.assetType.name == "Duct"){
											hasDuct = true;
										}
									});
								}
							});
						}
					}
					if(!hasDuct){
						
						var polylinepathDuct = new google.maps.Polyline({
	                        path: spreadAssetCoordinates,
	                        geodesic: true,
	                        strokeColor: polylineColor,
	                        strokeOpacity: 1,
	                        strokeWeight: 2,
	                        fillColor: polylineColor,
	                        fillOpacity: 0.3,
	                        editable: false
	                    });

						polylinepath.setMap(locations.map);

                    	polylines.push(polylinepath);
						
//						polylinepathDuct.setMap(locations.map);
//
//                    	polylines.push(polylinepathDuct);
					}
                    
                }
            }
        }

        var recursiveOwnedByPopName = function(childVal, multiassets) {
            var popNameVal = "";
            var child = childVal[0];
            multiassets.forEach(function(item, index) {
                if (child.id == multiassets[index].id) {
                    child = multiassets[index];
                }
            });
            var parentVal = child.ownedBy;

            if (parentVal != null) {
                if (parentVal.length > 0) {
                    popNameVal = recursiveOwnedByPopName(parentVal, multiassets);
                } else {
                    popNameVal = child.name;
                }
            } else {
                popNameVal = child.name;
            }
            return popNameVal;
        }

        function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return (dist);
            }
        }

        function moveToLocation(lat, lng, map) {
            var center = new google.maps.LatLng(lat, lng);
            map.panTo(center);
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        var greenPolylineLatLng = [];
        var redPolylineLatLng = [];
        var latlngs = [];
        var redLatLngs = [];
        $(document).ready(function() {
            var map = null;
            var myLatlng;

            $('#mapModalForFiberifying').on('show.bs.modal', function(event) {

                var fiberDefectLength = parseFloat(document.getElementById("distanceValue").value).toFixed(2);
                var button = $(event.relatedTarget);
                var child;
                var parent;

                var multiassets = vm.multiassets;
                latlngs = [];
                redLatLngs = [];
                var isValidLength = true;

                for (var i = 0; i < multiassets.length; i++) {
                    if (multiassets[i].id == selectedFromAssetMap) {
                        if (isFromAssetMapSelected) {
                            var parentCableLengthFromToAsset = 0.0;
                            child = multiassets[i];
                            if (isNaN(fiberDefectLength)) {
                                alert("Enter Value");
                                child = null;
                            }
                            for (var j = 0; j < multiassets.length; j++) {
                                if (multiassets[j].id == selectedToAssetMap) {
                                    var childAVToAsset = multiassets[j].assetTypeAttributeValues;

                                    childAVToAsset.forEach(function(item, index) {
                                        if (item.name == "Cable length") {
                                            parentCableLengthFromToAsset = parseFloat(item.attributeValue);
                                        }
                                    });
                                }
                            }
                            if (child != null) {
                                var childAV;
                                var parentCableLength = 0.0;
                                multiassets.forEach(function(item, index) {
                                    if (child.id == multiassets[index].id) {
                                        childAV = multiassets[index].assetTypeAttributeValues;
                                    }
                                });
                                childAV.forEach(function(item, index) {
                                    if (item.name == "Cable length") {
                                        parentCableLength = parseFloat(item.attributeValue);
                                    }
                                });
                                if (fiberDefectLength > (parentCableLength - parentCableLengthFromToAsset) || fiberDefectLength < 0) {
                                    alert("Please enter a valid length");
                                    isValidLength = false;
                                }
                            }
                            vm.generatedAssetName = child.name;
                            latlngs.push({
                                lat: child.lat,
                                lng: child.lng,
                                name: child.name
                            });
                            redLatLngs.push({
                                lat: child.lat,
                                lng: child.lng,
                                name: child.name
                            });

                            myLatlng = new google.maps.LatLng(child.lat, child.lng);
                            var myOptions = {
                                zoom: 18,
                                zoomControl: true,
                                center: myLatlng,
                                mapTypeId: google.maps.MapTypeId.ROADMAP
                            };
                            map = new google.maps.Map(document.getElementById("map_canvas_for_fiber_tracking"), myOptions);

                        }
                    }
                }
                greenPolylineLatLng = [];
                if (child == null) {
                    alert("No asset selected");
                }
                if (!isValidLength) {
                    child = null;
                }
                parent = child.ownedBy;
                if (parent.length > 0) {
                    if (parent[0].id != selectedToAssetMap) {
                        getParentChildCalVal(child, parent[0], multiassets, fiberDefectLength);
                        recursiveOwnedByAssetAttribute(parent, multiassets, fiberDefectLength);
                    } else {
                        getParentChildCalVal(child, parent[0], multiassets, fiberDefectLength);
                    }
                }

                for (var i = 0; i < latlngs.length; i++) {
                    createMarker(latlngs[i], map, i, "green");
                }

                for (var i = 0; i < redLatLngs.length; i++) {
                    createMarker(redLatLngs[i], map, i, "red");
                }


                $("#location-map").css("width", "100%");
                $("#map_canvas").css("width", "100%");
            });

            var recursiveOwnedByAssetAttribute = function(childVal, multiassets, fiberDefectLength) {
                var child = childVal[0];
                multiassets.forEach(function(item, index) {
                    if (child.id == multiassets[index].id) {
                        child = multiassets[index];
                    }
                });
                var parentVal = child.ownedBy;

                if (parentVal != null) {
                    if (parentVal.length > 0) {
                        getParentChildCalVal(child, parentVal[0], multiassets, fiberDefectLength);
                        recursiveOwnedByAssetAttribute(parentVal, multiassets, fiberDefectLength);
                    }
                }
            };

            // Trigger map resize event after modal shown
            $('#mapModalForFiberifying').on('shown.bs.modal', function() {
                google.maps.event.trigger(map, "resize");
            });

            var recursiveOwnedByAssetsVal = function(childVal, multiassets) {
                var child = childVal[0];
                multiassets.forEach(function(item, index) {
                    if (child.id == multiassets[index].id) {
                        child = multiassets[index];
                    }
                });
                var parentVal = child.ownedBy;

                if (parentVal != null) {
                    if (parentVal.length > 0) {
                        if (selectedToAsset != parentVal[0].id) {
                            parentAssetList.push(recursiveOwnedByAssetsVal(parentVal, multiassets));
                        } else {
                            multiassets.forEach(function(item, index) {
                                if (parentVal[0].id == multiassets[index].id) {
                                    parentAssetList.push(multiassets[index]);
                                }
                            });
                        }
                    }
                }
                return child;
            };



            $('#mapModalForFiberifyingAsset').on('show.bs.modal', function(event) {
                var child;
                var parent;
                parentAssetList = [];
                var PolylineLatLng = [];


                var multiassets = vm.multiassets;
                latlngs = [];
                redLatLngs = [];
                var isValidLength = true;

                for (var i = 0; i < multiassets.length; i++) {
                    if (multiassets[i].id == selectedFromAsset) {
                        if (isFromAssetSelected) {
                            child = multiassets[i];
                            if (child != null) {
                                var childAV;
                                var parentCableLength = 0.0;
                                multiassets.forEach(function(item, index) {
                                    if (child.id == multiassets[index].id) {
                                        childAV = multiassets[index].assetTypeAttributeValues;
                                    }
                                });
                            }


                            myLatlng = new google.maps.LatLng(child.lat, child.lng);
                            var myOptions = {
                                zoom: 16,
                                zoomControl: true,
                                center: myLatlng,
                                mapTypeId: google.maps.MapTypeId.ROADMAP
                            };
                            map = new google.maps.Map(document.getElementById("map_canvas_for_fiber_tracking_asset"), myOptions);

                        }
                    }
                }
                $("#assetDistanceVal").html("Distance : " + assetFromToDistance.toFixed(2));
                if (child == null) {
                    alert("No from asset selected");
                }
                if (!isFromAssetSelected) {
                    child = null;
                }

                parent = child.ownedBy;
                if (!isToAssetSelected) {
                    parent = null;
                    alert("No to asset selected");
                }
                if (parent.length > 0) {
                    if (selectedToAsset != parent[0].id) {
                        parentAssetList.push(recursiveOwnedByAssetsVal(parent, multiassets));
                    } else {
                        multiassets.forEach(function(item, index) {
                            if (parent[0].id == multiassets[index].id) {
                                parentAssetList.push(multiassets[index]);
                            }
                        });
                    }
                }
                parentAssetList.push(child);

                for (var i = 0; i < parentAssetList.length; i++) {
                    createMarker(parentAssetList[i], map, i, "icon");
                    if (i > 0) {
                        var parentAC = parentAssetList[i].assetCoordinates;
                        if (parentAC.length > 1) {
                            for (var iterable_element in parentAC) {
                                PolylineLatLng.push({
                                    lat: parentAC[iterable_element].latitude,
                                    lng: parentAC[iterable_element].longitude,
                                    name: parentAssetList[i].name
                                });
                            }
                        } else {
                            PolylineLatLng.push({
                                lat: parentAssetList[i].lat,
                                lng: parentAssetList[i].lng,
                                name: parentAssetList[i].name
                            });
                        }
                    }
                }
                for (var i = 0; i < PolylineLatLng.length; i++) {
                    if (i > 0) {
                        var locValues = [];
                        locValues.push(PolylineLatLng[i - 1]);
                        locValues.push(PolylineLatLng[i]);
                        drawPolylineForGeneratedLoc(locValues, map, "black");
                    }
                }
                $("#location-map-asset").css("width", "100%");
                $("#map_canvas").css("width", "100%");
            });

            function getParentChildCalVal(child, parent, multiassets, fiberDefectLength) {

                var childAV;
                var parentAV;
                var parentAC;
                var parentDistance = 0.0;
                var childDistance = 0.0;

                var parentCableLength = 0.0;
                var childCableLength = 0.0;
                var parentDistanceToAsset = 0.0;
                var parentCableLengthToAsset = 0.0;

                latlngs.push({
                    lat: parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude,
                    lng: parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude,
                    name: parent.name
                });
                multiassets.forEach(function(item, index) {
                    if (parent.id == multiassets[index].id) {
                        parentAV = multiassets[index].assetTypeAttributeValues;
                        parentAC = multiassets[index].assetCoordinates;
                    }
                });

                multiassets.forEach(function(item, index) {
                    if (child.id == multiassets[index].id) {
                        childAV = multiassets[index].assetTypeAttributeValues;
                    }
                });

                if (parent.id != selectedToAssetMap) {
                    parentAV.forEach(function(item, index) {
                        if (item.name == "Distance") {
                            parentDistance = parseFloat(item.attributeValue);
                        }

                        if (item.name == "Cable length") {
                            parentCableLength = parseFloat(item.attributeValue);
                        }

                    });
                } else {
                    parentAV.forEach(function(item, index) {
                        if (item.name == "Distance") {
                            parentDistanceToAsset = parseFloat(item.attributeValue);
                        }

                        if (item.name == "Cable length") {
                            parentCableLengthToAsset = parseFloat(item.attributeValue);
                        }

                    });
                }

                childAV.forEach(function(item, index) {
                    if (item.name == "Distance") {
                        childDistance = parseFloat(item.attributeValue);
                    }

                    if (item.name == "Cable length") {
                        childCableLength = parseFloat(item.attributeValue);
                    }
                });

                var cableDiff = parseFloat(childCableLength) - parseFloat(parentCableLength);
                var distanceDiff = parseFloat(childDistance) - parseFloat(parentDistance);
                if (parseFloat(fiberDefectLength) >= parseFloat(parentCableLength)) {
                    greenPolylineLatLng = [];
                    if (child.assetCoordinates.length > 1) {
                        for (var iterable_element in parentAC) {
                            greenPolylineLatLng.push({
                                lat: parentAC[iterable_element].latitude,
                                lng: parentAC[iterable_element].longitude,
                                name: parent.name
                            });
                        }
                    } else {
                        greenPolylineLatLng.push({
                            lat: child.assetCoordinates[child.assetCoordinates.length - 1].latitude,
                            lng: child.assetCoordinates[child.assetCoordinates.length - 1].longitude,
                            name: child.name
                        });
                        if (parentAC.length > 0) {
                            greenPolylineLatLng.push({
                                lat: parentAC[parentAC.length - 1].latitude,
                                lng: parentAC[parentAC.length - 1].longitude,
                                name: parent.name
                            });
                        }
                    }

                    for (var i = 0; i < greenPolylineLatLng.length; i++) {
                        if (i > 0) {
                            var locValues = [];
                            locValues.push(greenPolylineLatLng[i - 1]);
                            locValues.push(greenPolylineLatLng[i]);
                            drawPolylineForGeneratedLoc(locValues, map, "green");
                        }
                    }
                    if (parseFloat(fiberDefectLength) <= parseFloat(childCableLength)) {
                        var issueCableDiff = parseFloat(fiberDefectLength) - parseFloat(parentCableLength);
                        var leftLoopLength = 0.0;
                        var rightLoopLength = 0.0;

                        childAV.forEach(function(item, index) {
                            if (item.name == "Left Loop Length") {
                                leftLoopLength = parseFloat(item.attributeValue);
                            } else if (item.name == "Right Loop Length") {
                                rightLoopLength = parseFloat(item.attributeValue);
                            }
                        });

                        var prevOTDR = parseFloat(childCableLength) - cableDiff;
                        var leftFiberCutDistance = parseFloat(fiberDefectLength) - leftLoopLength;
                        var issueDistance = 0.0;
                        if (leftFiberCutDistance <= (childCableLength - cableDiff)) {
                            issueDistance = 0.0;
                        } else if (parseFloat(fiberDefectLength) > (parseFloat(childCableLength) - rightLoopLength)) {
                            issueDistance = distanceDiff - 0.2;
                        } else {
                            var percentage = ((issueCableDiff - leftLoopLength) * 100) / (cableDiff - (leftLoopLength + rightLoopLength));
                            issueDistance = (distanceDiff / 100) * parseFloat(percentage);
                        }

                        var dis = 0.0;
                        var cLocations = child.assetCoordinates;
                        var isDistanceValid = false;

                        if (cLocations.length > 1) {
                            redPolylineLatLng = [];
                            greenPolylineLatLng = [];
                            for (var index in cLocations) {
                                var prevDis = dis * 1000;
                                var disBetweenPoints = 0.0;
                                if (index >= 1) {
                                    dis += distanceCal(cLocations[index].latitude, cLocations[index].longitude, cLocations[index - 1].latitude, cLocations[index - 1].longitude);
                                } else if (index == 0) {
                                    dis += distanceCal(cLocations[index].latitude, cLocations[index].longitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude);
                                }
                                if (issueDistance < (dis * 1000)) {
                                    if (!isDistanceValid) {
                                        var issueDisVal = issueDistance - prevDis;
                                        var newLocVal = {
                                            lat: 0,
                                            lng: 0,
                                            name: 'name'
                                        };
                                        if (index >= 1) {
                                            disBetweenPoints = (distanceCal(cLocations[index].latitude, cLocations[index].longitude, cLocations[index - 1].latitude, cLocations[index - 1].longitude) * 1000);
                                            newLocVal = calNewLatLng(child.name, parent.name, cLocations[index - 1].latitude, cLocations[index - 1].longitude, cLocations[index].latitude, cLocations[index].longitude, issueDisVal);
                                        } else if (index == 0) {
                                            disBetweenPoints = (distanceCal(cLocations[index].latitude, cLocations[index].longitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude) * 1000);
                                            newLocVal = calNewLatLng(child.name, parent.name, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude, cLocations[index].latitude, cLocations[index].longitude, issueDisVal);
                                        }
                                        if (newLocVal.lat > 0) {
                                            greenPolylineLatLng.push(newLocVal);
                                        }
                                        isDistanceValid = true;
                                    } else {
                                        redPolylineLatLng.push({
                                            lat: cLocations[index - 1].latitude,
                                            lng: cLocations[index - 1].longitude,
                                            name: child.name
                                        });
                                        redPolylineLatLng.push({
                                            lat: cLocations[index].latitude,
                                            lng: cLocations[index].longitude,
                                            name: child.name
                                        });
                                    }
                                } else {
                                    if (index >= 1) {
                                        greenPolylineLatLng.push({
                                            lat: cLocations[index - 1].latitude,
                                            lng: cLocations[index - 1].longitude,
                                            name: child.name
                                        });
                                        greenPolylineLatLng.push({
                                            lat: cLocations[index].latitude,
                                            lng: cLocations[index].longitude,
                                            name: child.name
                                        });
                                    }
                                }
                            }
                            for (var i = 0; i < redPolylineLatLng.length; i++) {
                                if (i > 0) {
                                    var locValues = [];
                                    locValues.push(redPolylineLatLng[i - 1]);
                                    locValues.push(redPolylineLatLng[i]);
                                    drawPolylineForGeneratedLoc(locValues, map, "red");
                                }
                            }
                            for (var i = 0; i < greenPolylineLatLng.length; i++) {
                                if (i > 0) {
                                    var locValues = [];
                                    locValues.push(greenPolylineLatLng[i - 1]);
                                    locValues.push(greenPolylineLatLng[i]);
                                    drawPolylineForGeneratedLoc(locValues, map, "green");
                                }
                            }
                        } else {
                            calNewLatLng(child.name, parent.name, parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude, parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude, child.assetCoordinates[child.assetCoordinates.length - 1].latitude, child.assetCoordinates[child.assetCoordinates.length - 1].longitude, issueDistance);
                        }
                    }
                } else {
                    redLatLngs.push({
                        lat: parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude,
                        lng: parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude,
                        name: parent.name
                    });

                    redPolylineLatLng = [];
                    if (child.assetCoordinates.length > 1) {
                        for (var iterable_element in child.assetCoordinates) {
                            redPolylineLatLng.push({
                                lat: child.assetCoordinates[iterable_element].latitude,
                                lng: child.assetCoordinates[iterable_element].longitude,
                                name: child.name
                            });
                        }
                        for (var i = 0; i < redPolylineLatLng.length; i++) {
                            if (i > 0) {
                                var locValues = [];
                                locValues.push(redPolylineLatLng[i - 1]);
                                locValues.push(redPolylineLatLng[i]);
                                drawPolylineForGeneratedLoc(locValues, map, "red");
                            }
                        }
                    } else {
                        redPolylineLatLng.push({
                            lat: parent.assetCoordinates[parent.assetCoordinates.length - 1].latitude,
                            lng: parent.assetCoordinates[parent.assetCoordinates.length - 1].longitude,
                            name: parent.name
                        });
                        redPolylineLatLng.push({
                            lat: child.assetCoordinates[child.assetCoordinates.length - 1].latitude,
                            lng: child.assetCoordinates[child.assetCoordinates.length - 1].longitude,
                            name: child.name
                        });
                        for (var i = 0; i < redPolylineLatLng.length; i++) {
                            if (i > 0) {
                                var locValues = [];
                                locValues.push(redPolylineLatLng[i - 1]);
                                locValues.push(redPolylineLatLng[i]);
                                drawPolylineForGeneratedLoc(locValues, map, "red");
                            }
                        }
                    }
                }
            }

            function createMarker(myLatLng, map, index, color) {
                if (myLatLng.typeName != "Point") {
                    if (color == "red") {
                        var marker = new google.maps.Marker({
                            position: myLatLng,
                            map: map,
                            content: markerContent,
                            icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                            title: myLatLng.name
                        });
                    } else if (color == "icon") {
                        var marker = new google.maps.Marker({
                            position: myLatLng,
                            map: map,
                            content: markerContent,
                            icon: {
                                scaledSize: new google.maps.Size(
                                    30, 30),
                                origin: new google.maps.Point(
                                    0, 0),
                                anchor: new google.maps.Point(
                                    0, 0),

                            },
                            title: myLatLng.name
                        });
                    } else {
                        var marker = new google.maps.Marker({
                            position: myLatLng,
                            map: map,
                            content: markerContent,
                            icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                            title: myLatLng.name
                        });
                    }
                    var markerContent = '<div class="infoWindowhead">' +
                        myLatLng.name +
                        '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                        myLatLng.lat +
                        '<br> <b>Lng :</b> ' +
                        myLatLng.lng +
                        '<br> <b><a target="_blank" href=https://www.google.com/maps/search/?api=1&query=' + myLatLng.lat + ',' + myLatLng.lng + '>Go To</a></b>' +
                        '</div>';

                    var infowindow = new google.maps.InfoWindow({
                        content: markerContent
                    });
                    marker.addListener('click', function() {
                        infowindow.open(map, marker);
                    });
                }
            }

            function initializeGMap(lat, lng) {

                myLatlng = new google.maps.LatLng(lat, lng);
                var markerContent = '<div class="infoWindowhead">' +
                    "Generated Location" +
                    '</div><div class="infoWindowContent"> <br> <b> Lat  :</b> ' +
                    lat +
                    '<br> <b>Lng :</b> ' +
                    lng +
                    '<br> <b><a target="_blank" href=https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng + '>Go To</a></b>' +
                    '</div>';

                var marker = new google.maps.Marker({
                    position: myLatlng,
                    animation: google.maps.Animation.DROP,
                    map: map
                });
                var infowindow = new google.maps.InfoWindow({
                    content: markerContent
                });
                marker.addListener('click', function() {
                    infowindow.open(map, marker);
                });
            }

            function drawPolylineForGeneratedLoc(latlngsToDraw, map, color) {
                var spreadAssetCoordinates = [];
                $.each(latlngsToDraw, function(index, value) {

                    spreadAssetCoordinates.push({
                        lat: value.lat,
                        lng: value.lng
                    });
                });

                if (spreadAssetCoordinates.length > 0) {
                    var midLatLng = {
                        lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
                        lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
                    };
                    var infowindow = new google.maps.InfoWindow({
                        content: '<div class="infoWindowhead">' +
                            "name" +
                            '<div class="infoWindowContent"> <b>Description :</b> ' +
                            "des" + '</div></div>'
                    });
                    infowindow.setPosition(midLatLng);
                    var ColorValue = '#ff0000';
                    if (color == "red") {
                        ColorValue = '#ff0000';
                    } else if (color == "black") {
                        ColorValue = '#000000';
                    } else {
                        ColorValue = '#208000';
                    }
                    var polylinepath = new google.maps.Polyline({
                        path: spreadAssetCoordinates,
                        geodesic: true,
                        strokeColor: ColorValue,
                        strokeOpacity: 1.0,
                        strokeWeight: 2.5,
                        fillColor: ColorValue,
                        fillOpacity: 0.3,
                        editable: false
                    });

                    polylinepath.setOptions({
                        strokeColor: ColorValue
                    });

                    polylinepath.setMap(map);
                }
            }

            function calNewLatLng(childName, parentName, lat1, lng1, lat2, lng2, issueDistance) {
                vm.parentName = parentName;
                vm.childName = childName;

                var dLon = (lng2 - lng1);
                var y = Math.sin(dLon) * Math.cos(lat2);
                var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
                var bearing = radians_to_degrees((Math.atan2(y, x)));
                bearing = (360 - ((bearing + 360) % 360));

                var earthRadius = 6378.1;
                var bearingR = -degrees_to_radians(bearing);
                var latR = degrees_to_radians(lat1);
                var lonR = degrees_to_radians(lng1);
                var distanceToRadius = (issueDistance / 1000) / earthRadius;
                var newLatR = Math.asin(Math.sin(latR) * Math.cos(distanceToRadius) +
                    Math.cos(latR) * Math.sin(distanceToRadius) * Math.cos(bearingR));
                var newLonR = lonR + Math.atan2(Math.sin(bearingR) * Math.sin(distanceToRadius) * Math.cos(latR),
                    Math.cos(distanceToRadius) - Math.sin(latR) * Math.sin(newLatR));
                var latNew = radians_to_degrees(newLatR);
                var lonNew = radians_to_degrees(newLonR);
                vm.generatedLat = latNew;
                vm.generatedLng = lonNew;
                var newLatLng = {
                    lat: latNew,
                    lng: lonNew,
                    name: "New Location",
                    typeName: "New Location"
                };
                initializeGMap(latNew, lonNew);
                var myLatLngPos = {
                    lat: latNew,
                    lng: lonNew
                };
                map.setCenter(myLatLngPos);
                var locValues = [];
                locValues.push({
                    lat: latNew,
                    lng: lonNew,
                    name: childName
                });
                locValues.push({
                    lat: lat2,
                    lng: lng2,
                    name: childName
                });
                drawPolylineForGeneratedLoc(locValues, map, "red");
                return {
                    lat: latNew,
                    lng: lonNew,
                    name: childName
                };
            }
        });
        function createAsset() {
            sessionStorage.setItem("pageValue", "asset");
            $state.go("asset.new");
        }
        function navigateEditPage(selectedId) {
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevId", vm.editId);
            sessionStorage.setItem("firstEditPage", "asset");
            $state.go('asset.edit', {
                'id': vm.editId
            });
        }
        function navigateToEdit0(selectedId) {
            // var testModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", testModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                'id': vm.editId
            });
        };
        function navigateToEdit1(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go('asset-detail.edit', {
                'id': vm.editId
            });
        }
        function navigateToEdit2(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                'id': vm.editId
            });
        };
        function navigateToEdit3(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit4(selectedId) {
            // var setModal = "cablePathModal"
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit5(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit6(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit7(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit8(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit9(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit10(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };
        function navigateToEdit11(selectedId) {
            // var setModal = "cablePathModal";
            vm.editId = selectedId.id;
            sessionStorage.setItem("prevEditId", vm.editId);
            // sessionStorage.setItem("modalId", setModal);
            sessionStorage.setItem("firstEditDetailPage", "assetDetail");
            $state.go("asset-detail.edit", {
                id: vm.editId
            });
        };

        function childTable(asset) {
            vm.assets.forEach(function (item) {
                item.isExpanded = false;
            })
            if (vm.toggle == asset.id) {
                vm.toggle = undefined
            } else {
                asset.isExpanded = true;
                vm.toggle = asset.id;
            }
            ChildAssets.query({
                parentId: asset.id
            }, onSuccess);

            function onSuccess(data, headers) {
                vm.childAssets = data;
            }
        }

        function subChildTable(subChildValue) {
            ChildAssets.query({
                parentId: subChildValue.id
            }, onSuccess);

            function onSuccess(data, headers) {
                vm.subChildAssets = data;
            }
        }

        function grandChildTable(grandChildValue) {
            ChildAssets.query({
                parentId: grandChildValue.id
            }, onSuccess);

            function onSuccess(data, headers) {
                vm.grandChildAssets = data;
            }
        }



        $("#searchParentAssets").change(function () {
            var assetSelected = JSON.parse($("#searchParentAssets").val());
            AssetChildren.getAssetChildren(assetSelected, function (response) {
                var width = 960,
                    height = 500,
                    root;

                var force = d3.layout.force()
                    .linkDistance(80)
                    .charge(-120)
                    .gravity(.05)
                    .size([width, height])
                    .on("tick", tick);

                var svg = d3.select("#tree-container").append("svg")
                    .attr("width", width)
                    .attr("height", height);

                var link = svg.selectAll(".link"),
                    node = svg.selectAll(".node");

                var root = response;

                update();
                function update() {
                    var nodes = flatten(root),
                        links = d3.layout.tree().links(nodes);

                    // Restart the force layout.
                    force
                        .nodes(nodes)
                        .links(links)
                        .start();

                    // Update links.
                    link = link.data(links, function (d) {
                        return d.target.id;
                    });

                    link.exit().remove();

                    link.enter().insert("line", ".node")
                        .attr("class", "link")
                        .style("stroke", function (d) {
                            if ((d.target.status = 'false')) {
                                return d.target.color
                            }
                        })

                    // Update nodes.
                    node = node.data(nodes, function (d) { return d.id; });

                    node.exit().remove();

                    var nodeEnter = node.enter().append("g")
                        .attr("class", "node")
                        .on("click", click)
                        .call(force.drag);

                    // nodeEnter.append("circle")
                    //     .attr("r", function (d) { return Math.sqrt(d.size) / 10 || 4.5; });

                    nodeEnter.append("image")
                        .attr("xlink:href", function (d) { return d.icon; })
                        .attr("x", "-12px")
                        .attr("y", "-12px")
                        .attr("width", "24px")
                        .attr("height", "24px");

                    nodeEnter.append("text")
                        .attr("dy", ".35em")
                        .style("fill", "black")
                        .text(function (d) { return d.name; });

                    node.select("circle")
                        .style("fill", color);
                }

                function tick() {
                    link.attr("x1", function (d) { return d.source.x; })
                        .attr("y1", function (d) { return d.source.y; })
                        .attr("x2", function (d) { return d.target.x; })
                        .attr("y2", function (d) { return d.target.y; });

                    node.attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });
                }

                function color(d) {
                    return d._children ? "#3182bd" // collapsed package
                        : d.children ? "#c6dbef" // expanded package
                            : "#fd8d3c"; // leaf node
                }

                // Toggle children on click.
                function click(d) {
                    if (d3.event.defaultPrevented) return; // ignore drag
                    if (d.children) {
                        d._children = d.children;
                        d.children = null;
                    } else {
                        d.children = d._children;
                        d._children = null;
                    }
                    update();
                }

                // Returns a list of all nodes under the root.
                function flatten(root) {
                    var nodes = [], i = 0;

                    function recurse(node) {
                        if (node.children) node.children.forEach(recurse);
                        if (!node.id) node.id = ++i;
                        nodes.push(node);
                    }

                    recurse(root);
                    return nodes;
                }
            });
        });
    }
})();