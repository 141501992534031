(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('GroupTypeDialogController', GroupTypeDialogController);

    GroupTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams',  'entity', 'GroupType', 'User','AllCustomGroupTypeAttribute', '$state', '$q'];

    function GroupTypeDialogController ($timeout, $scope, $stateParams,  entity, GroupType, User,AllCustomGroupTypeAttribute, $state, $q) {
        var vm = this;

        vm.groupType = entity;
        $scope.attributetypes = ['INTEGER', 'FLOAT', 'STRING','BOOLEAN'];
        vm.clear = clear;
        
        vm.customCaseTypeAttributes = loadPage();
        vm.customAttributesMap = {};
        
        
		vm.save = save;
		 $(":file").filestyle({buttonBefore: true});      
	     $(":file").filestyle('buttonText', 'Browse File');
       
        
	     vm.items=[{ name: '',attributeDataType: {},defaultValue :''}];
	     
	     if(vm.groupType.groupTypeAttributes!=null && vm.groupType.groupTypeAttributes.length!=0){
	        	vm.items=vm.groupType.groupTypeAttributes;
	        }
	     
	     function loadPage() {
	    	 AllCustomGroupTypeAttribute.getAll(function(response) {
	    		 vm.customGroupTypeAttributes = response;
	     		 response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customGroupTypeAttributeValues;
					});
	    		 
				});
	    	    
	    	 }
        
        if(vm.groupType.groupTypeAttributes!=null && vm.groupType.groupTypeAttributes.length!=0){
        	vm.items=vm.groupType.groupTypeAttributes;
        }
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.groupType.id !== null) {
            	vm.groupType.groupTypeAttributes=[];
            	for(var i = 0; i < vm.items.length; i++){
            		var attributes ={"name" :  vm.items[i]};
            		 vm.groupType.groupTypeAttributes.push({"id" : vm.items[i].id , "name" :  vm.items[i].name,  "attributeDataType" :  vm.items[i].attributeDataType,"defaultValue" :  vm.items[i].defaultValue});
            	}
                GroupType.update(vm.groupType, onSaveSuccess, onSaveError);
            } else {
                vm.groupType.groupTypeAttributes=[];
            	
            	for(var i = 0; i < vm.items.length; i++){
            		var attributes ={"name" :  vm.items[i]};
            		 vm.groupType.groupTypeAttributes.push({"id" : vm.items[i].id , "name" :  vm.items[i].name,   "attributeDataType" :  vm.items[i].attributeDataType,"defaultValue" :  vm.items[i].defaultValue});
            	}
                GroupType.save(vm.groupType, onSaveSuccess, onSaveError);
            }
        }
        
        function onSaveSuccess(result) {
            $scope.$emit('trakeyeApp:groupTypeUpdate', result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == 'group.new') {
                $state.go('group.new');
            } else {
                $state.go('group-type');
            }
            // sessionStorage.removeItem("prevPage");
            // $state.go('group-type');
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        
        vm.addmore = function() {
        	vm.items.push({name: '',attributeDataType:{}, defaultValue:''});
          };
            
       vm.remove = function(index) {
            vm.items.splice(index, 1);
          };

    }
})();
