(function() {
	'use strict';
	angular.module('trakeyeApp').factory('Group', Group);

	Group.$inject = [ '$resource', 'DateUtils' ];

	function Group($resource, DateUtils) {
		var resourceUrl = 'api/groups/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('GroupSearch', GroupSearch);

	GroupSearch.$inject = [ '$resource' ];

	function GroupSearch($resource) {
		var service = $resource('/api/groups/searchvalue/:userId', {
			userId : '@id'
		}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllGroups', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/groupsall').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllGroupsTypes', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/group-alltypes').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';
	angular.module('trakeyeApp').factory('GroupUpload', GroupUpload);

	GroupUpload.$inject = [ '$http' ];

	function GroupUpload($http) {

		var service = {};
		service.groupUpload = function(data,assetType, callback) {
			$http.post('api/groupsimport/'+assetType.name, data, {
				transformRequest : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}).then(function(response) {

				callback(response, response.headers);
			}, function(error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

//(function() {
//	'use strict';
//
//	angular.module('trakeyeApp').factory('GroupIDs', [ '$rootScope', '$http', function($rootScope, $http) {
//
//		var service = {};
//		service.getAll = function( callback) {
//			$http.get('api/grouplist').then(function(response) {
//				callback(response.data);
//			});
//		};
//
//		return service;
//	} ]);
//})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserGroupIDs', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function( callback) {
			$http.get('api/user-groups').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();
