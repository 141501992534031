(function() {
	'use strict';

	angular.module('trakeyeApp').factory('User', User);

	User.$inject = [ '$resource' ];

	function User($resource) {
		var service = $resource('api/users/:login', {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'save' : {
				method : 'POST'
			},
			'update' : {
				method : 'PUT'
			},
			'delete' : {
				method : 'DELETE'
			}
		});

		return service;
	}
})();
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserSearch', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getuserbyname = function(data, callback) {
			$http.get('api/users-details/' + data).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserById', UserById);

	UserById.$inject = [ '$resource' ];

	function UserById($resource) {
		var service = $resource('api/user-data/:userId', {}, {
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					data = angular.fromJson(data);
					return data;
				}
			}
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserDetails', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getuserbystatus = function(data, callback) {
			$http.get('api/user/userdetails/' + data).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserIDs', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function( callback) {
			$http.get('api/userslist').then(function(response) {
				callback(response.data);
			});
		};
		
		service.getAdminAll = function( callback) {
			$http.get('api/adminuserslist').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('ActivatedUsers', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getactivatedusers = function(callback) {
			$http.get('api/activateduserlist').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserValueSearch', UserValueSearch);

	UserValueSearch.$inject = [ '$resource' ];

	function UserValueSearch($resource) {
		var service = $resource('/api/users/searchvalue/:search', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UsersStatusService', UsersStatusService);

	UsersStatusService.$inject = [ '$resource' ];

	function UsersStatusService($resource) {
		var service = $resource('api/userdetails/:status', {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserStatusSearch', UserStatusSearch);

	UserStatusSearch.$inject = [ '$resource' ];

	function UserStatusSearch($resource) {
		var service = $resource('/api/userdetails/statussearch/:status/:searchtext', {
			status : '@status',
			searchtext : '@searchtext'
		}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('ActivatedUserSearch', ActivatedUserSearch);

	ActivatedUserSearch.$inject = [ '$resource' ];

	function ActivatedUserSearch($resource) {
		var service = $resource('/api/users/searchactivatedusers/:userId/:user', {
			userId : '@id'
		}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UserHierarchy', UserHierarchy);

	UserHierarchy.$inject = [ '$resource' ];

	function UserHierarchy($resource) {
		var service = $resource('api/users/reporties/:login', {
			login : 'bang_south'
		}, {
			'query' : {
				method : 'GET',
				isArray : false
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('UsersHierarchy', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		/*
		 * service.getUserHierarchy = function (callback){
		 * $http.get('api/users/reporties').then(function(response){
		 * callback(response.data); }); };
		 */

		service.getUsersHierarchy = function(login, callback) {
			var url = 'api/users/reporties/';
			if (login != null) {
				url = url + login
			}
			$http.get(url).then(function(response) {
				callback(response.data);
			});

		};

		return service;
	} ]);
})();
