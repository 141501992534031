(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CaseTypeDialogController', CaseTypeDialogController);

    CaseTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$state', 'entity', 'CaseType', 'User','CaseTypeSearch','AllCustomCaseTypeAttribute'];

    function CaseTypeDialogController ($timeout, $scope, $stateParams, $state, entity, CaseType, User,CaseTypeSearch,AllCustomCaseTypeAttribute) {
        var vm = this;
		//casetype attribute types
			$scope.attributetypes = ['INTEGER', 'FLOAT', 'STRING','BOOLEAN'];
		//end
        vm.caseType = entity;
        vm.caseTypeImageName = caseTypeImageName;
        vm.decodeImage = decodeImage;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.customCaseTypeAttributes = loadPage();
        vm.customAttributesMap = {};
		$scope.allimages = false;
		 $scope.toggle = function() {
			$scope.allimages = !$scope.allimages;
		};
        vm.save = save;
		 $(":file").filestyle({buttonBefore: true});      
	     $(":file").filestyle('buttonText', 'Browse File');
      
	     function caseTypeImageName(image) {
	        	vm.caseType.image = image;
	        }
	     
	     function decodeImage(img){
      	   return window.atob(img);
         }
	     
	     
	     function loadPage() {
	    	 AllCustomCaseTypeAttribute.getAll(function(response) {
	    		 vm.customCaseTypeAttributes = response;
	     		 response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customCaseTypeAttributeValues;
					});
	    		 
				});
	    	    
	    	 }
        
//      vm.users = User.query();
       vm.items=[{ name: '',attributeDataType: {},value :''}];
	   
        if(vm.caseType.caseTypeAttribute!=null && vm.caseType.caseTypeAttribute.length!=0){
        	vm.items=vm.caseType.caseTypeAttribute;
        	
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        } 
        function save () {
            vm.isSaving = true;
             if (vm.caseType.id !== null) {
            	vm.caseType.caseTypeAttribute=[];
            	for(var i = 0; i < vm.items.length; i++){
            		var attributes ={"name" :  vm.items[i]};
					vm.caseType.caseTypeAttribute.push({"id" : vm.items[i].id , "name" :  vm.items[i].name,  "attributeDataType" :  vm.items[i].attributeDataType,"value" :  vm.items[i].value});
            	    
            	}
            	CaseType.update(vm.caseType, onSaveSuccess, onSaveError);
            } else {
            	vm.caseType.caseTypeAttribute=[];
            	
            	for(var i = 0; i < vm.items.length; i++){
            		var attributes ={"name" :  vm.items[i]};
					vm.caseType.caseTypeAttribute.push({"id" : vm.items[i].id ,"name" :  vm.items[i].name,"attributeDataType" :  vm.items[i].attributeDataType, "value" :  vm.items[i].value});
            	    
            	}
            	CaseType.save(vm.caseType, onSaveSuccess, onSaveError);
            }
           
        }
        
        $("#caseTypeImage").change(function(){
        	var filesSelected = document.getElementById("caseTypeImage").files;
       	 for(var i=0; i<filesSelected.length; i++){
				var fileReader = new FileReader();
                 fileReader.onload = function(fileLoadedEvent) {
			    	var test=window.btoa(fileLoadedEvent.target.result);
			    	 vm.caseType.image= test;
			    	
			    }
			      fileReader.readAsBinaryString(filesSelected[i]);
			}
		    
		});

        function onSaveSuccess(result) {
            $scope.$emit('trakeyeApp:caseTypeUpdate', result);
            vm.isSaving = false;

            var pageValue = sessionStorage.getItem("prevPage");
            if (pageValue == 'tr-case.workflow') {
                $state.go('tr-case.workflow');
            } else {
                $state.go('case-type');
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.addmore = function() {
            vm.items.push({name: '',attributeDataType:{}, value:''});
          };
            
       vm.remove = function(index) {
            vm.items.splice(index, 1);
          };
          
        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
