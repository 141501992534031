(function() {
    'use strict';

    angular.module('trakeyeApp').controller('TrCaseDialogController', TrCaseDialogController).directive('mySrc', function() {
        return {
            restrict: 'A',
            scope: {
                callback: '&'
            },
            link: function(scope, elem, attrs) {
                elem.attr('src', scope.callback());
            }
        };
    })
    TrCaseDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'TrCase', 'UserGroupIDs', 'User', 'CaseType', 'Principal', 'Asset', '$state', 'ActivatedUsers', 'UserValueSearch',
        'AlertService', 'ActivatedUserSearch', 'TrCaseUpload', 'AssetSearchForMap', 'AssetSearch', 'AllCaseTypes', 'TrCaseImages', 'AllCustomCaseTypeAttribute', '$ngConfirm'
    ];

    function TrCaseDialogController($timeout, $scope, $stateParams, $q, entity, TrCase, UserGroupIDs, User, CaseType, Principal, Asset, $state, ActivatedUsers, UserValueSearch, AlertService, ActivatedUserSearch,
        TrCaseUpload, AssetSearchForMap, AssetSearch, AllCaseTypes, TrCaseImages, AllCustomCaseTypeAttribute, $ngConfirm) {
        var vm = this;

        vm.trCase = entity;

        var entity_old = angular.copy(vm.trCase);
        // vm.caseImages; // = entity_old.caseImages;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.decodeImage = decodeImage;
        vm.filterUser = filterUser;
        vm.filterActivatedUser = filterActivatedUser;
        vm.assetList = "";
        vm.filterAssetsList = filterAssetsList;
        vm.customAttributesMap = {};
        var usersMap = {};
        var openedInfoWindow = null;

        $scope.allimages = false;
        $scope.toggle = function() {
            $scope.allimages = !$scope.allimages;
            // console.log($scope.allimages);
            if ($scope.allimages) {

                TrCaseImages.query({
                    caseId: vm.trCase.id

                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    // console.log('Recived data-----------');
                    vm.caseImages = data;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }

            }
        };
        vm.trCase.caseImages = [];

        $(".select2").select2(); // Added search for select box
        // Added for upload
        $(":file").filestyle({
            buttonBefore: true
        });
        $(":file").filestyle('buttonText', 'Browse File');
        vm.caseTypes = loadPage(); // CaseType.query();
        vm.selectattributes = selectedCaseTypeAttributes;
        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function loadPage() {

            vm.multgroup = [];
            var selctedGroup = [];

            if (vm.trCase.id != null) {
                vm.trCase.groups.forEach(function(group) {
                    selctedGroup.push(group.id);
                });
            }

            ReverseGeocode(parseFloat(vm.trCase.pinLat), parseFloat(vm.trCase.pinLong));
            AllCaseTypes.getAll(function(response) {
                vm.caseTypes = response;
            });

            UserGroupIDs.getAll(function(response) {
                response.forEach(function(group) {
                    if (selctedGroup.indexOf(group.id) !== -1) {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: true });
                    } else {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: false });
                    }
                });
            });

            AllCustomCaseTypeAttribute.getAll(function(response) {
                //vm.customCaseTypeAttributes = response;
                response.forEach(function(arrayItem) {
                    vm.customAttributesMap[arrayItem.name] = arrayItem.customCaseTypeAttributeValues;
                });

            });
        }

        function ReverseGeocode(latitude, longitude) {
            var address = new google.maps.Geocoder();

            address.geocode({
                'latLng': { lat: latitude, lng: longitude }
            }, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        vm.trCase.address = results[0].formatted_address;
                    }
                }
            });
        }

        function save() {
            vm.isSaving = true;
            var assignedToUser = $("#field_assignedToUser").val();
            vm.trCase.assignedTo = usersMap[assignedToUser];
            angular.forEach(vm.assetList, function(value, key) {
                if (vm.trCase.asset.name === value.name) {
                    vm.trCase.asset = value;
                }
            });

            if (vm.trCase.id !== null) {
                TrCase.update(vm.trCase, onSaveSuccess, onSaveError);
            } else {
                TrCase.save(vm.trCase, onSaveSuccess, onSaveError);
            }
        }
        $("#caseImages").change(function() {

            var filesSelected = document.getElementById("caseImages").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();

                fileReader.onload = function(fileLoadedEvent) {
                    vm.trCase.caseImages.push({
                        image: window.btoa(fileLoadedEvent.target.result),
                        trCase: {
                            id: entity_old.id
                        }
                    });
                }
                fileReader.readAsBinaryString(filesSelected[i]);
            }

        });

        function onSaveSuccess(result) {
            $scope.$emit('trakeyeApp:trCaseUpdate', result);
            $state.go('tr-case');
            // $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        vm.caseType = entity.caseType;

        function selectedCaseTypeAttributes() {
            if (vm.caseType) {
                vm.trCase.caseType = vm.caseType;
                if (entity_old.caseType && vm.caseType.id === entity_old.caseType.id) {
                    vm.trCase.caseTypeAttributeValues = [];
                    vm.trCase.caseTypeAttributeValues = entity_old.caseTypeAttributeValues;
                } else {
                    vm.trCase.caseTypeAttributeValues = [];
                    $.each(vm.caseType.caseTypeAttribute, function(key, value) {
                        vm.trCase.caseTypeAttributeValues.push({
                            caseTypeAttribute: vm.caseType.caseTypeAttribute[key]
                        });
                    });
                }
            }

        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.createDate = false;
        vm.datePickerOpenStatus.updateDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function decodeImage(img) {
            return window.atob(img);
        }

        function filterUser() {
            if (vm.trCase.assignedToUser != null && vm.trCase.assignedToUser != "" && !angular.isUndefined(vm.trCase.assignedToUser)) {
                UserValueSearch.query({
                    search: vm.trCase.assignedToUser,
                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.users = data;
                    angular.forEach(vm.users, function(value, key) {
                        usersMap[value.login] = value.id;
                    });
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
        }

        $scope.openAssetMapModal = function() {
            var value = vm.trCase.asset;
            if (vm.trCase.asset.assetCoordinates.length > 0) {
                $("#assetsModal").show();
                var myCenter = new google.maps.LatLng(vm.trCase.pinLat, vm.trCase.pinLong);
                var ele = document.getElementById("assetsModal");
                $(ele).modal('show');
                //
                var locMap = new google.maps.Map(document
                    .getElementById('map_canvas_assets'), {
                        center: myCenter,
                        zoom: 14
                    });

                //	            var markerA = new google.maps.Marker({
                //                    position: myCenter,
                //                    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                //                    map: locMap
                //                });

                //                var markerContent = '<div class="infoWindowhead"><b>'+ vm.trCase.description
                //    			+ '</b></div><div class="infoWindowContent">'
                //    			+'<b>Lat : </b>'+ vm.trCase.pinLat
                //    			+'<br><b>Lng : </b>'+ vm.trCase.pinLong
                //    			+'</div>';
                //                
                //                var infoWindow1 = new google.maps.InfoWindow({
                //                	content: markerContent
                //                });

                //    	        markerA.addListener('click', function () {
                //    	        	if (openedInfoWindow != null) {
                //                        openedInfoWindow.close();
                //                    }
                //    	        	infoWindow1.open(map, markerA);
                //    	        	openedInfoWindow = infoWindow1;
                //    	        });

                if (value.assetType != null) {
                    if (value.assetType != null && value.assetType.layout == 'FIXED') {
                        drawFixedAsset(value, locMap);
                    } else if (value.assetType != null && value.assetType.layout == 'SPREAD') {
                        if (value.assetCoordinates.length > 0) {
                            drawPolyline(value, locMap);
                        }
                    }
                }

                $('#assetsModal').on('show.bs.modal', function(event) {
                    $("#location-map-assets").css("width", "100%");
                    $("#map_canvas_assets").css("width", "100%");
                });
            } else {
                //console.log(value.customerName + " - Location details missing!!!");
            }
        }

        function drawFixedAsset(fixed, locMap) {

            if (fixed.assetCoordinates.length > 0) {
                if (openedInfoWindow != null) {
                    openedInfoWindow.close();
                }
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(fixed.assetCoordinates[0].latitude, fixed.assetCoordinates[0].longitude),
                    map: locMap,
                    icon: {
                        scaledSize: new google.maps.Size(
                            30, 30),
                        origin: new google.maps.Point(
                            0, 0),
                        url: fixed.assetType.imagePath,
                        anchor: new google.maps.Point(
                            16, 16),

                    }
                });

                marker.setMap(locMap);

                var infowindow = new google.maps.InfoWindow({
                    content: fixed.name
                });

                google.maps.event.addListener(marker, 'mouseover', function() {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.open(locMap, marker);
                    openedInfoWindow = infowindow;
                });
                google.maps.event.addListener(marker, 'mouseout', function() {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                });
                openedInfoWindow = infowindow;
            }
        }

        function drawPolyline(spread, locMap) {

            var polylineColor = null;

            var spreadAssetCoordinates = [];
            var assetCoordinatesList = spread.assetCoordinates;

            assetCoordinatesList.forEach(function(value) {
                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude
                });
            });

            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat: spread.assetCoordinates[spread.assetCoordinates.length - 1].latitude,
                    lng: spread.assetCoordinates[spread.assetCoordinates.length - 1].longitude
                };

                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        spread.name +
                        '</div>'
                });

                if (spread.assetType.layout == "SPREAD") {
                    polylineColor = spread.assetType.colorcode;
                }
                infowindow.setPosition(midLatLng);
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: polylineColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: spread.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: false
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
                    infowindow.close();
                });

                google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }

                    infowindow.setContent('<div class="infoWindowhead">' +
                        spread.name +
                        '</div>'
                    );

                    infowindow.mousemove
                    infowindow.setPosition(event.latLng);
                    infowindow.open(locMap);
                    openedInfoWindow = infowindow;
                });

                if (spread.status != null && spread.status === false) {
                    polylinepath.setOptions({
                        strokeColor: polylineColor
                    });
                }

                polylinepath.setMap(locMap);
            }
        }

        function filterActivatedUser() {

            if (vm.trCase.assignedToUser != null && vm.trCase.assignedToUser != "" && !angular.isUndefined(vm.trCase.assignedToUser)) {
                ActivatedUserSearch.query({
                    userId: vm.trCase.assignedToUser,
                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.activatedusers = data;

                    angular.forEach(vm.activatedusers, function(value, key) {
                        usersMap[value.login] = value.id;

                    });
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }
            }
        }

        // Added for asset search start
        function filterAssetsList() {
            if (vm.trCase.asset.name != null && vm.trCase.asset.name != "" && !angular.isUndefined(vm.trCase.asset.name)) {
                /*
                 * AssetSearchForMap.searchassetsformap(vm.trCase.asset.name,
                 * function (response) { vm.assetList = response; });
                 */

                AssetSearch.query({
                    search: vm.trCase.asset.name,

                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.assetList = data;

                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }

            }
        };

        // Added for getting lat alng values from map
        var latlng = new google.maps.LatLng("5.431587","101.128162");
        var settings = {
            zoom: 11,
            center: latlng,
            mapTypeControl: true,
            scaleControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            },
            navigationControl: true,
            navigationControlOptions: {
                style: google.maps.NavigationControlStyle.DEFAULT
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            backgroundColor: 'white'
        };

        var map = new google.maps.Map(document.getElementById('map_canvas'), settings);
        var address = new google.maps.Geocoder();
        google.maps.event.addListener(map, 'click', function(event) {

            address.geocode({
                'latLng': event.latLng
            }, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[0]) {
                        $ngConfirm({
                            title: 'Are you sure you want to select this address?',
                            content: '<strong>Address: </strong>' + results[0].formatted_address + '<br/>' + '<strong>Latitude: </strong>' + event.latLng.lat() + '</br>' +
                                '<strong>Longitude: </strong>' + event.latLng.lng(),
                            type: "blue",
                            typeAnimated: true,
                            buttons: {
                                OK: {
                                    text: 'OK',
                                    btnClass: 'btn-primary',
                                    action: function() {
                                        $scope.$apply(function() {
                                            vm.trCase.pinLat = event.latLng.lat();
                                            vm.trCase.pinLong = event.latLng.lng();
                                            vm.trCase.address = results[0].formatted_address;
                                            // alert('Lat: ' +
                                            // event.latLng.lat() + ' Lng: ' +
                                            // event.latLng.lng())
                                        })
                                    }
                                },
                                Cancel: {
                                    text: 'Cancel',
                                    btnClass: 'btn-danger',
                                    action: function() {

                                    }

                                },

                            }
                        });

                    }
                }
            });
        });
        // Added for getting lat alng values from map end

    }
})();