(function() {
	'use strict';
	angular.module('trakeyeApp').factory('TrCase', TrCase);

	TrCase.$inject = [ '$resource', 'DateUtils' ];

	function TrCase($resource, DateUtils) {
		var resourceUrl = 'api/tr-cases/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);

					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();

(function () {
	'use strict';
	angular.module('trakeyeApp').factory('TrCaseCheckAsset', TrCaseCheckAsset);

	TrCaseCheckAsset.$inject = ['$resource', 'DateUtils'];

	function TrCaseCheckAsset($resource, DateUtils) {
		var resourceUrl = 'api/assets/search/:name';

		return $resource(resourceUrl, {}, {
			'get': {
				method: 'GET',
				transformResponse: function (data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
		});
	}
})();


(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseById', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.get = function(id, callback) {
			$http.get('api/tr-cases/' + id).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseAssetsById', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.get = function(id, callback) {
			$http.get('api/tr-cases-assets/' + id).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();
/*
(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseById', TrCaseById);

	TrCaseById.$inject = [ '$resource' ];

	function TrCaseById($resource) {
		var service = $resource('/api/tr-cases/:id', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();*/

(function() {
    'use strict';

    angular.module('trakeyeApp').factory('TrCaseCountByGroup', ['$http', function($http) {

        var service = {};
        service.getAll = function(callback) {
            $http.get('api/casecountbygroup').then(function(response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function() {
    'use strict';

    angular.module('trakeyeApp').factory('TrCaseCountByStatus', ['$http', function($http) {

        var service = {};
        service.getAll = function(callback) {
            $http.get('api/casecountbystatus').then(function(response) {
                callback(response.data);
            });
        };

        return service;
    }]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCasePriority', TrCasePriority);

	TrCasePriority.$inject = [ '$resource' ];

	function TrCasePriority($resource) {
		var service = $resource('/api/tr-cases/priority/:priority', {
			priority : '@priorirty'
		}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseSearch', TrCaseSearch);

	TrCaseSearch.$inject = [ '$resource' ];

	function TrCaseSearch($resource) {
		var service = $resource('/api/tr-cases/searchvalue/:search', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';
	angular.module('trakeyeApp').factory('TrCaseGroup', TrCaseGroup);

	TrCaseGroup.$inject = [ '$resource', 'DateUtils' ];

	function TrCaseGroup($resource, DateUtils) {
		var resourceUrl = 'api/tr-cases-group';

		return $resource(resourceUrl,{
			'query' : {
				method : 'GET',
				isArray : true
			}
		});
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseGroupSearch', TrCaseGroupSearch);

	TrCaseGroupSearch.$inject = [ '$resource' ];

	function TrCaseGroupSearch($resource) {
		var service = $resource('/api/tr-cases-group/searchvalue/:search', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseGroupPrioritySearch', TrCaseGroupPrioritySearch);

	TrCaseGroupPrioritySearch.$inject = [ '$resource' ];

	function TrCaseGroupPrioritySearch($resource) {
		var service = $resource('/api/tr-cases-group/prioritysearch/:priority/:searchtext', {
			priority : '@priorirty',
			searchtext : '@searchtext'
		}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseGroupPriority', TrCaseGroupPriority);

	TrCaseGroupPriority.$inject = [ '$resource' ];

	function TrCaseGroupPriority($resource) {
		var service = $resource('/api/tr-cases-group/priority/:priority', {
			priority : '@priorirty'
		}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseImages', TrCaseImages);

	TrCaseImages.$inject = [ '$resource' ];

	function TrCaseImages($resource) {
		var service = $resource('/api/case-images/:caseId', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseRecordings', TrCaseRecordings);

	TrCaseRecordings.$inject = [ '$resource' ];

	function TrCaseRecordings($resource) {
		var service = $resource('/api/case-recordings/:caseId', {}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllCaseTypes', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/case-alltypes').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCasePrioritySearch', TrCasePrioritySearch);

	TrCasePrioritySearch.$inject = [ '$resource' ];

	function TrCasePrioritySearch($resource) {
		var service = $resource('/api/tr-cases/prioritysearch/:priority/:searchtext', {
			priority : '@priorirty',
			searchtext : '@searchtext'
		}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
    'use strict';
    angular.module('trakeyeApp').factory('TrCaseUpdateGroup', TrCaseUpdateGroup);

    TrCaseUpdateGroup.$inject = ['$resource'];

    function TrCaseUpdateGroup($resource) {
        var resourceUrl = 'api/tr-cases/groups';

        return $resource(resourceUrl, {}, {
            'update': {
                method: 'PUT'
            }
        });
    }
})();
(function() {
    'use strict';
    angular.module('trakeyeApp').factory('TrCaseBulkDelete', TrCaseBulkDelete);

    TrCaseBulkDelete.$inject = ['$resource'];

    function TrCaseBulkDelete($resource) {
        var resourceUrl = 'api/tr-cases/bulk/delete';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST'
            }
        });
    }
})();


(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseUser', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.gettrcasesuser = function(callback) {
			$http.get('api/tr-cases/live-logs').then(function(response) {
				callback(response.data);
			});
		};

		service.gettrcasesuserbyid = function(id, callback) {
			$http.get('api/tr-cases/live-logs/' + id).then(function(response) {
				callback(response.data);
			});
		};

		service.searchtrcasesuserbyvalue = function(id, callback) {
			$http.get('api/tr-cases/search/' + id).then(function(response) {
				callback(response.data);
			});
		};
		service.gettrcasesanduserbypriority = function(priority, callback) {
			$http.get('api/tr-cases/live-logs/priority/' + priority).then(function(response) {
				callback(response.data);
			});
		};

		service.getAgents = function(callback) {
			$http.get('api/dashboard/agentdashboard').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseEdit', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getalltrcases = function(callback) {
			$http.get('api/tr-allcases').then(function(response) {
				callback(response.data);
			});
		};

		service.edittrcase = function(data, callback) {
			$http.post('api/tr-caseedit/' + data.userId + '/' + data.caseId, {

			}).then(function(response) {

				callback(response, response.headers);
			}, function(error) {

				callback(error, error.headers);
			});

			/*
			 * $http.post('api/tr-caseedit/'+data.userId+'/'+data.caseId).then(function(response){
			 * callback(response.data); });
			 */
		};

		return service;
	} ]);
})();

(function() {
	'use strict';
	angular.module('trakeyeApp').factory('TrCaseUpload', TrCaseUpload);

	TrCaseUpload.$inject = [ '$http' ];

	function TrCaseUpload($http) {

		var service = {};
		service.trcaseupload = function(data, callback) {

			$http.post('api/caseimport', data, {
				transformRequest : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}).then(function(response) {

				callback(response, response.headers);
			}, function(error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupCaseSearchForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.searchtrcaseformap = function(search, callback) {
			$http.get('api/tr-groupcasesformap/searchvalue/' + search).then(function(response) {
				callback(response.data);
			});
		};
		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrGroupCaseForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAllCases = function(callback) {
			$http.get('api/tr-groupcasesformap').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrUnresolvedGroupCaseForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAllCases = function(callback) {
			$http.get('api/tr-unresolvedgroupcasesformap').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrUnresolvedLocGroupCaseForMap', TrUnresolvedLocGroupCaseForMap);

	TrUnresolvedLocGroupCaseForMap.$inject = [ '$resource' ];

	function TrUnresolvedLocGroupCaseForMap($resource) {
		var service = $resource('/api/tr-unresolvedlocgroupcasesformap/:lat/:lng/:dis', {},{},{},{}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrUnresolvedLocGroupCaseForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getcases = function(lat,lng,dis, callback) {
			$http.get('api/tr-unresolvedlocgroupcasesformap/' + lat +'/'+lng+'/'+dis).then(function(response) {
				callback(response.data);
			});
		};
		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseSearchForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.searchtrcaseformap = function(search, callback) {
			$http.get('api/tr-casesformap/searchvalue/' + search).then(function(response) {
				callback(response.data);
			});
		};
		
		service.prioritysearchtrcaseformap = function(priority, callback) {
			$http.get('api/tr-casesformap/priority/' + priority).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();


(function() {
    'use strict';
    
    angular
        .module('trakeyeApp')
        .factory('TrCaseLastLocation', ['$rootScope','$http',function($rootScope,$http){
        	var service ={};
        	
        	service.getlastlogs = function (callback){
        		$http.get('api/location-logs/lastuserpath').then(function(response){
            		callback(response.data);
            	});
        	};
        	return service;
        }]);

    
    
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('CasesForMapByAdmin', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.casesformapbyadmin = function(adminid, callback) {
			$http.get('api/tr-casesformap/' + adminid).then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('TrCaseForMap', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAllCases = function(callback) {
			$http.get('api/tr-casesformap').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();

(function() {
    'use strict';
    angular.module('trakeyeApp').factory('WorkflowAssets', WorkflowAssets);

    WorkflowAssets.$inject = ['$resource'];

    function WorkflowAssets($resource) {
        var resourceUrl = 'api/linkedAssets/:parentId';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            }
        });
    }
})();
