(function() {
	'use strict';
	angular.module('trakeyeApp').factory('Geofence', Geofence);

	Geofence.$inject = [ '$resource', 'DateUtils' ];

	function Geofence($resource, DateUtils) {
		var resourceUrl = 'api/geofences/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					if (data) {
						data = angular.fromJson(data);
					}
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			}
		});
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('GeofenceSearch', GeofenceSearch);

	GeofenceSearch.$inject = [ '$resource' ];

	function GeofenceSearch($resource) {
		var service = $resource('/api/geofences/searchvalue/:userId', {
			userId : '@id'
		}, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
		});

		return service;
	}
})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('AllGeofences', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function(callback) {
			$http.get('api/geofencesall').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();



(function() {
	'use strict';
	angular.module('trakeyeApp').factory('GeofenceUpload', GeofenceUpload);

	GeofenceUpload.$inject = [ '$http' ];

	function GeofenceUpload($http) {

		var service = {};
		service.geofenceUpload = function(data,assetType, callback) {
			$http.post('api/geofencesimport/'+assetType.name, data, {
				transformRequest : angular.identity,
				headers : {
					'Content-Type' : undefined
				}
			}).then(function(response) {

				callback(response, response.headers);
			}, function(error) {
				callback(error, error.headers);
			});
		};

		return service;
	}

})();

(function() {
	'use strict';

	angular.module('trakeyeApp').factory('GeofenceIDs', [ '$rootScope', '$http', function($rootScope, $http) {

		var service = {};
		service.getAll = function( callback) {
			$http.get('api/geofencelist').then(function(response) {
				callback(response.data);
			});
		};

		return service;
	} ]);
})();
