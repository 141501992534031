(function() {
	'use strict';

	angular
		.module('trakeyeApp')
		.controller('DashboardController', DashboardController);

	DashboardController.$inject = ['$scope', 'AlertService', '$state', 'pagingParams',
		'paginationConstants', 'DashboardService', 'LinkService', '$filter', 'UserListAndDistance', 'UserListAndDistanceSearch', '$interval', 'AssetTypes', 'ParseLinks',
		'AssetCountByGroup', 'AssetCountBySiteStatus', 'WorkorderCountByGroup', 'WorkorderCountByStatus', 'TrCaseCountByGroup', 'TrCaseCountByStatus', 'AssetCountByLinkStatus', 'WorkOrderGroup'
	];

	function DashboardController($scope, AlertService, $state, pagingParams,
		paginationConstants, DashboardService, LinkService, $filter, UserListAndDistance, UserListAndDistanceSearch, $interval, AssetTypes, ParseLinks,
		AssetCountByGroup, AssetCountBySiteStatus, WorkorderCountByGroup, WorkorderCountByStatus, TrCaseCountByGroup, TrCaseCountByStatus, AssetCountByLinkStatus, WorkOrderGroup) {
		var vm = this;

		vm.loadPage = loadPage;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.predicate = pagingParams.predicate;
		vm.reverse = pagingParams.ascending;
		vm.transition = transition;
		vm.itemsPerPage = paginationConstants.itemsPerPage;
		vm.siteCount = 0;
		vm.totalItems = 0;
		vm.devicesMaxVal = 0;
		vm.userslist = [];
		vm.loadUserList = loadUserList;
		vm.filterSearch = filterSearch;
		vm.loaddata = loaddata;
		vm.multiassets = [];
		vm.multiassets1 = [];
		vm.vendorDataResult = [];
		vm.fiberStatusDataResult = [];
		vm.assetTypeResult = [];
		vm.sitesList = [];
		vm.userlist = [];
		loaddata();
		loadUserList();
		vm.workOrders = [];

		vm.assetgroup = { ip: 0, pg: 0, mg: 0 };
		vm.workordergroup = { ip: 0, pg: 0, mg: 0 };
		vm.trcasegroup = { ip: 0, pg: 0, mg: 0 };

		vm.assetstatus = { status: "Asset", mpApproved: 0, mpProposed: 0, bpApproved: 0, bpProposed: 0 };
		vm.workorderstatus = { status: "Purchase_Order",mpApproved: 0, mpProposed: 0, bpApproved: 0, bpProposed: 0 };
		vm.trcasestatus = { status: "Work Flow",mpApproved: 0, mpProposed: 0, bpApproved: 0, bpProposed: 0 };

		vm.assetlinkstatus = { active: 0, inactive: 0 };

		var updateDashboard = $interval(function() {
			vm.multiassets1 = [];
			loadUserList();
			loaddata();
			var date = new Date();

			var weekday = new Array(7);
			weekday[0] = "Sunday";
			weekday[1] = "Monday";
			weekday[2] = "Tuesday";
			weekday[3] = "Wednesday";
			weekday[4] = "Thursday";
			weekday[5] = "Friday";
			weekday[6] = "Saturday";
			vm.day = weekday[date.getDay()];

			var month = new Array();
			month[0] = "January";
			month[1] = "February";
			month[2] = "March";
			month[3] = "April";
			month[4] = "May";
			month[5] = "June";
			month[6] = "July";
			month[7] = "August";
			month[8] = "September";
			month[9] = "October";
			month[10] = "November";
			month[11] = "December";
			vm.month = month[date.getMonth()];
			vm.todayDate = date.getDate();
			vm.year = date.getFullYear();
			var dateFormat = 'hh:mm';
			vm.time = $filter('date')(date, dateFormat);

		}, 300000);
		$scope.$on('$destroy', function() {
			$interval.cancel(updateDashboard);
		});

		var date = new Date();

		var weekday = new Array(7);
		weekday[0] = "Sunday";
		weekday[1] = "Monday";
		weekday[2] = "Tuesday";
		weekday[3] = "Wednesday";
		weekday[4] = "Thursday";
		weekday[5] = "Friday";
		weekday[6] = "Saturday";
		vm.day = weekday[date.getDay()];

		var month = new Array();
		month[0] = "January";
		month[1] = "February";
		month[2] = "March";
		month[3] = "April";
		month[4] = "May";
		month[5] = "June";
		month[6] = "July";
		month[7] = "August";
		month[8] = "September";
		month[9] = "October";
		month[10] = "November";
		month[11] = "December";
		vm.month = month[date.getMonth()];
		vm.todayDate = date.getDate();
		vm.year = date.getFullYear();
		var dateFormat = 'hh:mm';
		vm.time = $filter('date')(date, dateFormat);


		function loaddata() {
			AssetCountByGroup.getAll(function(data) {
				data.forEach(function(value) {
					if (value[1] == "Planning Group") {
						var plCount = 0;
						if (value[0] != null) {
							plCount = value[0];
						}
						vm.assetgroup.pg = plCount;
					} else if (value[1] == "Implementation Group") {
						var implCount = 0;
						if (value[0] != null) {
							implCount = value[0];
						}
						vm.assetgroup.ig = implCount;
					} else if (value[1] == "Maintenance Group") {
						var mgCount = 0;
						if (value[0] != null) {
							mgCount = value[0];
						}
						vm.assetgroup.mg = mgCount;
					}
				});
			});
			AssetCountBySiteStatus.getAll(function(data) {
				data.forEach(function(value) {
					if (value[1] == "MP Approved") {
						var mpApprovedCount = 0;
						if (value[0] != null) {
							mpApprovedCount = value[0];
						}
						vm.assetstatus.mpApproved = mpApprovedCount;
					} else if (value[1] == "MP Proposed") {
						var mpProposedCount = 0;
						if (value[0] != null) {
							mpProposedCount = value[0];
						}
						vm.assetstatus.mpProposed = mpProposedCount;
					} else if (value[1] == "BP Approved") {
						var bpApprovedCount = 0;
						if (value[0] != null) {
							bpApprovedCount = value[0];
						}
						vm.assetstatus.bpApproved = bpApprovedCount;
					} else if (value[1] == "BP Proposed") {
						var bpProposedCount = 0;
						if (value[0] != null) {
							bpProposedCount = value[0];
						}
						vm.assetstatus.bpProposed = bpProposedCount;
					}
				});
			});
			WorkorderCountByGroup.getAll(function(data) {
				data.forEach(function(value) {
					if (value[1] == "Planning Group") {
						var plCount = 0;
						if (value[0] != null) {
							plCount = value[0];
						}
						vm.workordergroup.pg = plCount;
					} else if (value[1] == "Implementation Group") {
						var implCount = 0;
						if (value[0] != null) {
							implCount = value[0];
						}
						vm.workordergroup.ig = implCount;
					} else if (value[1] == "Maintenance Group") {
						var mgCount = 0;
						if (value[0] != null) {
							mgCount = value[0];
						}
						vm.workordergroup.mg = mgCount;
					}
				});
			});
			WorkorderCountByStatus.getAll(function(data) {
				data.forEach(function(value) {
					if (value[1] == "MP Approved") {
						var mpApprovedCount = 0;
						if (value[0] != null) {
							mpApprovedCount = value[0];
						}
						vm.workorderstatus.mpApproved = mpApprovedCount;
					} else if (value[1] == "MP Proposed") {
						var mpProposedCount = 0;
						if (value[0] != null) {
							mpProposedCount = value[0];
						}
						vm.workorderstatus.mpProposed = mpProposedCount;
					} else if (value[1] == "BP Approved") {
						var bpApprovedCount = 0;
						if (value[0] != null) {
							bpApprovedCount = value[0];
						}
						vm.workorderstatus.bpApproved = bpApprovedCount;
					} else if (value[1] == "BP Proposed") {
						var bpProposedCount = 0;
						if (value[0] != null) {
							bpProposedCount = value[0];
						}
						vm.workorderstatus.bpProposed = bpProposedCount;
					}
				});
			});
			TrCaseCountByGroup.getAll(function(data) {
				data.forEach(function(value) {
					if (value[1] == "Planning Group") {
						var plCount = 0;
						if (value[0] != null) {
							plCount = value[0];
						}
						vm.trcasegroup.pg = plCount;
					} else if (value[1] == "Implementation Group") {
						var implCount = 0;
						if (value[0] != null) {
							implCount = value[0];
						}
						vm.trcasegroup.ig = implCount;
					} else if (value[1] == "Maintenance Group") {
						var mgCount = 0;
						if (value[0] != null) {
							mgCount = value[0];
						}
						vm.trcasegroup.mg = mgCount;
					}
				});
			});


			TrCaseCountByStatus.getAll(function(data) {
				data.forEach(function(value) {
					if (value[1] == "MP Approved") {
						var mpApprovedCount = 0;
						if (value[0] != null) {
							mpApprovedCount = value[0];
						}
						vm.trcasestatus.mpApproved = mpApprovedCount;
					} else if (value[1] == "MP Proposed") {
						var mpProposedCount = 0;
						if (value[0] != null) {
							mpProposedCount = value[0];
						}
						vm.trcasestatus.mpProposed = mpProposedCount;
					} else if (value[1] == "BP Approved") {
						var bpApprovedCount = 0;
						if (value[0] != null) {
							bpApprovedCount = value[0];
						}
						vm.trcasestatus.bpApproved = bpApprovedCount;
					} else if (value[1] == "BP Proposed") {
						var bpProposedCount = 0;
						if (value[0] != null) {
							bpProposedCount = value[0];
						}
						vm.trcasestatus.bpProposed = bpProposedCount;
					}
				});
			});

			AssetCountByLinkStatus.getAll(function(data) {
				data.forEach(function(value) {
					if (value[1] == "Active") {
						var activeCount = 0;
						if (value[0] != null) {
							activeCount = value[0];
						}
						vm.assetlinkstatus.active = activeCount;
					} else if (value[1] == "Inactive") {
						var inactiveCount = 0;
						if (value[0] != null) {
							inactiveCount = value[0];
						}
						vm.assetlinkstatus.inactive = inactiveCount;
					}
				});
				var dataset = [
					{ label: 'Active', count: vm.assetlinkstatus.active },
					{ label: 'Inactive', count: vm.assetlinkstatus.inactive }
				];

				var width = document.getElementById('link-chart').offsetWidth;
				var height = document.getElementById('link-chart').offsetHeight;
				var radius = Math.min(width, height) / 2;
				var donutWidth = 40;
				var legendRectSize = 18;
				var legendSpacing = 4;

				var color = d3.scale.ordinal()
					.range(["#3CB371", "#CD5C5C"]);

				var svg = d3.select('#link-chart')
					.append('svg')
					.attr('width', width)
					.attr('height', height)
					.append('g')
					.attr('transform', 'translate(' + (width / 2) +
						',' + (height / 2) + ')');

				var arc = d3.svg.arc()
					.innerRadius(radius - donutWidth)
					.outerRadius(radius);

				var pie = d3.layout.pie()
					.value(function(d) {
						return d.count;
					})
					.sort(null);


				//d3.csv('weekdays.csv', function (error, dataset) {
				dataset.forEach(function(d) {
					d.count = +d.count;
					d.enabled = true;
				});

				var path = svg.selectAll('path')
					.data(pie(dataset))
					.enter()
					.append('path')
					.attr('d', arc)
					.attr('fill', function(d, i) {
						return color(d.data.label + " - " + d.data.count);
					})
					.each(function(d) {
						this._current = d;
					});

				var tooltip3 = d3.select("body").append("div").attr("class", "toolTipA");

				path.on("mouseover", function(d) {
					tooltip3
						.style("left", d3.event.pageX - 50 + "px")
					.style("top", d3.event.pageY - 50 + "px")
					.style("display", "inline-block")
					.style("fill", "black")
					.html((d.data.label) + "<br>" + (d.value)); return tooltip3.style("visibility", "visible");
				})
					.on("mousemove", function() { return tooltip3.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px"); })
					.on("mouseout", function() { return tooltip3.style("visibility", "hidden"); });

				var legend = svg.selectAll('.legend')
					.data(color.domain())
					.enter()
					.append('g')
					.attr('class', 'legend')
					.attr('transform', function(d, i) {
						var height = legendRectSize + legendSpacing;
						var offset = height * color.domain().length / 2;
						var horz = -2 * legendRectSize;
						var vert = i * height - offset;
						return 'translate(' + horz + ',' + vert + ')';
					});

				legend.append('rect')
					.attr('width', legendRectSize)
					.attr('height', legendRectSize)
					.style('fill', color)
					.style('stroke', color)
					.on('click', function(label) {
						var rect = d3.select(this);
						var enabled = true;
						var totalEnabled = d3.sum(dataset.map(function(d) {
							return (d.enabled) ? 1 : 0;
						}));

						if (rect.attr('class') === 'disabled') {
							rect.attr('class', '');
						} else {
							if (totalEnabled < 2) return;
							rect.attr('class', 'disabled');
							enabled = false;
						}

						pie.value(function(d) {
							if (d.label === label) d.enabled = enabled;
							return (d.enabled) ? d.count : 0;
						});

						path = path.data(pie(dataset));

						path.transition()
							.duration(750)
							.attrTween('d', function(d) {
								var interpolate = d3.interpolate(this._current, d);
								this._current = interpolate(0);
								return function(t) {
									return arc(interpolate(t));
								};
							});
					});

				legend.append('text')
					.attr('x', legendRectSize + legendSpacing)
					.attr('y', legendRectSize - legendSpacing)
					.text(function(d) {
						return d;
					});
			});

			LinkService.loaddata(function(data) {
				vm.vendorDataResult = [];
				vm.fiberStatusDataResult = [];
				var vendorData = Object.entries(data.linkVendorDistance);
				var fiberStatusData = Object.entries(data.nttnProviderAssetsCount);


				vendorData.forEach(function(value) {
					var vendorName = value[0];
					if (vendorName == "null") {
						vendorName = "Other";
					}
					vm.vendorDataResult.push({ y: vendorName, a: parseFloat(value[1]) / 100 });
				});

				fiberStatusData.forEach(function(value) {
					var floatVal = value[1];
					vm.fiberStatusDataResult.push({ y: value[0], a: value[1] });
				});

				var vendorAsset = vm.vendorDataResult
				vm.totalDistance = 0;
				vendorAsset.forEach(function(value) {
					vm.totalDistance += parseFloat(value.a);
				});

				vm.totalDistance = vm.totalDistance.toFixed(2);

				var margin3 = { top: 20, right: 0, bottom: 50, left: 30 }, axisPadding = 5;
				var Width3 = document.getElementById('asset-chart').offsetWidth - 40, Height3 = 280;
				var svgWidth3 = Width3 + margin3.left + margin3.right,
					svgHeight3 = Height3 + margin3.top + margin3.bottom;
				var maxIncome3 = d3.max(vendorAsset, function(d) { return d.a; });

				// define scales and axises
				var xScale = d3.scale.ordinal()
					.domain(vendorAsset.map(function(d) { return d.y; }))
					.rangeBands([0, Width3], 0.1);
				var yScale = d3.scale.linear()
					.domain([0, maxIncome3])
					.range([0, Height3]);
				var color = d3.scale.category20();

				var xAxis = d3.svg.axis()
					.scale(xScale)
					.tickSize(3, 0)
					.orient('bottom');
				var yAxis = d3.svg.axis()
					.scale(yScale.copy().domain([maxIncome3, 0]))
					.tickSize(6, 0)
					.ticks(5)
					.orient('left');

				var tooltip3 = d3.select("body").append("div").attr("class", "toolTipA");

				// create a svg canvas
				var svg = d3.select('#asset-chart')
					.append('svg')
					.attr({ width: svgWidth3, height: svgHeight3 })


				// Drawing for axises
				var xGroup = svg.append('g')
					.attr('class', 'xGroup')
					.attr('transform', 'translate(' + [margin3.left, margin3.top + Height3 + axisPadding] + ')');
				xGroup.call(xAxis);
				styleXAxis(xGroup);
				var yGroup = svg.append('g')
					.attr('class', 'yGroup')
					.attr('transform', 'translate(' + [margin3.left - axisPadding, margin3.top] + ')');
				yGroup.call(yAxis);
				styleXAxis(yGroup);


				// Label layer
				var label = svg.append('g')
					.attr('transform', 'translate(' + [margin3.left - axisPadding, margin3.top] + ')');

				// Drawing for graph body
				var graph = svg.append('g')
					.attr('class', 'graph')
					.attr('transform', 'translate(' + [margin3.left, margin3.top + Height3] + ')');
				var bars = graph.selectAll('g')
					.data(vendorAsset)
					.enter()
					.append('g')
					.attr('transform', function(d, i) { return 'translate(' + [xScale(d.y), -1 * yScale(d.a)] + ')'; });
				bars.append('rect')
					.each(function(d, i) {
						d3.select(this).attr({
							fill: color.range()[i],
							width: xScale.rangeBand(),
							height: yScale(d.a),
						})
					})
					.on("mousemove", function(d) {
						tooltip3
							.style("left", d3.event.pageX - 50 + "px")
							.style("top", d3.event.pageY - 50 + "px")
							.style("display", "inline-block")
							.html((d.y) + "<br>" + (d.a));
					})
					.on("mouseout", function(d) { tooltip3.style("display", "none"); })
					.append('text')
					.text(function(d) { return d.a; })
					.attr('Y', '-5')
					.attr("text-anchor", "end");

				bars.append('text')
					.text(function(d) { return d.a; })
					.each(function(d, i) {
						d3.select(this).attr({
							fill: color.range()[i],
							stroke: 'none',
							x: xScale.rangeBand() / 2,
							y: -5,
							'text-anchor': 'middle',
						});
					})
					
				var tooltip3 = d3.select("body").append("div").attr("class", "toolTipA");

				bars.on("mouseover", function(d) {
					tooltip3
						.style("left", d3.event.pageX - 50 + "px")
					.style("top", d3.event.pageY - 50 + "px")
					.style("display", "inline-block")
					.style("fill", "black")
					.html((d.a) + "<br>" + (d.y)); return tooltip3.style("visibility", "visible");
				})
					.on("mousemove", function() { return tooltip3.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px"); })
					.on("mouseout", function() { return tooltip3.style("visibility", "hidden"); });
					
				function styleXAxis(axis) {
					// style path
					axis.select('.domain').attr({
						fill: 'none',
						stroke: '#888',
						'stroke-width': 1
					});
					// style tick
					axis.selectAll('.tick line').attr({
						stroke: '#000',
						'stroke-width': 1,
						'font-size': '10px'
					})
				}




				AssetTypes.get(function(data) {
					vm.siteCount = data.site;
					vm.assetTypeResult = [];
					var assetTypeData = Object.entries(data);
					assetTypeData.forEach(function(value) {
						vm.assetTypeResult.push({ y: value[0].toUpperCase(), a: parseFloat(value[1]) });
					});
					var assetType = vm.assetTypeResult;
					var margin2 = { top: 20, right: 0, bottom: 50, left: 50 }, axisPadding = 5;
					var Width2 = document.getElementById('assetType-chart').offsetWidth - 50, Height2 = document.getElementById('assetType-chart').offsetHeight - 40;
					var svgWidth2 = Width2 + margin2.left + margin2.right,
						svgHeight2 = Height2 + margin2.top + margin2.bottom;
					var maxIncome2 = d3.max(assetType, function(d) { return d.a; });

					// define scales and axises
					var xScale = d3.scale.ordinal()
						.domain(assetType.map(function(d) { return d.y; }))
						.rangeBands([0, Width2], 0.1);
					var yScale = d3.scale.linear()
						.domain([0, maxIncome2])
						.range([0, Height2]);
					var color = d3.scale.category20();

					var xAxis = d3.svg.axis()
						.scale(xScale)
						.tickSize(3, 0)
						.orient('bottom');
					var yAxis = d3.svg.axis()
						.scale(yScale.copy().domain([maxIncome2, 0]))
						.tickSize(6, 0)
						.ticks(5)
						.orient('left');

					var tooltip2 = d3.select("body").append("div").attr("class", "toolTipA");

					// create a svg canvas
					var svg = d3.select('#assetType-chart')
						.append('svg')
						.attr({ width: svgWidth2, height: svgHeight2 })

					// Drawing for axises
					var xGroup = svg.append('g')
						.attr('class', 'xGroup')
						.attr('transform', 'translate(' + [margin2.left, margin2.top + Height2 + axisPadding] + ')')
						.attr('font-size', '7px')
					xGroup.call(xAxis);
					styleAxis(xGroup);
					var yGroup = svg.append('g')
						.attr('class', 'yGroup')
						.attr('transform', 'translate(' + [margin2.left - axisPadding, margin2.top] + ')');
					yGroup.call(yAxis);
					styleAxis(yGroup);


					// Label layer
					var label = svg.append('g')
						.attr('transform', 'translate(' + [margin2.left - axisPadding, margin2.top] + ')');

					// Drawing for graph body
					var graph = svg.append('g')
						.attr('class', 'graph')
						.attr('transform', 'translate(' + [margin2.left, margin2.top + Height2] + ')');
					var bars = graph.selectAll('g')
						.data(assetType)
						.enter()
						.append('g')
						.attr('transform', function(d, i) { return 'translate(' + [xScale(d.y), -1 * yScale(d.a)] + ')'; });
					bars.append('rect')
						.each(function(d, i) {
							d3.select(this).attr({
								fill: color.range()[i],
								width: xScale.rangeBand(),
								height: yScale(d.a),
							})
						})
						.on("mousemove", function(d) {
							tooltip2
								.style("left", d3.event.pageX - 50 + "px")
								.style("top", d3.event.pageY - 50 + "px")
								.style("display", "inline-block")
								.html((d.y) + "<br>" + (d.a));
						})
						.on("mouseout", function(d) { tooltip2.style("display", "none"); })
						.append('text')
						.text(function(d) { return d.a; })
						.attr('y', '-5')
						.attr("text-anchor", "end");

					bars.append('text')
						.text(function(d) { return d.a; })
						.each(function(d, i) {
							d3.select(this).attr({
								fill: color.range()[i],
								stroke: 'none',
								x: xScale.rangeBand() / 2,
								y: -5,
								'text-anchor': 'middle',
							});
						})

					function styleAxis(axis) {
						// style path
						axis.select('.domain').attr({
							fill: 'none',
							stroke: '#888',
							'stroke-width': 1,
							'font-size': '10px'
						});
						// style tick
						axis.selectAll('.tick line').attr({
							stroke: '#000',
							'stroke-width': 1,
							'font-size': '10px'
						})
					}

				});
				
				var dataset = [];
				dataset.push({ label:'Planning', count: vm.assetgroup.pg});
				dataset.push({ label:'Implementation', count: vm.assetgroup.ig});
				dataset.push({ label:'Maintenance', count: vm.assetgroup.mg});
				
				var width = document.getElementById('donut-group-chart').offsetWidth;
				var height = document.getElementById('donut-group-chart').offsetHeight;
				var radius = Math.min(width, height) / 2;
				var donutWidth = 40;
				var legendRectSize = 10;
				var legendSpacing = 3;

				var color = d3.scale.ordinal()
					.range(["#f6b278", "#F0E68C","#3CB371"]);

				var svg = d3.select('#donut-group-chart')
					.append('svg')
					.attr('width', width)
					.attr('height', height)
					.append('g')
					.attr('transform', 'translate(' + (width / 2) +
						',' + (height / 2) + ')');

				var arc = d3.svg.arc()
					.innerRadius(radius - donutWidth)
					.outerRadius(radius);

				var pie = d3.layout.pie()
					.value(function(d) {
						return d.count;
					})
					.sort(null);

				var tooltip = d3.select('#chart')
					.append('div')
					.attr('class', 'tooltip');

				tooltip.append('div')
					.attr('class', 'label');

				tooltip.append('div')
					.attr('class', 'count');

				tooltip.append('div')
					.attr('class', 'percent');

				dataset.forEach(function(d) {
					d.count = +d.count;
					d.enabled = true;
				});

				var path = svg.selectAll('path')
					.data(pie(dataset))
					.enter()
					.append('path')
					.attr('d', arc)
					.attr('fill', function(d, i) {
						return color(d.data.label + " - " + d.data.count);
					})
					.each(function(d) {
						this._current = d;
					});

				var tooltip3 = d3.select("body").append("div").attr("class", "toolTipA");

				path.on("mouseover", function(d) {
					tooltip3
						.style("left", d3.event.pageX - 50 + "px")
					.style("top", d3.event.pageY - 50 + "px")
					.style("display", "inline-block")
					.style("fill", "black")
					.html((d.data.label) + "<br>" + (d.value)); return tooltip3.style("visibility", "visible");
				})
					.on("mousemove", function() { return tooltip3.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px"); })
					.on("mouseout", function() { return tooltip3.style("visibility", "hidden"); });

				var legend = svg.selectAll('.legend')
					.data(color.domain())
					.enter()
					.append('g')
					.attr('class', 'legend')
					.attr('transform', function(d, i) {
						var height = legendRectSize + legendSpacing;
						var offset = height * color.domain().length / 2;
						var horz = -5 * legendRectSize;
						var vert = i * height - offset;
						return 'translate(' + horz + ',' + vert + ')';
					});

				legend.append('rect')
					.attr('width', legendRectSize)
					.attr('height', legendRectSize)
					.style('fill', color)
					.style('stroke', color)
					.on('click', function(label) {
						var rect = d3.select(this);
						var enabled = true;
						var totalEnabled = d3.sum(dataset.map(function(d) {
							return (d.enabled) ? 1 : 0;
						}));

						if (rect.attr('class') === 'disabled') {
							rect.attr('class', '');
						} else {
							if (totalEnabled < 2) return;
							rect.attr('class', 'disabled');
							enabled = false;
						}

						pie.value(function(d) {
							if (d.label === label) d.enabled = enabled;
							return (d.enabled) ? d.count : 0;
						});

						path = path.data(pie(dataset));

						path.transition()
							.duration(750)
							.attrTween('d', function(d) {
								var interpolate = d3.interpolate(this._current, d);
								this._current = interpolate(0);
								return function(t) {
									return arc(interpolate(t));
								};
							});
					});

				legend.append('text')
					.attr('x', legendRectSize + legendSpacing)
					.attr('y', legendRectSize - legendSpacing)
					.text(function(d) {
						return d;
					});
				WorkOrderGroup.query({
					page: pagingParams.page - 1,
					size: vm.itemsPerPage,
					sort: sort()
				}, onSuccess, onError);
				function sort() {
					var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
					if (vm.predicate !== 'id') {
						// result.push('id');
					}
					return result;
				}
				function onSuccess(data, headers) {
					vm.links = ParseLinks.parse(headers('link'));
					vm.totalItems = headers('X-Total-Count');
					vm.queryCount = vm.totalItems;
					var workOrdersRes = [];
					data.forEach(function(values) {
						var Cost = "";
						var pb = "";
						var totalDistance = 0.0;
						var resolvedDistance = 0.0;
						var unresolvedDistance = 0.0;
						var percentdistance = 0.0;
						var FiberStatus = "";
						var workOrderTypeAttrVal = values.workOrderTypeAttributeValues;

						workOrderTypeAttrVal.forEach(function(item) {
							if (item.workOrderDataTypeName == "Fiber Status") {
								FiberStatus = item.attributeValue;
							}

							if (item.name == "Cost") {
								Cost = item.attributeValue;
							} else if (item.name == "Power Budget") {
								pb = item.attributeValue;
							} else if (item.name == "Fiber Distance") {
								totalDistance = item.attributeValue;
							} else if (item.name == "Resolved Distance") {
								resolvedDistance = item.attributeValue;
							} else if (item.name == "Unresolved Distance") {
								unresolvedDistance = item.attributeValue;
							}
						});
						var total = parseFloat(unresolvedDistance) + parseFloat(resolvedDistance);
						var percent = (parseFloat(resolvedDistance) * 100) / total;
						if (total > 0 && parseFloat(resolvedDistance) > 0) {
							percentdistance = percent.toFixed(0);
						} else {
							percentdistance = 0;
						}
						if (values.status) {
							values.status = values.status.replace("NEW", "PLANNING PHASE");
							values.status = values.status.replace("INPROGRESS", "ROLL-OUT PHASE");
							values.status = values.status.replace("RESOLVED", "OPERATION PHASE");
						}
						var res = {
							id: values.id,
							description: values.description,
							address: values.address,
							escalated: values.escalated,
							status: values.status,
							reportedByUser: values.reportedByUser,
							assignedToUser: values.assignedToUser,
							workOrderTypeName: values.workOrderTypeName,
							pinLat: values.pinLat,
							pinLong: values.pinLong,
							workOrderType: values.workOrderType,
							priority: values.priority,
							fiberStatus: FiberStatus,
							resolved: resolvedDistance,
							unresolved: unresolvedDistance,
							percentdistance: percentdistance,
							groups: values.groups,
							workOrderCases: values.workOrderCases,
							geofenceName: values.geofenceName,
							workOrderTypeAttributeValues: values.workOrderTypeAttributeValues,
							Cost: "$" + Cost,
							pb: pb,
							totalDistance: totalDistance
						};

						workOrdersRes.push(res);
					});
					vm.page = pagingParams.page;
					vm.workOrders = [];
					workOrdersRes.forEach(function(workOrderVal) {
						workOrderVal.workOrderTypeAttributeValues.forEach(function(val) {
							if (val.name == "Resolved Distance") {
								workOrderVal.resolved = val.attributeValue;
							}
							if (val.name == "Unresolved Distance") {
								workOrderVal.unresolved = val.attributeValue;
							}
						});
						var total = parseFloat(workOrderVal.unresolved) + parseFloat(workOrderVal.resolved);
						var percent = (parseFloat(workOrderVal.resolved) * 100) / total;
						workOrderVal.totaldistance = total.toFixed(2);
						if (total > 0 && parseFloat(workOrderVal.resolved) > 0) {
							workOrderVal.percentdistance = percent.toFixed(0);
						} else {
							workOrderVal.percentdistance = 0;
						}
						vm.workOrders.push(workOrderVal);
					});
				}

				function onError(error) {
					AlertService.error(error.data.message);
				}

				var margin = { top: 20, right: 160, bottom: 35, left: 30 };

				var width = document.getElementById('stacked-chart-status').offsetWidth - margin.left - margin.right,
					height = 200 - margin.top - margin.bottom;

				var svg = d3.select('#stacked-chart-status')
					.append("svg")
					.attr("width", width + margin.left + margin.right)
					.attr("height", height + margin.top + margin.bottom)
					.append("g")
					.attr("transform", "translate(" + margin.left + "," + margin.top + ")");
				/* Data in strings like it would be if imported from a csv */


				var data = [];
				data.push(vm.workorderstatus);
				data.push(vm.trcasestatus);
				data.push(vm.assetstatus);

				// Transpose the data into layers
				var dataset = d3.layout.stack()(["mpProposed", "mpApproved", "bpProposed", "bpApproved"].map(function(statusCount) {
					return data.map(function(d) {
						return { x: d.status, y: +d[statusCount] };
					});
				}));


				// Set x, y and colors
				var x = d3.scale.ordinal()
					.domain(dataset[0].map(function(d) { return d.x; }))
					.rangeRoundBands([10, width - 10], 0.5);

				var y = d3.scale.linear()
					.domain([0, d3.max(dataset, function(d) { return d3.max(d, function(d) { return d.y0 + d.y; }); })])
					.range([height, 0]);

				var colors = ["#F0E68C", "#CD5C5C", "#6495ED", "#3CB371"];


				// Define and draw axes
				var yAxis = d3.svg.axis()
					.scale(y)
					.orient("left")
					.ticks(5)
					.tickSize(-width, 0, 0)
					.tickFormat(function(d) { return d });

				var xAxis = d3.svg.axis()
					.scale(x)
					.orient("bottom");
				//		  .tickFormat(d3.time.format("%Y"));

				svg.append("g")
					.attr("class", "y axis")
					.call(yAxis);

				svg.append("g")
					.attr("class", "x axis")
					.attr("transform", "translate(0," + height + ")")
					.call(xAxis);



				// Create groups for each series, rects for each segment 
				var groups = svg.selectAll("g.cost")
					.data(dataset)
					.enter().append("g")
					.attr("class", "cost")
					.style("fill", function(d, i) { return colors[i]; });

				var rect = groups.selectAll("rect")
					.data(function(d) { return d; })
					.enter()
					.append("rect")
					.attr("x", function(d) { return x(d.x); })
					.attr("y", function(d) { return y(d.y0 + d.y); })
					.attr("height", function(d) { return y(d.y0) - y(d.y0 + d.y); })
					.attr("width", x.rangeBand())
					.on("mouseover", function(d) { })
					.on("mouseout", function() { tooltip.style("display", "none"); })
					.on("mousemove", function(d) {
						var xPosition = d3.mouse(this)[0] - 15;
						var yPosition = d3.mouse(this)[1] - 25;
						tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
						tooltip.select("text").text(d.y);
					});




				// Draw legend
				var legend = svg.selectAll(".legend")
					.data(colors)
					.enter().append("g")
					.attr("class", "legend")
					.attr("transform", function(d, i) { return "translate(30," + i * 19 + ")"; });

				legend.append("rect")
					.attr("x", width - 18)
					.attr("width", 18)
					.attr("height", 18)
					.style("fill", function(d, i) { return colors.slice().reverse()[i]; });

				legend.append("text")
					.attr("x", width + 5)
					.attr("y", 9)
					.attr("dy", ".35em")
					.style("text-anchor", "start")
					.text(function(d, i) {
						switch (i) {
							case 0: return "MP Proposed";
							case 1: return "MP Approved";
							case 2: return "BP Proposed";
							case 3: return "BP Approved";
						}
					});

				var tooltip3 = d3.select("body").append("div").attr("class", "toolTipA");

				rect.on("mouseover", function(d) {
					tooltip3
						.style("left", d3.event.pageX - 50 + "px")
					.style("top", d3.event.pageY - 50 + "px")
					.style("display", "inline-block")
					.style("fill", "black")
					.html((d.x) + "<br>" + (d.y)); return tooltip3.style("visibility", "visible");
				})
					.on("mousemove", function() { return tooltip3.style("top", (d3.event.pageY - 10) + "px").style("left", (d3.event.pageX + 10) + "px"); })
					.on("mouseout", function() { return tooltip3.style("visibility", "hidden"); });
			});

			DashboardService.loaddata(function(data) {
				vm.users = data.users;
				vm.casepriority = data.casePriority;
			});
		}

		function filterSearch() {
			if (vm.search != null && vm.search != "" && !angular.isUndefined(vm.search)) {
				UserListAndDistanceSearch.query({
					login: vm.search,
					page: pagingParams.page - 1,
					size: vm.itemsPerPage,
					sort: sort()
				}, onSuccess, onError);
			} else {
				loadUserList();
			}
		}

		function loadPage(page) {
			vm.page = page;
			vm.transition();
		}

		function transition() {
			$state.transitionTo($state.$current, {
				page: vm.page,
				sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
				search: $scope.search
			});
		}

		function loadUserList() {
			UserListAndDistance.query({
				page: pagingParams.page - 1,
				size: vm.itemsPerPage,
				sort: sort()
			}, onSuccess, onError);
		}

		function sort() {
			var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
			if (vm.predicate !== 'id') {
				result.push('id');
			}
			return result;
		}

		function onSuccess(data) {
			vm.userslist = data;
			vm.page = pagingParams.page;
		}

		function onError(error) {
			AlertService.error(error.data.message);
		}

		$scope.options = {
			chart: {
				type: 'discreteBarChart',
				height: 450,
				margin: {
					top: 20,
					right: 20,
					bottom: 50,
					left: 55
				},
				x: function(d) { return d.label; },
				y: function(d) { return d.value + (1e-10); },
				showValues: true,
				valueFormat: function(d) {
					return d3.format(',.4f')(d);
				},
				duration: 500,
				xAxis: {
					axisLabel: 'X Axis'
				},
				yAxis: {
					axisLabel: 'Y Axis',
					axisLabelDistance: -10
				}
			}
		};

		$scope.data = [
			{
				key: "Cumulative Return",
				values: [
					{
						"label": "A",
						"value": -29.765957771107
					},
					{
						"label": "B",
						"value": 0
					},
					{
						"label": "C",
						"value": 32.807804682612
					},
					{
						"label": "D",
						"value": 196.45946739256
					},
					{
						"label": "E",
						"value": 0.19434030906893
					},
					{
						"label": "F",
						"value": -98.079782601442
					},
					{
						"label": "G",
						"value": -13.925743130903
					},
					{
						"label": "H",
						"value": -5.1387322875705
					}
				]
			}
		]

	}

})();