(function() {
    'use strict';

    angular.module('trakeyeApp').controller('AssetDialogController',
        AssetDialogController)

    .directive("fileread", [function() {
        'use strict';

        return {
            restrict: "A",

            link: function($scope, element) {

                element.change(function(event) {
                    $scope.$apply(function() {
                        $scope.myFile = element[0].files[0];
                        $scope.asset = $scope.assetname;

                    });
                });
            }

        };
    }]);

    AssetDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'AssetImages', 'GroupAssets', 'ParseLinks', 'AlertService',
        'entity', 'Asset', 'User', '$state', 'AssetUpload',
        'AllAssetsTypes', 'AllCustomAssetTypeAttribute', 'UserGroupIDs', 'AssetByID', 'AssetGroupSearch'
    ];

    function AssetDialogController($timeout, $scope, $stateParams, AssetImages, GroupAssets, ParseLinks, AlertService, entity,
        Asset, User, $state, AssetUpload, AllAssetsTypes,
        AllCustomAssetTypeAttribute, UserGroupIDs, AssetByID, AssetGroupSearch) {
        var vm = this;

        vm.asset = entity;
        var entity_old = angular.copy(vm.asset);
        vm.clear = clear;
        vm.save = save;
        vm.assetType = entity.assetType;

        vm.upload = upload;

        vm.assettypes = loadPage();
        vm.selectattributes = selectedAssetTypeAttributes;
        vm.coOrdinates = [];
        vm.coOrdinate = null;
        var newFence = false;
        var latlng;
        var locations = {};
        var centerlatlng = null;
        vm.customAttributesMap = {};
        var formData;
        var spreadAssets = [];
        vm.asset.assetImages = [];
        vm.parentLatLng = [];

        vm.navigateCreateAsset = navigateCreateAsset;
        vm.navigateCreateGroup = navigateCreateGroup;
        vm.navigateAssetType = navigateAssetType;
        vm.filterParentAssets = filterParentAssets;

        function loadPage() {

            vm.multgroup = [];
            var selctedGroup = [];

            vm.multiassets = [];
            vm.multiassetsLink = [];
            var selctedAssets = [];
			
            if (vm.asset.id != null) {
                vm.asset.ownedBy.forEach(function(asset) {
                    selctedAssets.push(asset.id);
					vm.selectedParent = asset.name;
					AssetGroupSearch.query({
	                    search: asset.name,
	                }, onSuccess, onError);
	
	                function onSuccess(data, headers) {
	                    vm.parentAssets = data;
	                }
	                function onError(error) {
	                    AlertService.error(error.data.message);
	                }
                });

                vm.asset.groups.forEach(function(group) {
                    selctedGroup.push(group.id);
                })
            }

            var assetTypeAttributeValuesList = vm.asset.assetTypeAttributeValues;

            try {
                for (var index in vm.asset.assetType.assetTypeAttributes) {
                    if (!checkIfAssetTypeExists(vm.asset.assetType.assetTypeAttributes[index], vm.asset.assetTypeAttributeValues)) {
                        var assetTypeAttribute1 = vm.asset.assetType.assetTypeAttributes[index];
                        assetTypeAttributeValuesList.push({ 'assetTypeAttribute': assetTypeAttribute1, 'attributeValue': vm.asset.assetType.assetTypeAttributes[index].defaultValue });
                    }
                }
            } catch (e) {}

            function checkIfAssetTypeExists(assetType, assetTypeAttrVal) {
                var hasAssetType = false;
                for (var i = 0; i < assetTypeAttrVal.length; i++) {
                    var name = assetTypeAttrVal[i].assetTypeAttribute.name;
                    if (name == assetType.name) {
                        hasAssetType = true;
                        break;
                    }
                }
                return hasAssetType;
            }

            assetTypeAttributeValuesList
                .sort(function(a, b) {
                    var nameA = a.assetTypeAttribute.name.toLowerCase(),
                        nameB = b.assetTypeAttribute.name.toLowerCase()
                    if (nameA < nameB) // sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 // default return value (no sorting)
                });
            vm.asset.assetTypeAttributeValues = assetTypeAttributeValuesList;

            UserGroupIDs.getAll(function(response) {
                response.forEach(function(group) {
                    if (selctedGroup.indexOf(group.id) !== -1) {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: true });
                    } else {
                        vm.multgroup.push({ name: group.name, id: group.id, ticked: false });
                    }
                });
            });
//            if (!entity.assetImport) {
//                getGroupAsset(0, vm.multiassets, selctedAssets);
//            }



            AllAssetsTypes.getAll(function(response) {
                vm.assettypes = response;
            });

            AllCustomAssetTypeAttribute
                .getAll(function(response) {
                    // vm.customCaseTypeAttributes = response;
                    response
                        .forEach(function(arrayItem) {
                            vm.customAttributesMap[arrayItem.name] = arrayItem.customAssetTypeAttributeValues;
                        });

                });

            vm.multiassets.sort(function(a, b) {
                var nameA = a.name.toLowerCase(),
                    nameB = b.name.toLowerCase()
                if (nameA < nameB) // sort string ascending
                    return -1
                if (nameA > nameB)
                    return 1
                return 0 // default return value (no sorting)
            });
        }
        function filterParentAssets() {
            // if (vm.asset.user.length > 2 && vm.asset.user != null && vm.asset.user != "" && vm.asset.user != "." && !angular.isUndefined(vm.asset.user)) {
				if(vm.selectedParent.length > 1){
	                AssetGroupSearch.query({
	                    search: vm.selectedParent,
	                }, onSuccess, onError);
	
	                function onSuccess(data, headers) {
	                    vm.parentAssets = data;
	                }
	                function onError(error) {
	                    AlertService.error(error.data.message);
	                }
				}
            // }
        }

        function getGroupAsset(pageNum, mapAssetLists, selctedAssets) {
            GroupAssets.query({
                page: pageNum,
                size: 500
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.multiassets = mapAssetLists;
                data.forEach(function(asset) {
                    if (selctedAssets.indexOf(asset.id) !== -1) {
                        vm.multiassets.push({
                            lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                            lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                            name: asset.name,
                            layout: asset.assetType.layout,
                            assetType: asset.assetTypeName,
                            assetCoordinates: asset.assetCoordinates,
                            id: asset.id,
                            ownedBy: asset.ownedBy,
                            ticked: true
                        });
                    } else {
                        try {
                            vm.multiassets.push({
                                lat: asset.assetCoordinates[asset.assetCoordinates.length - 1].latitude,
                                lng: asset.assetCoordinates[asset.assetCoordinates.length - 1].longitude,
                                name: asset.name,
                                assetType: asset.assetTypeName,
                                layout: asset.assetType.layout,
                                assetCoordinates: asset.assetCoordinates,
                                id: asset.id,
                                ownedBy: asset.ownedBy,
                                ticked: false
                            });
                        } catch (e) {
                            // TODO: handle exception
                        }

                    }
                });
                if (vm.assetTotalPage == 0) {
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.assetTotalPage = Math.ceil(vm.totalItems / 500);
                }

                vm.assetCurrentPage = pageNum;

                if (vm.assetTotalPage > 1) {
                    if (vm.assetCurrentPage != vm.assetTotalPage) {
//                        getGroupAsset(pageNum + 1, vm.multiassets, selctedAssets);
                    } else {
                        var lastAsset = vm.multiassets[vm.multiassets.length - 1];
                        if (lastAsset.assetCoordinates.length > 0) {
                            var latestLatLng = {
                                lat: lastAsset.assetCoordinates[0].latitude,
                                lng: lastAsset.assetCoordinates[0].longitude
                            };
                            map.setCenter(latestLatLng);
                        }
                    }
                }

            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function upload() {
            formData = new FormData();

            formData.append("assettype", vm.importassettype.name);
            formData.append('file', $scope.myFile);

            AssetUpload.assetupload(formData, function(response, headers) {
                if (response.status == 200) {
                    vm.error = null;
                    vm.success = 'OK';
                    $scope.spreadassetname = '';

                    $timeout(function() {
                        vm.success = '';
                    }, 4000);

                }
                if (response.status == 400) {
                    vm.success = null;
                    vm.error = 'error';

                }
            });

        }

        $(":file").filestyle({
            buttonBefore: true
        });
        // $(":file").filestyle('placeholder', 'Choose File');
        $(":file").filestyle('buttonText', 'Browse File');

        //		$scope.importxlsx = /^(.*?)\.(xlsx)$/;
        $scope.importxlsx = /^(.*?)\.(xlsx|kml)$/;

        /* import end */
        vm.createNewFence = function() {
            newFence = true;
            vm.asset.assetCoordinates = [];
            vm.createfence();
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $("#assetImages").change(function() {

            var filesSelected = document.getElementById("assetImages").files;
            for (var i = 0; i < filesSelected.length; i++) {
                var fileReader = new FileReader();

                fileReader.onload = function(fileLoadedEvent) {
                    vm.asset.assetImages.push({
                        image: window.btoa(fileLoadedEvent.target.result),
                        asset: {
                            id: entity_old.id
                        }
                    });
                }
                fileReader.readAsBinaryString(filesSelected[i]);
            }

        });

        $scope.toggle = function() {
            $('#soundtrack').get(0).play();
            $scope.allimages = !$scope.allimages;
            if ($scope.allimages) {

                AssetImages.query({
                    assetId: vm.asset.id

                }, onSuccess, onError);

                function onSuccess(data, headers) {
                    vm.assetImages = data;
                }

                function onError(error) {
                    AlertService.error(error.data.message);
                }

            }
        };

        function save() {
			if(vm.asset.assetCoordinates.length > 0){
				vm.asset.ownedBy = [];
				var parentLat = 0.0;
				var parentLng = 0.0;
				if(vm.parentAssets != null){
					vm.parentAssets.forEach(function(value){
						if(value.name == vm.selectedParent){
							var tempAsset = {};
							tempAsset.id = value.id;
							tempAsset.name = value.name;
							parentLat = value.assetCoordinates[value.assetCoordinates.length-1].latitude;
							parentLng = value.assetCoordinates[value.assetCoordinates.length-1].longitude;
							vm.asset.ownedBy.push(tempAsset);
						}
					});
				}
	            vm.isSaving = true;
				var fiberDistance = 0.0;
				if(vm.asset.assetType.layout == "SPREAD"){
					var spreadCoordinates = vm.asset.assetCoordinates;
	                if (spreadCoordinates.length > 0) {
	                    for (var index in spreadCoordinates) {
	                        if (index > 0) {
	                            fiberDistance += distanceCal(spreadCoordinates[index].latitude, spreadCoordinates[index].longitude, spreadCoordinates[index - 1].latitude, spreadCoordinates[index - 1].longitude);
	                        }
	                    }
	                }
	                fiberDistance = fiberDistance.toFixed(2);
	                var spreadAV = vm.asset.assetTypeAttributeValues;
	                for (var spreadAssetIndex = 0; spreadAssetIndex < spreadAV.length; spreadAssetIndex++) {
	                    if (spreadAV[spreadAssetIndex].assetTypeAttribute.name === "Fiber Distance (km)") {
	                        spreadAV[spreadAssetIndex].attributeValue = fiberDistance;
	                    }
	                }
					vm.asset.assetTypeAttributeValues = spreadAV;
					var tempCoordinates = [];
					tempCoordinates.push({latitude:parentLat, longitude:parentLng});
					vm.asset.assetCoordinates.forEach(function(value){
						tempCoordinates.push(value);
					});
					vm.asset.assetCoordinates = tempCoordinates;
				}
	            if (vm.asset.id !== null) {
	                Asset.update(vm.asset, onSaveSuccess, onSaveError);
	            } else {
	                Asset.save(vm.asset, onSaveSuccess, onSaveError);
	            }
			}else{
				alert("Asset Coordinates should not be empty");
			}
        }

        function onSaveSuccess(result) {
            vm.isSaving = true;
            if (vm.asset.groups.length > 0) {
                vm.isSaving = false;
                $scope.$emit("trakeyeApp:assetUpdate", result);
                var pageValue = sessionStorage.getItem("prevPage");
                var previousId = sessionStorage.getItem("prevId");
                var prevEditDetail = sessionStorage.getItem("prevEditId");
                var preEditPage = sessionStorage.getItem("firstEditPage");
                var preEditDetailPage = sessionStorage.getItem("firstEditDetailPage");

                var assetToassetVar = sessionStorage.getItem("assetCreateToassetEdit");
                var assetTypeToAssetVar = sessionStorage.getItem("AssetTypeToEditAsset");
                var groupToAssetVar = sessionStorage.getItem("groupToEditAsset");

                var groupToEditAssetDetailVar = sessionStorage.getItem("groupToEditAssetDetail");
                var AssetTypeToEditDetailsVar = sessionStorage.getItem("AssetTypeToEditDetails");
                var assetCreateToassetEditDetailsVar = sessionStorage.getItem("assetCreateToassetEditDetails");

                if (pageValue == "geofence.new") {
                    $state.go("geofence.new");
                } else if (pageValue == "asset") {
                    $state.go("asset");
                } else if (previousId) {
                    if ((preEditPage == "asset") || (groupToAssetVar == "groupToasset") || (assetTypeToAssetVar == "assetTypeToAsset") || (assetToassetVar == "assetToasset")) {
                        $state.go("asset", {
                            id: previousId
                        });
                    } else if (preEditPage == "assetEdit") {
                        sessionStorage.setItem("assetCreateToassetEdit", "assetToasset");
                        $state.go("asset.edit", {
                            id: previousId
                        });
                    }
                } else if (prevEditDetail) {
                    if ((preEditDetailPage == "assetDetail") || (groupToEditAssetDetailVar == "groupToassetDetail") || (AssetTypeToEditDetailsVar == "assetTypeToAssetDetails") || (assetCreateToassetEditDetailsVar == "assetToassetDetails")) {
                        $state.go("asset", {
                            'id': prevEditDetail
                        });
                    } else if (preEditDetailPage == "assetEditDetails") {
                        sessionStorage.setItem("assetCreateToassetEditDetails", "assetToassetDetails");
                        $state.go("asset-detail.edit", {
                            id: prevEditDetail
                        });

                    }
                } else {
                    $state.go("asset");
                }
            }
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function selectedAssetTypeAttributes() {
			vm.asset.ownedBy = [];
			if(vm.parentAssets != null){
				vm.parentAssets.forEach(function(value){
					if(value.name == vm.selectedParent){
						var tempAsset = {};
						tempAsset.id = value.id;
						tempAsset.lat = value.assetCoordinates[0].latitude;
						tempAsset.lng = value.assetCoordinates[0].longitude;
						vm.asset.ownedBy.push(tempAsset);
					}
				});
			}
            if (vm.assetType) {
                if (vm.asset.ownedBy != "") {
                    for (var i = 0; i < vm.multiassets.length; i++) {
                        if (vm.multiassets[i].id == vm.asset.ownedBy[0].id) {
                            if (vm.multiassets[i].layout == "FIXED") {
                                vm.fixedArray = [];
                                var coordinates = {};
                                coordinates.latitude = vm.multiassets[i].assetCoordinates[0].latitude;
                                coordinates.longitude = vm.multiassets[i].assetCoordinates[0].longitude;
                                vm.fixedArray.push(coordinates);
                                vm.asset.assetCoordinates = vm.fixedArray;
                                vm.parentLatLng.push({latitude:vm.multiassets[i].assetCoordinates[0].latitude, longitude: vm.multiassets[i].assetCoordinates[0].longitude});
                            }

                        }
                    }
                }

                vm.asset.assetType = vm.assetType;
                if (entity_old.assetType &&
                    vm.assetType.id === entity_old.assetType.id) {
                    vm.asset.assetTypeAttributeValues = [];
                    vm.asset.assetTypeAttributeValues = entity_old.assetTypeAttributeValues;
                } else {
                    vm.asset.assetTypeAttributeValues = [];
                    $.each(
                        vm.assetType.assetTypeAttributes,
                        function(key, value) {
                            vm.asset.assetTypeAttributeValues
                                .push({
                                    assetTypeAttribute: vm.assetType.assetTypeAttributes[key]
                                });
                        });
                }

            }

        }
        vm.createfence = function() {
			var elem = document.getElementById("map_canvas");
			vm.asset.ownedBy = [];
			if(vm.parentAssets != null){
				vm.parentAssets.forEach(function(value){
					if(value.name == vm.selectedParent){
						var tempAsset = {};
						tempAsset.id = value.id;
						tempAsset.lat = value.assetCoordinates[0].latitude;
						tempAsset.lng = value.assetCoordinates[0].longitude;
						vm.asset.ownedBy.push(tempAsset);
					}
				});
			}
            //            if (elem.requestFullscreen) {
            //                elem.requestFullscreen();
            //            } else if (elem.mozRequestFullScreen) { /* Firefox */
            //                elem.mozRequestFullScreen();
            //            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            //                elem.webkitRequestFullscreen();
            //            } else if (elem.msRequestFullscreen) { /* IE/Edge */
            //                elem.msRequestFullscreen();
            //            }
            if (vm.asset.ownedBy == "") {
                var map = new google.maps.Map(document
                    .getElementById('map_canvas'), {
                        center: {
                            lat: 5.431587,
                            lng: 101.128162
                        },
                        zoom: 13,
                        fullscreenControl: true
                    });
            } else {
                var coordinates = [];
                if (vm.asset.ownedBy.length > 0) {
                        coordinates.push({
                            lat: vm.asset.ownedBy[0].lat,
                            lng: vm.asset.ownedBy[0].lng
                        });
                    }
                var map = new google.maps.Map(document
                    .getElementById('map_canvas'), {
                        center: coordinates[0],
                        zoom: 13,
                        fullscreenControl: true
                    });
            }

            if (vm.assetType.layout == 'SPREAD') {
                drawingManagerSpread();
                drawingManagerValue('polylinecomplete', map);
            } else if (vm.assetType.layout == 'FIXED') {
                drawingManagerFixed();
                drawingManagerValue('click', map);
            }

            if (entity.id && entity.id != null && !newFence) {
                if (vm.assetType.layout == 'SPREAD') {
                    drawPolyline(function(polyline) {
                        polyline.setMap(map);
                        google.maps.event.addListener(polyline.getPath(),
                            'insert_at',
                            function() {
                                setGoefenceCoordinate(polyline);
                            });
                        google.maps.event.addListener(polyline.getPath(),
                            'set_at',
                            function(path) {
                                setGoefenceCoordinate(polyline);
                            });

                        vm.drawingManager.setOptions({
                            drawingControl: false
                        });
                        vm.drawingManager.setMap(null);

                    })
                } else if (vm.assetType.layout == 'FIXED') {
                    if (vm.asset.assetCoordinates.length > 1) {
                        drawPolyline(function(polyline) {
                            polyline.setMap(map);
                            google.maps.event.addListener(polyline.getPath(),
                                'insert_at',
                                function() {
                                    setGoefenceCoordinate(polyline);
                                });
                            google.maps.event.addListener(polyline.getPath(),
                                'set_at',
                                function(path) {
                                    setGoefenceCoordinate(polyline);
                                });

                            vm.drawingManager.setOptions({
                                drawingControl: false
                            });
                            vm.drawingManager.setMap(null);

                        })
                    } else {
                        var latitudelongitude = {
                            latitude: vm.asset.assetCoordinates.latitude,
                            longitude: vm.asset.assetCoordinates.longitude
                        };

                        setValues();
                        drawingManagerFixed();
                        drawMarker(map, vm.coOrdinate);
                        // drawFixedAssetImages(vm.coOrdinate);
                    }
                }

            }


            function drawPolyline(callback) {

                setValues();
                JSON.parse(vm.coOrdinate).forEach(function(value, key) {
                    centerlatlng = {
                        lat: value.lat,
                        lng: value.lng
                    };
                });
                callback(new google.maps.Polyline({
                    path: JSON.parse(vm.coOrdinate),
                    geodesic: true,
                    strokeColor: vm.asset.assetType.colorcode,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: vm.asset.assetType.colorcode,
                    fillOpacity: 0.3,
                    editable: true
                }));
                map.setCenter(centerlatlng);
            }

            spreadAssets = [];
            var multiAssetList = vm.multiassets;
            var latlngs = [];
            for (var i = 0; i < multiAssetList.length; i++) {
                var assetCoordinateValues = multiAssetList[i].assetCoordinates;
                if (assetCoordinateValues.length == 1) {
                    latlngs.push({
                        lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
                        lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude,
                        name: multiAssetList[i].name,
                        assetType: multiAssetList[i].assetType
                    });
                }
                drawPolylineForGeneratedLoc(assetCoordinateValues, map, "yellow");
            }

            for (var i = 0; i < vm.multiassetsLink.length; i++) {
                var assetCoordinateValues = vm.multiassetsLink[i].assetCoordinates;
                drawPolylineForGeneratedLoc(assetCoordinateValues, map, "yellow");
            }

            for (var i = 0; i < latlngs.length; i++) {
                var myLatLng = ({ lat: latlngs[i].lat, lng: latlngs[i].lng });
                if (latlngs[i].assetType != "WDM" && latlngs[i].assetType != "Router" && latlngs[i].assetType != "NTTN" && latlngs[i].assetType != "Others" && latlngs[i].assetType != "BackToBack") {
                    createMarkerOfAllAssets(myLatLng, map, latlngs[i].name);
                }
            }

            for (var i = 0; i < multiAssetList.length; i++) {
                var array_element = multiAssetList[i];
                if (array_element.layout == "SPREAD") {
                    spreadAssets.push(array_element);
                }
            }
            for (var i = 0; i < spreadAssets.length; i++) {
                var spreadAssetAC = spreadAssets[i].assetCoordinates;
                for (var j = 0; j < spreadAssetAC.length; j++) {
                    if (j > 0) {
                        var locValues = [];
                        locValues.push(spreadAssetAC[j - 1]);
                        locValues.push(spreadAssetAC[j]);
                        drawPolylineForGeneratedLoc(locValues, map, "black");
                    }
                }
            }

        };

        function navigateCreateAsset() {
            var previousId = sessionStorage.getItem("prevId");
            var editDetailValue = sessionStorage.getItem("prevEditId");
            sessionStorage.setItem("firstEditPage", "assetEdit");
            sessionStorage.setItem("firstEditDetailPage", "assetEditDetails");
            if (previousId) {
                $state.go('asset.new', {
                    'id': previousId
                });
            }
            if (editDetailValue) {
                $state.go('asset.new', {
                    'id': editDetailValue
                });
            }
        }

        function navigateCreateGroup() {
            var previousId = sessionStorage.getItem("prevId");
            var editDetailValue = sessionStorage.getItem("prevEditId");
            if (previousId) {
                $state.go("group.new", {
                    id: previousId
                });
            } else if (editDetailValue) {
                $state.go("group.new", {
                    id: editDetailValue
                });
            }
        };

        function navigateAssetType() {
            var pageValue = sessionStorage.getItem("prevId");
            var editDetailValue = sessionStorage.getItem("prevEditId");
            if (pageValue) {
                $state.go("asset-type.new", {
                    id: pageValue
                });
            } else if (editDetailValue) {
                $state.go("asset-type.new", {
                    id: editDetailValue
                });
            }
        };

        function createMarkerOfAllAssets(myLatLng, map, myLocName, index) {
            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                icon: {
                    scaledSize: new google.maps.Size(
                        24, 24),
                    origin: new google.maps.Point(0, 0),
                    url: 'content/images/yellow.png',
                    anchor: new google.maps.Point(
                        16, 16),

                }
                //                label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: myLocName, backgroundcolor: "#ff9800" },
            });

        }

        function drawingManagerSpread() {
            vm.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.POLYLINE,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['polyline']
                },
                polylineOptions: {
                    fillColor: '#ffff00',
                    fillOpacity: 1,
                    strokeWeight: 5,
                    clickable: true,

                    editable: true,
                    zIndex: 1,
                    geodesic: true
                }
            });
        }

        function drawingManagerFixed() {
            vm.drawingManager = new google.maps.drawing.DrawingManager({
                drawingMode: google.maps.drawing.OverlayType.MARKER,
                drawingControl: true,
                drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: ['marker']
                },
                markerOptions: {
                    icon: {
                        size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        // url : "data:image/png;base64," +
                        // vm.asset.assetType.image,
                        // url : vm.asset.assetType.imagePath,
                        anchor: new google.maps.Point(16, 16)
                    },
                }

            });
        }

        var recursiveOwnedByAsset = function(ownedBy) {
            var names = [];
            if (ownedBy.length > 0) {
                if (ownedBy[0].ownedBy != null) {
                    if (ownedBy[0].ownedBy.length > 0) {
                        var assetCoordinateValues2 = ownedBy[0].ownedBy;
                        names
                            .push({
                                lat: assetCoordinateValues2[0].assetCoordinates[0].latitude,
                                lng: assetCoordinateValues2[0].assetCoordinates[0].longitude
                            });
                        if (assetCoordinateValues2.length > 0) {
                            Array.prototype.push.apply(names,
                                recursiveOwnedByAsset(assetCoordinateValues2));
                        }
                    }
                }
            }
            return names;
        };

        function drawPolylineForGeneratedLoc(latlngsToDraw, map, color) {
            var spreadAssetCoordinates = [];
            $.each(latlngsToDraw, function(index, value) {

                spreadAssetCoordinates.push({
                    lat: value.latitude,
                    lng: value.longitude
                });
            });

            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                    lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
                    lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
                };
                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        "name" +
                        '<div class="infoWindowContent"> <b>Description :</b> ' +
                        "des" + '</div></div>'
                });
                infowindow.setPosition(midLatLng);

                var ColorValue = '#ff0000';
                if (color == "red") {
                    ColorValue = '#ff0000';
                } else if (color == "black") {
                    ColorValue = '#000000';
                } else if (color == "yellow") {
                    ColorValue = '#F4A500';
                } else {
                    ColorValue = '#208000';
                }
                var polylinepath = new google.maps.Polyline({
                    path: spreadAssetCoordinates,
                    geodesic: true,
                    strokeColor: ColorValue,
                    strokeOpacity: 1.0,
                    strokeWeight: 2.5,
                    fillColor: ColorValue,
                    fillOpacity: 0.3,
                    editable: false
                });

                polylinepath.setOptions({
                    strokeColor: ColorValue
                });

                polylinepath.setMap(map);
            }
        }

        function drawingManagerValue(option, map) {
            var parent = {};
            for (var i = 0; i < vm.multiassets.length; i++) {
                if (vm.multiassets[i].ticked == true) {
                    parent = vm.multiassets[i];
                }
            }
            if (parent.id != null) {
                AssetByID.get(parent.id, function(response) {
                    parent = response;
                    var latlngs = [];
                    try {
                        var len = parent.ownedBy;
                        var assetCoordinateValuesParent = parent.assetCoordinates;
                        latlngs.push({
                            lat: assetCoordinateValuesParent[assetCoordinateValuesParent.length - 1].latitude,
                            lng: assetCoordinateValuesParent[assetCoordinateValuesParent.length - 1].longitude
                        });

                        if (len.length > 0) {
                            var assetCoordinateValues = len[0].assetCoordinates;
                            latlngs.push({
                                lat: assetCoordinateValues[assetCoordinateValues.length - 1].latitude,
                                lng: assetCoordinateValues[assetCoordinateValues.length - 1].longitude
                            });

                            if (recursiveOwnedByAsset(len) != null) {
                                Array.prototype.push.apply(latlngs,
                                    recursiveOwnedByAsset(len));
                            }
                        }

                        for (var i = 0; i < latlngs.length; i++) {
                            createMarker(latlngs[i], map, i);
                        }

                        if (latlngs.length > 0) {
                            moveToLocation(latlngs[0].lat, latlngs[0].lng, map);
                            map.setZoom(16);
                        }


                    } catch (err) {
                        var event232 = err;
                    }
                });
            }

            if (option == 'polylinecomplete') {
                google.maps.event.addListener(vm.drawingManager, option,
                    function(event) {
                        google.maps.event.addListener(event.getPath(),
                            'insert_at',
                            function() {
                                setGoefenceCoordinate(event);
                            });
                        google.maps.event.addListener(event.getPath(),
                            'set_at',
                            function(path) {
                                setGoefenceCoordinate(event);
                            });
                        vm.drawingManager.setOptions({
                            drawingControl: false
                        });
                        vm.drawingManager.setMap(null);
                        setGoefenceCoordinate(event);
                    });
                var assetCoorVal = [];

				vm.parentLatLng.forEach(function(value){
					assetCoorVal.push(value);
				});
                vm.asset.assetCoordinates.forEach(function(assetlatlng) {
                    assetCoorVal.push(assetlatlng);
                });
				vm.asset.assetCoordinates = assetCoorVal;
                vm.drawingManager.setMap(map);
            } else if (option == 'click') {
                google.maps.event.addListener(map, 'click', function(e) {
                    placeMarker(e.latLng, map);
                    vm.fixedArray = [];
                    var coordinates = {};
                    coordinates.latitude = e.latLng.lat();
                    coordinates.longitude = e.latLng.lng();
                    vm.fixedArray.push(coordinates);
                    vm.asset.assetCoordinates = vm.fixedArray;
                });
            }
        }

        function moveToLocation(lat, lng, map) {
            var center = new google.maps.LatLng(lat, lng);
            // using global variable:
            map.panTo(center);
        }

        function createMarker(myLatLng, map, index) {
            if (index == 0) {
                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    animation: google.maps.Animation.DROP
                });
            } else {
                var marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                });
            }
        }

        var marker;

        function placeMarker(location, map) {

            if (marker) {
                marker.setPosition(location);
            } else {
                marker = new google.maps.Marker({
                    position: location,
                    map: map,
                    icon: {
                        size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        // url : "data:image/png;base64," +
                        // vm.asset.assetType.image,
                        // url : vm.asset.assetType.imagePath,
                        anchor: new google.maps.Point(16, 16)
                    },
                });
            }
        }
        $scope.mapEvents = {
            "click": function() {

            }
        }

		function distanceCal(lat1, lon1, lat2, lon2) {
            var theta = lon1 - lon2;
            var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2)) +
                Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
            dist = Math.acos(dist);
            dist = radians_to_degrees(dist);
            dist = dist * 60 * 1.1515;
            dist = dist * 1.609344;
            if (isNaN(dist)) {
                return 0.0;
            } else {
                return (dist);
            }
        }

        function degrees_to_radians(degrees) {
            var pi = Math.PI;
            return degrees * (pi / 180);
        }

        function radians_to_degrees(radians) {
            var pi = Math.PI;
            return radians * (180 / pi);
        }

        function setGoefenceCoordinate(event) {
            vm.assetCoordinateValues = [];
            vm.asset.assetCoordinates = [];

            var radius = event.getPath();
            for (var i = 0; i < radius.getLength(); i++) {
                var coordinates = {};
                coordinates.latitude = radius.getAt(i).lat();
                coordinates.longitude = radius.getAt(i).lng();
                vm.asset.assetCoordinates.push(coordinates);
            }
        }

        function setValues() {
            vm.coOrdinates = [];
            for (var i = 0; i < vm.asset.assetCoordinates.length; i++) {
                vm.coOrdinates.push({
                    lat: vm.asset.assetCoordinates[i].latitude,
                    lng: vm.asset.assetCoordinates[i].longitude
                });
                if (i == vm.asset.assetCoordinates.length - 1) {
                    vm.coOrdinate = JSON.stringify(vm.coOrdinates);
                }
            }
        }

        function drawMarker(map, response) {
            $.each(JSON.parse(response), function(index, value) {
                centerlatlng = {
                    lat: value.lat,
                    lng: value.lng
                };

                var startMarker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(value.lat, value.lng),

                    icon: {
                        size: new google.maps.Size(220, 220),
                        scaledSize: new google.maps.Size(32, 32),
                        origin: new google.maps.Point(0, 0),
                        // url : "data:image/png;base64," +
                        // vm.asset.assetType.image,
                        // url : vm.asset.assetType.imagePath,
                        anchor: new google.maps.Point(16, 16)
                    },
                });
            });
            map.setCenter(centerlatlng);
        }

    }
})();