(function() {
	'use strict';

	angular.module('trakeyeApp').controller('CaseAgingReportController', CaseAgingReportController)
	angular.module('trakeyeApp').filter('trustFilter', ['$sce', function($sce){
    return function(text) {
        return $sce.trustAsHtml(text);
    	};
	}]);

	CaseAgingReportController.$inject = [ '$scope','$sce' ,'$state', "Report", '$rootScope', '$timeout', '$window', '$filter', '$interval','Principal' ];

	function CaseAgingReportController($scope, $sce,$state, Report, $rootScope, $timeout, $window, $filter, $interval,Principal) {
		var vm = this;
		vm.users = [];
		$scope.reporthide = false;
		vm.reportUser = $rootScope.userName;
		vm.openCalendar = openCalendar;
		vm.users = loadPage();
		var dateFormat = 'dd EEE HH:mm';
		var date = "dd EEE";
		vm.fromTime = 0;
		vm.toTime = 0;
		vm.today = today();
		vm.datePickerOpenStatus = {};
		vm.reportGenerated = false;
		vm.reportGeneratedFileName='';
		
		function openCalendar(date) {
			vm.datePickerOpenStatus[date] = true;
		}
		
		$(".select2").select2(); // Added search for select box
		function today() {
			var today = new Date();
			vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		vm.calculateToDate = calculateToDate;
		vm.calculateFromDate = calculateFromDate;
		function calculateFromDate() {

			vm.fromDate.setHours(0, 0, 0, 0);
			vm.fromTime = vm.fromDate.getTime();
		}
		function calculateToDate() {

			vm.toDate.setHours(23, 59, 59, 999);
			vm.toTime = vm.toDate.getTime();
		}
		
		function loadPage(){
			Report.getUsers('', function(response) {
				vm.users = response;
			})
		}
		$scope.loader = false;
		$(function() {
			$scope.getPdf = function() {
				$scope.loader = true;
				html2canvas(reportpdf, {
					onrendered : function(canvas) {
						var img = canvas.toDataURL("image/png");
						var pdf = new jsPDF('landscape', 'mm', 'a2');
						pdf.addImage(img, 'JPEG', 0, 0);
						pdf.save($scope.pdftype);
						$scope.loader = false;
					}
				});
				$interval(function() {
				}, 1000);
			}
		});
		function select2() {
			$(".select2").select2();
		}
		$scope.report = function() {
				Report.getCasesAgingReport({
					reportType:"USER",
					ageType:vm.dateRange,
					login:vm.reportUser,
					fromDateTime : vm.fromTime,
					toDateTime : vm.toTime
				}, function(response) {
					$scope.myhtml = response;
				});
				
				vm.reportGenerated = true;


		}
		
		
		
		$scope.download = function() {
		       // $window.open("/images/CaseAgingReportByYears.xls");
				if(vm.dateRange === 'DAYS'){
				 window.location.href = "/images/CaseAgingReportByDays.xls";
			 	}else if(vm.dateRange === 'WEEKS'){
			 		window.location.href = "/images/CaseAgingReportByWeeks.xls";
				}else if(vm.dateRange === 'MONTHS'){
					window.location.href = "/images/CaseAgingReportByMonths.xls";
				}else if(vm.dateRange === 'YEARS'){
					window.location.href = "/images/CaseAgingReportByYears.xls";
				}
		    }

		
	}

})();
