(function() {
	'use strict';

	angular.module('trakeyeApp').controller('WorkOrderDialogController', WorkOrderDialogController).directive('mySrc', function() {
		return {
			restrict: 'A',
			scope: {
				callback: '&'
			},
			link: function(scope, elem, attrs) {
				elem.attr('src', scope.callback());
			}
		};
	})
	WorkOrderDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'AssetByID','TrUnresolvedGroupCaseForMap', 'TrCaseById', 'TrUnresolvedLocGroupCaseForMap', 'pagingParams', 'entity', 'WorkOrder', 'UserGroupIDs', 'User', 'WorkOrderType', 'Principal', 'Asset', '$state', 'ActivatedUsers', 'UserValueSearch',
		'AlertService', 'ActivatedUserSearch', 'WorkOrderUpload', 'AssetSearchForMap', 'AssetSearch', 'AllWorkOrderTypes', 'AllCustomWorkOrderTypeAttribute', '$ngConfirm'
	];

	function WorkOrderDialogController($timeout, $scope, $stateParams, $q, AssetByID, TrUnresolvedGroupCaseForMap, TrCaseById, TrUnresolvedLocGroupCaseForMap, pagingParams, entity, WorkOrder, UserGroupIDs, User, WorkOrderType, Principal, Asset, $state, ActivatedUsers, UserValueSearch, AlertService, ActivatedUserSearch,
		WorkOrderUpload, AssetSearchForMap, AssetSearch, AllWorkOrderTypes, AllCustomWorkOrderTypeAttribute, $ngConfirm) {
		var vm = this;

		vm.workOrder = entity;

		var entity_old = angular.copy(vm.workOrder);
		vm.datePickerOpenStatus = {};
		vm.save = save;
		vm.assetList = "";
		vm.customAttributesMap = {};
		var usersMap = {};
		$scope.model = {};
		vm.selectedWorkFlows = [];
		var map = null;
        var markers = [];
        var polylines = [];
		var workFlowLat = null;
		var workFlowLng = null;
		var workFlowDistance = null;
		var openedInfoWindow = null;
		vm.resolved = 0.0;
		vm.unresolved = 0.0;
		vm.navigateGroupfromWorkOrder = navigateGroupfromWorkOrder;
		vm.navigateWorkOrderTypefromWorkOrder = navigateWorkOrderTypefromWorkOrder;
		
		$(".select2").select2();
		
		$(":file").filestyle({
			buttonBefore: true
		});
		
		$(":file").filestyle('buttonText', 'Browse File');
		
		vm.workOrderTypes = loadPage();
		vm.selectattributes = selectedWorkOrderTypeAttributes;
		
		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});
		
		vm.workOrderType = entity.workOrderType;

		function selectedWorkOrderTypeAttributes() {
			if (vm.workOrderType) {
				vm.workOrder.workOrderType = vm.workOrderType;
				if (entity_old.workOrderType && vm.workOrderType.id === entity_old.workOrderType.id) {
					vm.workOrder.workOrderTypeAttributeValues = [];
					vm.workOrder.workOrderTypeAttributeValues = entity_old.workOrderTypeAttributeValues;
				} else {
					vm.workOrder.workOrderTypeAttributeValues = [];
					$.each(vm.workOrderType.workOrderTypeAttribute, function(key, value) {
						vm.workOrder.workOrderTypeAttributeValues.push({
							workOrderTypeAttribute: vm.workOrderType.workOrderTypeAttribute[key]
						});
					});
				}
			}

		}

		function loadPage() {

			vm.multgroup = [];
			var selectedGroups = [];
			var workorderCasesList = [];

			if (vm.workOrder.id != null) {
				vm.workOrder.groups.forEach(function(group) {
					selectedGroups.push(group.id);
				});
				vm.workOrder.workOrderCases.forEach(function(caseVal){
					workorderCasesList.push(caseVal);
				});
			}

			AllWorkOrderTypes.getAll(function(response) {
				vm.workOrderTypes = response;
			});

			
			UserGroupIDs.getAll(function(response) {
				response.forEach(function(group) {
					if (selectedGroups.indexOf(group.id) !== -1) {
						vm.multgroup.push({ name: group.name, id: group.id, ticked: true });
					} else {
						vm.multgroup.push({ name: group.name, id: group.id, ticked: false });
					}
				});
			});

			AllCustomWorkOrderTypeAttribute.getAll(function(response) {
				response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customWorkOrderTypeAttributeValues;
				});

			});
			
			
			TrUnresolvedGroupCaseForMap.getAllCases(function(response) {
				vm.trCases = [];
				response.forEach(function(caseVal){
					workorderCasesList.forEach(function(caseValChecked){
						if(caseValChecked.id == caseVal.id){
							caseVal.isChecked = true;
							caseValChecked.isChecked = true;
							
							vm.selectedWorkFlows.push(caseValChecked);
							showCaseAssetsOnMap(caseValChecked);
						}
					});
					vm.trCases.push(caseVal);
				});
			});
			var myOptions = {
				zoom: 10,
				center: new google.maps.LatLng("5.431587","101.128162"),
				mapTypeId: google.maps.MapTypeId.ROADMAP
			};

			map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
							if(map != null){
				for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
			}

		}
		// This executes when workflow checkbox checked/unchecked
		$scope.selectEntity = function() {
			if(map != null){
				for (var i = 0; i < markers.length; i++) {
                    markers[i].setMap(null);
                }

                for (i = 0; i < polylines.length; i++) {
                    polylines[i].setMap(null);
                }

                markers = [];
                polylines = [];
			}
			vm.resolved = 0.0;
			vm.unresolved = 0.0;
			vm.selectedWorkFlows = [];
			vm.trCases.forEach(function(caseVal){
				if(caseVal.isChecked){
					if(caseVal.status == "RESOLVED"){
						vm.resolved += parseFloat(caseVal.fiberDistance);
					}else{
						vm.unresolved += parseFloat(caseVal.fiberDistance);
					}
					TrCaseById.get(caseVal.id,function(caseVal){
						caseVal.groups = vm.workOrder.groups;
						vm.selectedWorkFlows.push(caseVal);
						if(caseVal.caseAssets.length > 0){
							showCaseAssetsOnMap(caseVal);
						}
					});
				}
			});
			
			var totalDistance = vm.resolved + vm.unresolved ;
			var workOrderAV = vm.workOrder.workOrderTypeAttributeValues;
            for (var workOrderIndex = 0; workOrderIndex < workOrderAV.length; workOrderIndex++) {
				if (workOrderAV[workOrderIndex].workOrderTypeAttribute.name === "Fiber Distance") {
                    workOrderAV[workOrderIndex].attributeValue = totalDistance.toFixed(2);
                } else if (workOrderAV[workOrderIndex].workOrderTypeAttribute.name === "Unresolved Distance") {
                    workOrderAV[workOrderIndex].attributeValue = vm.unresolved.toFixed(2);
                } else if (workOrderAV[workOrderIndex].workOrderTypeAttribute.name === "Resolved Distance") {
                    workOrderAV[workOrderIndex].attributeValue = vm.resolved.toFixed(2);
                }
            }
            vm.workOrder.workOrderTypeAttributeValues = workOrderAV;
		};
	
		function showCaseAssetsOnMap(caseVal){
			caseVal.caseAssets.forEach(function(assetVal){
					if (assetVal.assetType.layout == 'SPREAD' && assetVal.assetCoordinates != null) {
						
						var spreadAssetCoordinates = [];
		            	assetVal.assetCoordinates.forEach(function(loc){
							spreadAssetCoordinates.push({
		                        lat: loc.latitude,
		                        lng: loc.longitude
		                    });
						});
						var polylineColor = '#000000';
		                var polylinepath = new google.maps.Polyline({
	                        path: spreadAssetCoordinates,
	                        geodesic: true,
	                        strokeColor: polylineColor,
	                        strokeOpacity: 1.0,
	                        strokeWeight: 2.5,
	                        fillColor: polylineColor,
	                        fillOpacity: 0.3,
	                        editable: false
	                    });
						

						polylinepath.setMap(map);
						
						polylines.push(polylinepath);
						try{
							var pos = {
				                lat: assetVal.assetCoordinates[0].latitude,
				                lng: assetVal.assetCoordinates[0].longitude
				            };
							map.setCenter(pos);
							
							var midLatLng = {
				                    lat: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lat,
				                    lng: spreadAssetCoordinates[spreadAssetCoordinates.length - 1].lng
				                };
				                var infowindow = new google.maps.InfoWindow({
				                    content: '<div class="infoWindowhead">' +
				                        "name" +
				                        '<div class="infoWindowContent"> <b>Name :</b> ' +
				                        assetVal.name + '</div></div>'
				                });
				
				                infowindow.setPosition(midLatLng);
							google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
			                    infowindow.close();
			                });
			
			                google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
			                    if (openedInfoWindow != null) {
			                        openedInfoWindow.close();
			                    }
			                    infowindow.setContent('<div class="infoWindowhead">' +
			                        '<b></b>' + assetVal.name + '</br></div>'
			                    );
			
			                    infowindow.mousemove;
			                    infowindow.open(map);
			                    infowindow.setPosition(event.latLng);
			                    openedInfoWindow = infowindow;
			                });
						}catch(err){}
		            } else if (assetVal.assetType.layout == 'FIXED'  && assetVal.assetCoordinates != null) {
						try{
			                createMarker(assetVal.assetCoordinates[0], map);
							var pos = {
				                lat: assetVal.assetCoordinates[0].latitude,
				                lng: assetVal.assetCoordinates[0].longitude
				            };
							map.setCenter(pos);
						}catch(err){}
	                }
				});
		}
		
		function createMarker(latlngs, map) {
			try {
				var pos = {
	                lat: latlngs.latitude,
	                lng: latlngs.longitude
	            };

	            var marker = new google.maps.Marker({
	                position: pos,
	                map: map,
	                icon: 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
				});
				
				marker.setMap(map);
				markers.push(marker);

                var infowindow = new google.maps.InfoWindow({
                    content: '<div class="infoWindowhead">' +
                        "name" +
                        '<div class="infoWindowContent"> <b>Name :</b> ' +
                        assetVal.name + '</div></div>'
                });

                infowindow.setPosition(pos);
				google.maps.event.addListener(marker, 'mouseout', function(event) {
                    infowindow.close();
                });

                google.maps.event.addListener(marker, 'mouseover', function(event) {
                    if (openedInfoWindow != null) {
                        openedInfoWindow.close();
                    }
                    infowindow.setContent('<div class="infoWindowhead">' +
                        '<b></b>' + assetVal.name + '</br></div>'
                    );

                    infowindow.mousemove;
                    infowindow.open(map);
                    infowindow.setPosition(event.latLng);
                    openedInfoWindow = infowindow;
                });
				// markers.push(marker);
			}
			catch(err) {}
		}
		
		vm.createfence = function() {
			    $("#workflowsModal").show();
                var myCenter = new google.maps.LatLng("5.431587","101.128162");
                var ele = document.getElementById("workflowsModal");
                $(ele).modal('show');
                //
                var locMap = new google.maps.Map(document.getElementById('map_canvas_workflows'), {
		                        center: myCenter,
		                        zoom: 14
		                    });
				 var address = new google.maps.Geocoder();
			
		        google.maps.event.addListener(locMap, 'click', function(event) {
		
		            address.geocode({
		                'latLng': event.latLng
		            }, function(results, status) {
		                if (status == google.maps.GeocoderStatus.OK) {
		                    if (results[0]) {
		                        $ngConfirm({
		                            title: 'Are you sure you want to select this address?',
		                            content: '<strong>Address: </strong>' + results[0].formatted_address + '<br/>' + '<strong>Latitude: </strong>' + event.latLng.lat() + '</br>' +
		                                '<strong>Longitude: </strong>' + event.latLng.lng(),
		                            type: "blue",
		                            typeAnimated: true,
		                            buttons: {
		                                OK: {
		                                    text: 'OK',
		                                    btnClass: 'btn-primary',
		                                    action: function() {
		                                        $scope.$apply(function() {
													workFlowLat = event.latLng.lat();
													workFlowLng = event.latLng.lng();
													workFlowDistance = document.getElementById("workflow_distance").value;
													TrUnresolvedLocGroupCaseForMap.getcases(workFlowLat, workFlowLng, workFlowDistance, function (response) {
															vm.trCases = response;
				
															var workOrderCasesList = vm.workOrder.workOrderCases;
											
															if(workOrderCasesList !== undefined){
																workOrderCasesList.forEach(function(workFlow){
																	for(var i=0;i<vm.trCases.length;i++){
																		if(workFlow.id == vm.trCases[i].id){
																			vm.trCases[i].isChecked = true;
																		}
																	}	
																});
															}
															
															var myOptions = {
																zoom: 10,
																center: new google.maps.LatLng("5.431587","101.128162"),
																mapTypeId: google.maps.MapTypeId.ROADMAP
															};
											
															map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
													});
													hideModal();
		                                        })
		                                    }
		                                },
		                                Cancel: {
		                                    text: 'Cancel',
		                                    btnClass: 'btn-danger',
		                                    action: function() {
		
		                                    }
		
		                                },
		
		                            }
		                        });
		
		                    }
		                }
		            });
		        });
                $('#workflowsModal').on('show.bs.modal', function(event) {
                    $("#location-map-workflows").css("width", "100%");
                    $("#map_canvas_workflows").css("width", "100%");
                });
				
			};
			function hideModal() {
	            $("#workflowsModal").removeClass("in");
	            $(".modal-backdrop").remove();
	            $("#workflowsModal").hide();
	        }

		function save() {
			vm.isSaving = true;
			
			vm.workOrder.workOrderCases = [];
			vm.selectedWorkFlows.forEach(function(workflow){
				workflow.groups = vm.workOrder.groups;
				vm.workOrder.workOrderCases.push(workflow);
			});
			if(vm.workOrder.workOrderCases.length > 0){
				try{
					vm.workOrder.pinLat = vm.workOrder.workOrderCases[0].caseAssets[0].assetCoordinates[0].latitude;
					vm.workOrder.pinLong = vm.workOrder.workOrderCases[0].caseAssets[0].assetCoordinates[0].longitude;
				}catch(error){
					vm.isSaving = false;
				}
				if (vm.workOrder.id !== null) {
					WorkOrder.update(vm.workOrder, onSaveSuccess, onSaveError);
				} else {
					WorkOrder.save(vm.workOrder, onSaveSuccess, onSaveError);
				}
			}else{
				alert("Please select atleast one work flow");
				vm.isSaving = false;
			}
		}

		function onSaveSuccess(result) {
			$scope.$emit('trakeyeApp:workOrderUpdate', result);
			$state.go('work-order');
			vm.isSaving = false;
		}
		
		function onSaveError() {
			vm.isSaving = false;
		}
		
		function navigateGroupfromWorkOrder() {
			sessionStorage.setItem("prevPage", "work-order.new");
			$state.go('group.new');
		}

		function navigateWorkOrderTypefromWorkOrder() {
			sessionStorage.setItem("prevPage", "work-order.new");
			$state.go('workorder-type.new');
		}
	}
})();