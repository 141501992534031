(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('WorkFlowTypeDetailController', WorkFlowTypeDetailController);

    WorkFlowTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'WorkFlowType', 'User', 'Tenant', 'WorkFlowTypeAttribute'];

    function WorkFlowTypeDetailController($scope, $rootScope, $stateParams, previousState, entity, WorkFlowType, User, Tenant, WorkFlowTypeAttribute) {
        var vm = this;

        vm.workFlowType = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:workFlowTypeUpdate', function(event, result) {
            vm.workFlowType = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
