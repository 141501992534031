(function() {
	'use strict';

	angular.module('trakeyeApp').controller('AssetAdditionController',
			AssetAdditionController);

	AssetAdditionController.$inject = [ '$timeout', '$scope', '$stateParams','AlertService',
			'entity', 'Asset', 'User', 'AssetType', '$state', 'AssetUpload',
			'AllAssetsTypes', 'AllCustomAssetTypeAttribute', 'AssetIDs','AssetByID' ];

	function AssetAdditionController($timeout, $scope, $stateParams, AlertService, entity,
			Asset, User, AssetType, $state, AssetUpload, AllAssetsTypes,
			AllCustomAssetTypeAttribute, AssetIDs,AssetByID) {
		var vm = this;
//		var entity_old = angular.copy(vm.asset);
		vm.asset = entity;
		vm.generatedasset = null;
		vm.childAsset =  null;
		vm.parentName = null;
		var entity_old = angular.copy(vm.asset);
		vm.clear = clear;
		vm.save = save;
		vm.assetType = entity.assetType;

		vm.upload = upload;
		var childAC = [];
		vm.assettypes = loadPage();
		vm.coOrdinates = [];
		vm.coOrdinate = null;
		var newFence = false;
		var latlng;
		var locations = {};
		var centerlatlng = null;
		vm.customAttributesMap = {};
		var formData;
		var spreadAssets = [];
		
		function loadPage() {

			vm.multiassets = [];
			var selctedAssets = [];

			if (vm.asset.id != null) {
				vm.asset.ownedBy.forEach(function(asset) {
					selctedAssets.push(asset.id);
				})
			}

			AssetIDs.getAll(function(response) {
				response.forEach(function(asset) {
					if (selctedAssets.indexOf(asset.id) !== -1) {
						vm.multiassets.push({
							lat : asset.assetCoordinates[asset.assetCoordinates.length-1].latitude,
							lng : asset.assetCoordinates[asset.assetCoordinates.length-1].longitude,
							name : asset.name,
							assetType : asset.assetType,
							assetCoordinates : asset.assetCoordinates,
							id : asset.id,
							ownedBy : asset.ownedBy,
							ticked : true
						});
					} else {
						try {
							vm.multiassets.push({
								lat : asset.assetCoordinates[asset.assetCoordinates.length-1].latitude,
								lng : asset.assetCoordinates[asset.assetCoordinates.length-1].longitude,
								name : asset.name,
								assetType : asset.assetType,
								assetCoordinates : asset.assetCoordinates,
								id : asset.id,
								ownedBy : asset.ownedBy,
								ticked : false
							});
						} catch (e) {
							// TODO: handle exception
						}
						
					}
				});
			});
			
			AllAssetsTypes.getAll(function(response) {
				vm.assettypes = response;
			});

			AllCustomAssetTypeAttribute.getAll(function(response) {
				response.forEach(function(arrayItem) {
					vm.customAttributesMap[arrayItem.name] = arrayItem.customAssetTypeAttributeValues;
				});
			});
		}

		function upload() {
			formData = new FormData();

			formData.append("assetname", $scope.assetname);
			formData.append('file', $scope.myFile);

			AssetUpload.assetupload(formData, function(response, headers) {
				if (response.status == 200) {
					vm.error = null;
					vm.success = 'OK';
					$scope.spreadassetname = '';

					$timeout(function() {
						vm.success = '';
					}, 4000);

				}
				if (response.status == 400) {
					vm.success = null;
					vm.error = 'error';

				}
			});

		}

		/* import end */
		vm.createNewFence = function() {
			newFence = true;
			vm.asset.assetCoordinates = [];
			vm.createfence();
		}

		$timeout(function() {
			angular.element('.form-group:eq(1)>input').focus();
		});

		function clear() {
			$uibModalInstance.dismiss('cancel');
		}

		function save() {
			vm.isSaving = true;
			if (vm.asset.id !== null) {
				Asset.update(vm.asset, onSaveSuccess, onSaveError);
			} else {
				Asset.save(vm.asset, onSaveSuccess, onSaveError);
			}
		}

		function onSaveSuccess(result) {
			$scope.$emit('trakeyeApp:assetUpdate', result);
			vm.isSaving = false;
			$state.go('asset');
		}

		function onSaveError() {
			vm.isSaving = false;
		}

		vm.createfence = function() {
			
			var elem = document.getElementById("map_canvas");

			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}
			 
			var child = {};
			childAC = [];
			for (var i = 0; i < vm.multiassets.length; i++) {
				if (vm.multiassets[i].ticked == true) {
					child = vm.multiassets[i];
					childAC = vm.multiassets[i].assetCoordinates;
				}
			}
			
			var myLatLng = ({lat:child.lat, lng:child.lng});
			var map = new google.maps.Map(document
					.getElementById('map_canvas'), {
				center : myLatLng,
				zoom : 16,
				fullscreenControl: true
			});
			
			createMarkerOfAllAssets(myLatLng,map,child.name);

			for(var j = 0; j < childAC.length;j++){
				if(j > 0){
					var locValues =[];
					locValues.push(childAC[j-1]);
					locValues.push(childAC[j]);
					drawPolylineForGeneratedLoc(locValues,map, "black");
				}
			}	
			
			AssetByID.get(child.id,function(response) {
				vm.childAsset = response;
				if(response.ownedBy != null){
					var parent = response.ownedBy;
						if (parent.length > 0) {
							vm.parentName = parent[0].name;
							var assetCoordinateValues = parent[0].assetCoordinates;
							var myLatLng = ({lat:assetCoordinateValues[assetCoordinateValues.length-1].latitude, lng:assetCoordinateValues[assetCoordinateValues.length-1].longitude});
							createMarkerOfAllAssets(myLatLng,map,parent[0].name);
						}
					}
			});
		};

		function drawPolylineForGeneratedLoc(latlngsToDraw, map, color) {
            var spreadAssetCoordinates = [];
            $.each(latlngsToDraw, function(index, value) {

                spreadAssetCoordinates.push({
                    lat : value.latitude,
                    lng : value.longitude
                });
            });

            if (spreadAssetCoordinates.length > 0) {
                var midLatLng = {
                        lat : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lat,
                        lng : spreadAssetCoordinates[spreadAssetCoordinates.length-1].lng
                    };
                var infowindow = new google.maps.InfoWindow(
                        {
                            content : '<div class="infoWindowhead">'
                                    + "name"
                                    + '<div class="infoWindowContent"> <b>Description :</b> '
                                    + "des" + '</div></div>'
                        });
                infowindow.setPosition(midLatLng);

                var ColorValue = '#ff0000';
                if(color == "red"){
                    ColorValue = '#ff0000';
                }else if(color == "black"){
                	ColorValue = '#000000';
                }else{
                    ColorValue = '#208000';
                }
                var polylinepath = new google.maps.Polyline({
                    path : spreadAssetCoordinates,
                    geodesic : true,
                    strokeColor : ColorValue,
                    strokeOpacity : 1.0,
                    strokeWeight : 2.5,
                    fillColor : ColorValue,
                    fillOpacity : 0.3,
                    editable : false
                });

                google.maps.event.addListener(polylinepath, 'mouseout', function(event) {
					infowindow.close();
				});

				google.maps.event.addListener(polylinepath, 'mouseover', function(event) {
					
					var userContent= document.createElement('div'), button;

					userContent.innerHTML = '<div class="infoWindowhead">' + 'Insert Asset at '
					+ '</div><div class="infoWindowContent">'
					+'<b>Latitude :</b> '
					+ event.latLng.lat()
					+'<br><b>Longitude :</b> '
					+ event.latLng.lng()
					+ '</div>';

					button = userContent.appendChild(document.createElement('input'));
					button.type = 'button';
					button.value = 'Save';
					button.className = "button"; 
					button.style.width = '300px';
					infowindow = new google.maps.InfoWindow({
						content : userContent
					});

					google.maps.event.addDomListener(button, 'click', function () {
						var newAssetAC = [];
						var newChildAC = [];
						var isACSplitted = false;
						
						vm.generatedasset = vm.asset;
						vm.generatedasset.name = "Asset BW "+vm.childAsset.name + " " +vm.parentName;
						vm.generatedasset.assetCoordinates = [];
						vm.generatedasset.assetCoordinates.push({latitude:event.latLng.lat(), longitude:event.latLng.lng()});
						var assetTypes = vm.assettypes;
						var assetTypeAttributesVal = [];
						var assetTypeValConsolidated = null;
						for (var i = 0; i < assetTypes.length; i++) {
							if(assetTypes[i].name == "Joint Kit"){
								assetTypeValConsolidated = assetTypes[i];
								assetTypeAttributesVal = assetTypes[i].assetTypeAttributes;
							}	
						}
						var assetTypeAttributesValConsolidated = [];
						for (var i = 0; i < assetTypeAttributesVal.length; i++) {
							if(assetTypeAttributesVal[i] != null){
								assetTypeAttributesValConsolidated.push({assetTypeAttribute:assetTypeAttributesVal[i],attributeValue:assetTypeAttributesVal[i].defaultValue}  );
							}
						}
						vm.generatedasset.assetTypeAttributeValues = assetTypeAttributesValConsolidated;
						vm.generatedasset.assetType = assetTypeValConsolidated;

						for (var i = 0; i < childAC.length; i++) {
							if(!isACSplitted){
								newAssetAC.push({latitude:childAC[i].latitude, longitude:childAC[i].longitude});
								if(latlngsToDraw[0].latitude == childAC[i].latitude){
									isACSplitted = true;
									newAssetAC.push({latitude:event.latLng.lat(), longitude:event.latLng.lng()});
									newChildAC.push({latitude:event.latLng.lat(), longitude:event.latLng.lng()});
								}
							}else{
								newChildAC.push({latitude:childAC[i].latitude, longitude:childAC[i].longitude});
							}
						}
					
						if(newAssetAC.length > 0){
							vm.childAsset.assetCoordinates.push({latitude:newAssetAC[newAssetAC.length-1].latitude, longitude:newAssetAC[newAssetAC.length-1].longitude});
						}
						
						for (var i = 0; i < newChildAC.length; i++) {
							vm.childAsset.assetCoordinates.push({latitude:newChildAC[i].latitude, longitude:newChildAC[i].longitude});
						}
						
						vm.generatedasset.assetCoordinates = [];
						
						for (var i = 0; i < newAssetAC.length; i++) {
							vm.generatedasset.assetCoordinates.push({latitude:newAssetAC[i].latitude, longitude:newAssetAC[i].longitude});
						}
						

						
						Asset.save(vm.generatedasset, function(response) {
							
							vm.generatedasset.ownedBy = vm.childAsset.ownedBy;
							vm.childAsset.ownedBy = [];
							vm.childAsset.ownedBy.push({id:response.id})
							
							vm.generatedasset.id = response.id;

							Asset.update(vm.childAsset,function(response) {
								Asset.update(vm.generatedasset,function(response) {
									$state.go('asset');
								});
							});
							
							
						});
						newAssetAC = [];
						newChildAC = [];
						
					});
                    infowindow.mousemove;
					infowindow.setPosition(event.latLng);
					infowindow.open(map);
				});
				
                polylinepath.setOptions({
                    strokeColor : ColorValue
                });
                
                
                polylinepath.setMap(map);
            }
        }
		
		function distanceCal(lat1, lon1, lat2, lon2) {
			var theta = lon1 - lon2;
			var dist = Math.sin(degrees_to_radians(lat1)) * Math.sin(degrees_to_radians(lat2))
			+ Math.cos(degrees_to_radians(lat1)) * Math.cos(degrees_to_radians(lat2)) * Math.cos(degrees_to_radians(theta));
			dist = Math.acos(dist);
			dist = radians_to_degrees(dist);
			dist = dist * 60 * 1.1515;
			dist = dist * 1.609344;
			if(isNaN(dist)){
				return 0.0;
			}else{
				return (dist);	
			}
		}
		function degrees_to_radians(degrees){
			var pi = Math.PI;
			return degrees * (pi/180);
		}

		function radians_to_degrees(radians){
			var pi = Math.PI;
			return radians * (180/pi);
		}

		function createMarkerOfAllAssets(myLatLng, map, myLocName) {
				var marker = new google.maps.Marker(
						{
							position : myLatLng,
							map : map,
							icon : {
								scaledSize : new google.maps.Size(
										24, 24),
										origin : new google.maps.Point(0, 0),
										icon :'http://maps.google.com/mapfiles/ms/icons/green-dot.png',
										anchor : new google.maps.Point(
														0, 0),

							},
							label: { color: 'black', fontWeight: 'medium', fontSize: '12px', text: myLocName, backgroundcolor: "#ff9800" },
						});
		}
		

		function moveToLocation(lat, lng, map) {
			var center = new google.maps.LatLng(lat, lng);
			// using global variable:
			map.panTo(center);
		}


	
		$scope.mapEvents = {
			"click" : function() {

			}
		}

	}
})();
