(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomWorkOrderTypeAttributeDialogController', CustomWorkOrderTypeAttributeDialogController);

    CustomWorkOrderTypeAttributeDialogController.$inject = ['$timeout', '$scope', '$state','$stateParams', 'entity', 'CustomWorkOrderTypeAttribute'];

    function CustomWorkOrderTypeAttributeDialogController ($timeout, $scope, $state ,$stateParams, entity, CustomWorkOrderTypeAttribute) {
        var vm = this;

        vm.customWorkOrderTypeAttribute = entity;
        vm.clear = clear;
        vm.save = save;
       // vm.customworkordertypeattributevalues = CustomWorkOrderTypeAttributeValue.query();
        
        vm.items = [ {
			value : ''
		} ];
        
        if (vm.customWorkOrderTypeAttribute.customWorkOrderTypeAttributeValues != null && vm.customWorkOrderTypeAttribute.customWorkOrderTypeAttributeValues.length != 0) {
			vm.items = vm.customWorkOrderTypeAttribute.customWorkOrderTypeAttributeValues;
		}
        vm.addmore = function() {
			vm.items.push({
				value : ''
			});
		};

		vm.remove = function(index) {
			vm.items.splice(index, 1);
		};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            //$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if  ( vm.customWorkOrderTypeAttribute.id !== null) {
            	
            	 vm.customWorkOrderTypeAttribute.customWorkOrderTypeAttributeValues = [];
				for (var i = 0; i < vm.items.length; i++) {
					 vm.customWorkOrderTypeAttribute.customWorkOrderTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
                CustomWorkOrderTypeAttribute.update(vm.customWorkOrderTypeAttribute, onSaveSuccess, onSaveError);
            } else {
            	
            	
            	 vm.customWorkOrderTypeAttribute.customWorkOrderTypeAttributeValues = [];

				for (var i = 0; i < vm.items.length; i++) {
					 vm.customWorkOrderTypeAttribute.customWorkOrderTypeAttributeValues.push({
						"id" : vm.items[i].id,
						"value" : vm.items[i].value
					});

				}
				
                CustomWorkOrderTypeAttribute.save(vm.customWorkOrderTypeAttribute, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:customWorkOrderTypeAttributeUpdate', result);
           // $uibModalInstance.close(result);
            vm.isSaving = false;
            $state.go('custom-workorder-type-attribute');
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
