(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('CustomWorkFlowTypeAttributeDetailController', CustomWorkFlowTypeAttributeDetailController);

    CustomWorkFlowTypeAttributeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomWorkFlowTypeAttribute', 'User', 'Tenant', 'CustomWorkFlowTypeAttributeValue'];

    function CustomWorkFlowTypeAttributeDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomWorkFlowTypeAttribute, User, Tenant, CustomWorkFlowTypeAttributeValue) {
        var vm = this;

        vm.customWorkFlowTypeAttribute = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('trakeyeApp:customWorkFlowTypeAttributeUpdate', function(event, result) {
            vm.customWorkFlowTypeAttribute = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
