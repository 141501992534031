(function() {
    'use strict';

    angular
        .module('trakeyeApp')
        .controller('WorkFlowTypeDialogController', WorkFlowTypeDialogController);

    WorkFlowTypeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'WorkFlowType', 'User', 'Tenant', 'WorkFlowTypeAttribute'];

    function WorkFlowTypeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, WorkFlowType, User, Tenant, WorkFlowTypeAttribute) {
        var vm = this;

        vm.workFlowType = entity;
        vm.clear = clear;
        vm.save = save;
        vm.users = User.query();
        vm.tenants = Tenant.query();
        vm.workflowtypeattributes = WorkFlowTypeAttribute.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.workFlowType.id !== null) {
                WorkFlowType.update(vm.workFlowType, onSaveSuccess, onSaveError);
            } else {
                WorkFlowType.save(vm.workFlowType, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('trakeyeApp:workFlowTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
